import { RouteComponentProps, StaticContext } from 'react-router';

import {
  ColaboratorFormPage,
  ColaboratorPage,
} from '../pages/admin/colaborator';
import { CouponFormPage, CouponPage } from '../pages/admin/coupon';
import { CreatorFormPage, CreatorPage } from '../pages/admin/creators';
import { IndicatorFormPage, IndicatorPage } from '../pages/admin/indicator';
import { FormMaterial, PageMaterial } from '../pages/admin/material';
import { ModelingFormPage, ModelingPageHome } from '../pages/admin/modeling';
import { PearsonPage, PearsonFormPage } from '../pages/admin/pearson';
import {
  PageStock,
  ProductPageHome,
  ProductStock,
} from '../pages/admin/product';
import {
  ProductBasicInfo,
  ProductData,
  ProductFormCreate,
  ProductFormUpdate,
  ProductMedia,
} from '../pages/admin/product/form';
import {
  PageExchange,
  PageSiteSale,
  PageSiteSaleView,
  PageWholeSale,
  PageWholeSaleForm,
} from '../pages/admin/purchase';
import {
  Page,
  FormPage,
  StoreDeparmentPage,
  StoreDepartmentFormPage,
  ShopPage,
  ShopFormPage,
} from '../pages/admin/store';
import { UserPage, UserFormPage } from '../pages/admin/user';
import Login from '../pages/auth';
import LoginWithToken from '../pages/with-code';
import Dashboard from '../pages/dashboard';
import Home from '../pages/home';
import APP_ROUTE from './routes';
import PageCredentials from '../pages/admin/systemsettings/credentials';
import PagePacking from '../pages/admin/systemsettings/packing';
import PageGeneralSettings from '../pages/admin/systemsettings/general';
import CreatorProductPage from '../pages/creator/product/home';
import CreatorProductForm from '../pages/creator/product/form';
import ProductSolicitations from '../pages/creator/product/solicitations/home';
import FeedbackPage from '../pages/feedback';
import FeedBackAdminPage from '../pages/admin/feedback';
import CreatorModeling from '../pages/creator/modeling';
import UserProfile from '../pages/user';
import CreatorCreation from '../pages/creator/creation';
import ProductionPage from '../pages/creator/production';
import ProductSolicitationDetail from '../pages/creator/product/solicitations/detail';
import PageSolicitations from '../pages/admin/solicitations/home';
import CreatorProductStockPage from '../pages/creator/product/stock';
import CreatorStockPage from '../pages/creator/stock';

interface PageRouteItem {
  route: string;
  component?:
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | React.ComponentType<any>
    | undefined;
}

const PageRoutes: Array<PageRouteItem> = [
  //ADMINISTRATOR ITENS MENU
  { route: APP_ROUTE.HOME(), component: Login },
  { route: APP_ROUTE.LOGIN(), component: Login },
  { route: APP_ROUTE.LOGIN_WITH_CODE(), component: LoginWithToken },
  { route: APP_ROUTE.DASHBOARD(), component: Dashboard },
  { route: APP_ROUTE.PEARSON(), component: PearsonPage },
  { route: APP_ROUTE.PEARSON_CREATE(), component: PearsonFormPage },
  { route: APP_ROUTE.PEARSON_VIEW(), component: PearsonFormPage },
  { route: APP_ROUTE.USER(), component: UserPage },
  { route: APP_ROUTE.USER_CREATE(), component: UserFormPage },
  { route: APP_ROUTE.USER_VIEW(), component: UserFormPage },

  { route: APP_ROUTE.PROFILE(), component: UserProfile },

  { route: APP_ROUTE.CREATOR(), component: CreatorPage },
  { route: APP_ROUTE.CREATOR_CREATE(), component: CreatorFormPage },
  { route: APP_ROUTE.CREATOR_VIEW(), component: CreatorFormPage },

  { route: APP_ROUTE.INDICATOR(), component: IndicatorPage },
  { route: APP_ROUTE.INDICATOR_CREATE(), component: IndicatorFormPage },
  { route: APP_ROUTE.INDICATOR_VIEW(), component: IndicatorFormPage },

  { route: APP_ROUTE.COLABORATOR(), component: ColaboratorPage },
  { route: APP_ROUTE.COLABORATOR_CREATE(), component: ColaboratorFormPage },
  { route: APP_ROUTE.COLABORATOR_VIEW(), component: ColaboratorFormPage },

  { route: APP_ROUTE.STORE_DEPARTMENT(), component: StoreDeparmentPage },
  {
    route: APP_ROUTE.STORE_DEPARTMENT_CREATE(),
    component: StoreDepartmentFormPage,
  },
  {
    route: APP_ROUTE.STORE_DEPARTMENT_VIEW(),
    component: StoreDepartmentFormPage,
  },

  { route: APP_ROUTE.COUPON(), component: CouponPage },
  { route: APP_ROUTE.COUPON_CREATE(), component: CouponFormPage },
  { route: APP_ROUTE.COUPON_VIEW(), component: CouponFormPage },

  { route: APP_ROUTE.SHOP_PAGE(), component: Page },

  { route: APP_ROUTE.SHOP_PAGE_CREATE(), component: FormPage },

  { route: APP_ROUTE.SHOP(), component: ShopPage },
  { route: APP_ROUTE.SHOP_CREATE(), component: ShopFormPage },
  { route: APP_ROUTE.SHOP_VIEW(), component: ShopFormPage },

  { route: APP_ROUTE.MATERIAL(), component: PageMaterial },
  { route: APP_ROUTE.MATERIAL_CREATE(), component: FormMaterial },
  { route: APP_ROUTE.MATERIAL_VIEW(), component: FormMaterial },

  { route: APP_ROUTE.MODELING(), component: ModelingPageHome },
  { route: APP_ROUTE.MODELING_CREATE(), component: ModelingFormPage },
  { route: APP_ROUTE.MODELING_VIEW(), component: ModelingFormPage },

  { route: APP_ROUTE.PRODUCT(), component: ProductPageHome },
  { route: APP_ROUTE.PRODUCT_VIEW_STOCK(), component: PageStock },

  { route: APP_ROUTE.PRODUCT_CREATE('basic'), component: ProductBasicInfo },
  { route: APP_ROUTE.PRODUCT_CREATE('media'), component: ProductMedia },
  { route: APP_ROUTE.PRODUCT_CREATE('data'), component: ProductData },
  { route: APP_ROUTE.PRODUCT_VIEW(), component: ProductFormUpdate },
  { route: APP_ROUTE.PRODUCT_STOCK(), component: ProductStock },

  { route: APP_ROUTE.PURCHASE_WHOLESALE(), component: PageWholeSale },
  {
    route: APP_ROUTE.PURCHASE_WHOLESALE_CREATE(),
    component: PageWholeSaleForm,
  },
  {
    route: APP_ROUTE.PURCHASE_WHOLESALE_VIEW(),
    component: PageWholeSaleForm,
  },
  { route: APP_ROUTE.PURCHASE_SITESALE(), component: PageSiteSale },
  {
    route: APP_ROUTE.PURCHASE_SITESALE_VIEW(),
    component: PageSiteSaleView,
  },
  { route: APP_ROUTE.PURCHASE_EXCHANGE(), component: PageExchange },
  {
    route: APP_ROUTE.SYSTEM_SETTINGS_CREDENTIALS(),
    component: PageCredentials,
  },
  { route: APP_ROUTE.SYSTEM_SETTINGS_PACKING(), component: PagePacking },
  {
    route: APP_ROUTE.SYSTEM_SETTINGS_GENERAL(),
    component: PageGeneralSettings,
  },

  {
    route: APP_ROUTE.FEEDBACK_ADMIN(),
    component: FeedBackAdminPage,
  },
  {
    route:APP_ROUTE.SOLICITATION(),
    component:PageSolicitations
  },

  //CREATOR ITENS MENU
  {
    route: APP_ROUTE.FEEDBACK(),
    component: FeedbackPage,
  },
  {
    route: APP_ROUTE.CREATOR_PRODUCT(),
    component: CreatorProductPage,
  },
  {
    route: APP_ROUTE.CREATOR_PRODUCT_CREATE(),
    component: CreatorModeling,
  },
  {
    route: APP_ROUTE.CREATOR_PRODUCT_STOCK(),
    component: CreatorProductStockPage
  },
  {
    route:APP_ROUTE.CREATOR_PRODUCT_VIEW_STOCK(),
    component:CreatorStockPage
  },
  {
    route: APP_ROUTE.CREATOR_PRODUCT_CREATE_VIEW(),
    component: CreatorProductForm,
  },
  {
    route: APP_ROUTE.CREATOR_PRODUCT_SOLICITATION(),
    component: ProductSolicitations,
  },
  {
    route: APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(),
    component: ProductSolicitationDetail,
  },
  {
    route:APP_ROUTE.CREATOR_CRIATION(),
    component:CreatorCreation
  },
  {
    route:APP_ROUTE.CREATOR_REQUEST_PRODUCTION(),
    component:ProductionPage
  }
];

/*
CREATOR_PRODUCT_SOLICITATION_VIEW
ProductSolicitationDetail
*/

export { PageRoutes };
export type { PageRouteItem };
