import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from 'antd';
import { useEffect, useState } from 'react';
import { EyeTwoTone, EyeInvisibleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';

import { ItemCityAndState } from '../../classes/StateAndCity';
import { Bank } from '../../classes/Util';
import { useAppContext } from '../../context/app-context';
import CommonApi from '../../services/CommonApi';
import UtilApi from '../../services/UtilApi';
import { isValidCPF } from '../../utils/cpfValidation';
import { mask } from '../../utils/mask';
import React from 'react';
import UserApi from '../../services/UserApi';
import AuthApi from '../../services/AuthApi';
import moment from 'moment';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const UserProfile = () => {
  const [form] = Form.useForm();

  const { user, setOnUpdateUser } = useAppContext();

  const [imageUrl, setImageUrl] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [maskedValue, setMaskedValue] = useState('');

  const [states, setStates] = useState<ItemCityAndState[]>([]);
  const [city, setCities] = useState<ItemCityAndState[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);

  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!!user) {
      console.log(user);
      form.setFieldsValue(user);
      setTimeout(()=>{
        form.setFieldsValue({
          doc_number:user.doc_number,
          birth_date:moment(user.birth_date).format('YYYY-MM-DD'),
          zipcode:user.zipcode,
          phone:user.phone
        })
      },300)
      

      loadBanks();
      loadState();
    
    
      if(!!user.state){
        loadCity(user.state, 'id');
      }
    }
  }, [user]);

  function loadBanks() {
    UtilApi.banks()
      .then((resp) => {
        setBanks(resp?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function loadState() {
    CommonApi.State()
      .then((resp) => {
        setStates(resp?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function loadCity(value?: any, key?:string) {
    setLoadingCity(true);
    CommonApi.City(value, key)
      .then((resp) => {
        setCities(resp?.data);
      })
      .finally(() => {
        setLoadingCity(false);
        setTimeout(() => {
          if (!!user?.city) {
            form.setFieldValue('city', user.city);
          }
        }, 300);
      });
  }

  const handlerChangeState = (option: any) => {
    console.log(option);
    const { value } = option;

    console.log(value);

    loadCity(value, 'initials');
  };

  const handlerSubmit = (values: any) => {
    setIsSending(true);
    UserApi.updateProfile(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        setOnUpdateUser(true);
      })
      .catch((e) => {
        message.error(e.response?.data.msg);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const cellphoneMask = '(00) 0 0000-0000';
  const phoneMask = '(00) 0000-0000';

  // always memoize dynamic masks
  const mask = React.useMemo(
    () => [
      {
        mask: cellphoneMask,
        lazy: false,
      },
      {
        mask: phoneMask,
        lazy: false,
      },
    ],
    []
  );

  return (
    <>
      <Row justify={'space-between'}>
        <Title level={2}>Meu perfil</Title>
      </Row>

      <Form
        form={form}
        onFinish={handlerSubmit}
        style={{ width: '100%' }}
        layout={'vertical'}
      >
        <Space
          size={[40, 40]}
          align="start"
          direction="vertical"
          style={{ width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={8}>
                <Form.Item
                  label="Nome"
                  name={'name'}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Nome" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={`CPF/CNPJ`}
                  name={'doc_number'}
                  initialValue={user?.doc_number}
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let valid = isValidCPF(value);

                        if (!valid) {
                          return Promise.reject(
                            new Error(
                              `O ${
                                value.length > 14 ? `CNPJ` : `CPF`
                              } é inválido`
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <MaskedInput mask={'000.000.000-00'}/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="birth_date" label="Data de nascimento:">
                  <Input type="date" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label={'Email'}
                  name="email"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                  <Input placeholder="E-mail" disabled readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Telefone" name={'phone'} initialValue={user?.phone}>
                  <MaskedInput
                    mask={'(00)00000-0000'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={8}>
                <Form.Item
                  name={'zipcode'}
                  label={'CEP'}
                  initialValue={user?.zipcode}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <MaskedInput mask={'00000-000'} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Estado"
                  name="state"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                   <Select
                    labelInValue
                    showSearch
                    placeholder={'Estado'}
                    defaultActiveFirstOption={false}
                    dropdownMatchSelectWidth={false}
                    showArrow={true}
                    onChange={handlerChangeState}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? '').toLowerCase()
                        )
                    }
                    options={states.map((item: ItemCityAndState) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  ></Select>
                  
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Cidade"
                  name="city"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                   <Select
                    labelInValue
                    showSearch
                    placeholder={'Cidade'}
                    defaultActiveFirstOption={false}
                    dropdownMatchSelectWidth={false}
                    showArrow={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? '').toLowerCase()
                        )
                    }
                    options={city.map((item: ItemCityAndState) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="Endereço"
                  name="address"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                  <TextArea placeholder="Endereço" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div>
            <Title level={5}>Dados do bancários</Title>

            <Row gutter={[16, 16]} justify="space-between">
              <Col span={6}>
                <Form.Item
                  name={'bank_name'}
                  label={'Banco'}
                  rules={[
                    {
                      required: true,
                      message: 'Selecione um banco',
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    showSearch
                    placeholder={'Banco'}
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={banks.map((item: Bank) => ({
                      label: item.name,
                      value: item.code,
                    }))}
                  ></Select>
                </Form.Item>

                {/* <Form.Item
                    label="Banco"
                    name="bank_name"
                    rules={[
                      { required: true, message: 'Campo é obrigatório!' },
                    ]}
                  >
                    <Input placeholder="Banco" />
                  </Form.Item> */}
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Agência"
                  name="bank_agency"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                  <Input placeholder="Agência" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Conta"
                  name="bank_account"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                  <Input placeholder="Conta" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Pix"
                  name="bank_pix"
                  rules={[{ required: true, message: 'Campo é obrigatório!' }]}
                >
                  <Input placeholder="Pix" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Form.Item>
              <Button
                type="primary"
                className="btn-orange"
                size="large"
                htmlType="submit"
                loading={isSending}
              >
                Atualizar
              </Button>
            </Form.Item>
          </div>
        </Space>
      </Form>
    </>
  );
};

export default UserProfile;
