import {
  Breadcrumb,
  Button,
  Col,
  Form,
  message,
  Row,
  Space,
  Typography,
  Upload,
  Image,
  Input,
  Select,
  Divider,
  InputRef,
} from "antd";
import type { UploadProps } from "antd";
import { InboxOutlined, UndoOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { Color, SketchPicker, SwatchesPicker } from "react-color";

import AppModal from "../../../../../components/AppModal";
import { CustomFile } from "../../../../../classes/Util";
import TextArea from "antd/lib/input/TextArea";
import { ColorOption } from "../../../../../classes/Color";
import ColorApi from "../../../../../services/ColorApi";
import ColorsSelect from "../../../../../components/Colors";
import AuthApi from "../../../../../services/AuthApi";
import UploadFile from "../../../../../components/Upload";
import StoreApi from "../../../../../services/StoreApi";
import { ProviderFormPageParams } from "../../../colaborator/form";
import APP_ROUTE from "../../../../../routes/routes";

const { Title, Text } = Typography;
const { Option } = Select;

const { Dragger } = Upload;

const PageForm = (props: RouterProps) => {
  const history = useHistory();
  const [form] = Form.useForm();
  let paramsUrl = useParams<ProviderFormPageParams>();

  const [pageId, setPageId] = useState(0);

  // const [bannerList, setBannerList] = useState<UploadFile[]>([]);
  const [bannerListFile, setBannerListFile] = useState<CustomFile[]>([]);
  const [avatarListFile, setAvatarListFile] = useState<CustomFile[]>([]);

  const [isEdit, setIsEdit] = useState(false);

  const [currentColor, setCurrentColor] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingColor, setLoadingColor] = useState(false);

  const [params, setParams] = useState({});
  const [urlParams, setUrlParams] = useState("");

  const [banner, setBanner] = useState("");
  const [avatar, setAvatar] = useState("");

  const [option, setOption] = useState<"SAVE" | "SAVE_CHARGE">("SAVE");

  useEffect(() => {
    StoreApi.getItem(Number(paramsUrl.id)).then((resp) => {
      form.setFieldsValue(resp?.data);
      setBanner(resp?.data.banner.file);
      setAvatar(resp?.data.avatar.file);
    });
    setPageId(Number(paramsUrl.id));
    setIsEdit(true);
  }, [paramsUrl.id]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  useEffect(() => {
    setParams({ ...params, ...{ banner: banner } });
  }, [banner]);

  useEffect(() => {
    setParams({ ...params, ...{ avatar: avatar } });
  }, [avatar]);

  const handlerSubmit = (values: any) => {
    setLoading(true);
    var finalParams = { ...params, ...{ token: "asdf1234" } };
    let newUrlparams = new URLSearchParams(finalParams).toString();
    setUrlParams(newUrlparams);

    values = {
      ...values,
      ...{
        avatar: avatar,
        banner: banner,
      },
    };
    StoreApi.update(values, pageId)
      .then((resp) => {
        message.success(resp?.data.msg);
        setTimeout(() => {
          history.replace(APP_ROUTE.SHOP());
        }, 300);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPreview = () => {
    let fields = form.getFieldsValue();

    var finalParams = {
      ...fields,
      ...{ token: "asdf1234", banner: banner, avatar: avatar },
    };
    let newUrlparams = new URLSearchParams(finalParams).toString();

    setUrlParams(newUrlparams);
  };

  const handlerApprove = () => {
    console.log(params);
  };

  const handlerPublish = () => {};

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? "Atualizar" : "Cadastrar"} Loja
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Space
                size={[40, 40]}
                direction="vertical"
                align="start"
                style={{ width: "100%" }}
                wrap
              >
                <div>
                  <Title level={5}>Banner</Title>
                  {banner && isEdit ? (
                    <Image
                      width={`100%`}
                      src={`${process.env.REACT_APP_API}/storage/${banner}`}
                    />
                  ) : (
                    <></>
                  )}
                  <UploadFile
                    path={"store"}
                    hint={"PNG, JPG or PDF (max. 00 Mb)"}
                    onFile={setBanner}
                  />
                </div>
                <div>
                  <Title level={5}>Informações da página</Title>
                  <Form.Item name="name" label="Nome da página">
                    <Input
                      name="name"
                      placeholder="Nome da página"
                      onChange={(e) =>
                        setParams({ ...params, ...{ name: e.target.value } })
                      }
                    />
                  </Form.Item>

                  <Form.Item name="resume" label="Resumo">
                    <TextArea
                      placeholder="Resumo"
                      onChange={(e) =>
                        setParams({
                          ...params,
                          ...{ resume: e.target.value },
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item name="social_network" label="Rede social">
                    <Select>
                      <Option key={null}>Rede social</Option>
                      <Option key={"FACEBOOK"} value={"FACEBOOK"}>
                        Facebook
                      </Option>
                      <Option key={"TWITTER"} value={"TWITTER"}>
                        Twitter
                      </Option>
                      <Option key={"INSTAGRAM"} value={"INSTAGRAM"}>
                        Instagram
                      </Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="url" label="URL">
                    <Input
                      name="url"
                      placeholder="URL"
                      onChange={(e) =>
                        setParams({
                          ...params,
                          ...{ socialnetwork: e.target.value },
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <Title level={5}>Avatar</Title>
                  {avatar && isEdit ? (
                    <Image
                      width={`100%`}
                      src={`${process.env.REACT_APP_API}/storage/${avatar}`}
                    />
                  ) : (
                    <></>
                  )}
                  <UploadFile
                    path={"avatar"}
                    hint={"PNG, JPG or PDF (max. 00 Mb)"}
                    onFile={setAvatar}
                  />
                </div>
              </Space>
            </Col>
            <Col span={18}>
              <iframe
                src={`${
                  process.env.REACT_APP_API
                }/api/example-store?token=asdf1234${
                  !!urlParams ? `&${urlParams}` : ""
                }`}
                width={"100%"}
                height={1200}
                frameBorder="0"
              />
            </Col>
          </Row>
          <Form.Item name="option">
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  type="dashed"
                  htmlType="submit"
                  className="btn-orange"
                  onClick={() => setOption("SAVE")}
                  value={"save"}
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => setOption("SAVE_CHARGE")}
                  value={"saveCharge"}
                >
                  Salvar e gerar cobrança
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<UndoOutlined />}
                  onClick={() => onPreview()}
                >
                  Pré-visualizar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(PageForm);
