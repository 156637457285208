import { Divider, Form, Input, Button, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import {
  RouteProps,
  useLocation,
  withRouter,
  useHistory,
} from "react-router-dom";
import { useAppContext } from "../../context/app-context";
import APP_ROUTE from "../../routes/routes";
import AuthApi from "../../services/AuthApi";

const useQueryString = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const LoginWithToken = (props: RouteProps) => {
  let query = useQueryString();

  const { setUser, setIsLoggedUser } = useAppContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState("");

  useEffect(() => {
    console.log(query.get("token"));
    setToken(query.get("token") || "");
    handleSubmit(query.get("token"));
  }, [query.get("token")]);

  const handleSubmit = (token: any) => {
    console.log(token);
    setLoading(true);

    AuthApi.setToken({
      access_token: token,
      token_type: "Bearer",
    });

    AuthApi.Me()
      .then((authresp) => {
        localStorage.setItem("userdata", JSON.stringify(authresp?.data));
        setUser(authresp?.data);
        setIsLoggedUser(true);
        history.replace(APP_ROUTE.PRODUCT());
      })
      .catch((error) => {
        message.error(error.response.data.msg);
      });
  };

  return (
    <>
      <div className="wrapper-login">
        <div className="form">
          <div className="brand">
            <img src="/imgs/brand.svg" alt="" />
            <h2>Fella</h2>
          </div>

          <Typography.Text>Validando usuário</Typography.Text>

          {loading && <Spin></Spin>}
        </div>
      </div>
    </>
  );
};

export default withRouter(LoginWithToken);
