import { Avatar, Button, Col, Dropdown, MenuProps, Modal, Row, Space } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  DownOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../context/app-context';
import APP_ROUTE from '../../routes/routes';
import AuthApi from '../../services/AuthApi';

const AppHeader = () => {
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [open, setOpen] = useState(false);

  const {
    user,
    isLoggedUser,
    setIsLoggedUser,
    setUser,
    setCloseMenu,
    closeMenu,
  } = useAppContext();

  useEffect(() => {
    let first = user?.name.split(' ');
    if (!!first) setFirstName(first[0]);
  }, [user]);

  function logout() {
    AuthApi.logout();
    setIsLoggedUser(false);
    setUser(undefined);
    history.replace(APP_ROUTE.LOGIN());
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Perfil',
      onClick: () => {
        history.push(APP_ROUTE.PROFILE());
      },
    },
    {
      key: '3',
      label: 'Sair',
      onClick: () => {
        logout();
      },
    },
  ];

  return (
    <Header className="header-app">
      <Row gutter={[16, 16]}>
        <Col>

          <Dropdown
            menu={{ items }}
            arrow={{ pointAtCenter: true }}
          >
              <div onClick={e => e.preventDefault()}>
                <Avatar size={'large'}>{user?.initials}</Avatar>
              </div>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeader;
