import { Col, message } from "antd";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import { RouterProps, useHistory, withRouter } from "react-router-dom";
import { UserDict } from "../../../../../classes/User";
import AppModal from "../../../../../components/AppModal";
import APP_ROUTE from "../../../../../routes/routes";
import StoreApi from "../../../../../services/StoreApi";
import UserApi from "../../../../../services/UserApi";
import { isValidCPF, isValidCNPJ } from "../../../../../utils/cpfValidation";
import { mask } from "../../../../../utils/mask";

const { Title, Text } = Typography;

const { Option } = Select;

const ShopFormPage = (props: RouterProps) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [users, setUsers] = useState<UserDict[]>([]);

  const [username, setUserName] = useState("");
  const [maskedValue, setMaskedValue] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [option, setOption] = useState<"SAVE" | "SAVE_CHARGE">("SAVE");

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  function handleChangeMask(event: any) {
    const { value } = event.target;
    form.setFieldsValue({
      doc_number: mask(value),
    });
  }

  const handleChange = (searchText: string) => {
    setLoadingUser(true);
    UserApi.search(searchText)
      .then((resp) => {
        setUsers(resp?.data);
        form.setFieldValue("doc_number", mask(resp?.data.doc_number));
      })
      .finally(() => {
        setLoadingUser(false);
      });
  };

  const HandlerOnChange = (values: string) => {
    console.log(values);

    let currentUser = users.filter((item) => item.id == Number(values));

    if (!!currentUser[0].doc_number) {
      form.setFieldValue(
        "doc_number",
        mask(currentUser[0].doc_number.replace(/[\s.-]*/gim, ""))
      );
    }

    setUserName(values);
  };

  const handlerSubmit = (values: any) => {
    console.log(values);
    setLoading(true);
    values = { ...values, ...{ pre_register: true, option: option } };
    StoreApi.addnew(values)
      .then((resp) => {
        var result = resp?.data;

        message.success(result.msg);

        if (values.type == "PAGE") {
          history.replace(APP_ROUTE.SHOP_PAGE_CREATE(result.store.id));
        } else {
          history.replace(APP_ROUTE.SHOP_PAGE());
        }
      })
      .catch((e) => {
        message.error(e.response?.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? "Atualizar" : "Cadastrar"} Loja
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Form.Item label={"Criador"} name={"user_id"}>
            <Select
              showSearch
              value={username}
              placeholder={"Usuários"}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleChange}
              onChange={HandlerOnChange}
              loading={loadingUser}
              notFoundContent={null}
            >
              {users.map((item: UserDict) => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={`CPF/CNPJ`}
            name={"doc_number"}
            rules={[
              { required: true, message: "Campo obrigatório" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  let valid =
                    value.length > 14 ? isValidCNPJ(value) : isValidCPF(value);

                  if (!valid) {
                    return Promise.reject(
                      new Error(
                        `O ${value.length > 14 ? `CNPJ` : `CPF`} é inválido`
                      )
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input onChange={handleChangeMask} value={maskedValue} />
          </Form.Item>
          <Form.Item name="type" label="Tipo">
            <Radio.Group
              options={[
                {
                  label: "Etiqueta",
                  value: "TAG",
                },
                {
                  label: "Página",
                  value: "PAGE",
                },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>

          <Form.Item name="option">
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  type="dashed"
                  htmlType="submit"
                  className="btn-orange"
                  onClick={() => setOption("SAVE")}
                  value={"save"}
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(ShopFormPage);
