import { Breadcrumb, Button, message, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import {
  RouteProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { FileListItem } from "../../../../../../classes/Util";
import UploadMultFile from "../../../../../../components/UploadMultFile";
import APP_ROUTE from "../../../../../../routes/routes";
import ProductApi from "../../../../../../services/ProductApi";

const ProductMedia = (props: RouteProps) => {
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<FileListItem[]>([]);

  const history = useHistory();

  useEffect(() => {}, [params.id]);

  const handlerAddNewFile = (value: any) => {
    setFileList([...fileList, value]);
  };

  const handlerSubmit = () => {
    setLoading(true);
    var values = {
      media: fileList,
      product_id: Number(params.id),
      step: "MEDIA",
    };
    ProductApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        history.push(APP_ROUTE.PRODUCT_CREATE("data", resp?.data.productId));
      })
      .catch((e) => {
        message.error(e.response?.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
    console.log(fileList);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Produtos</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          Medias
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <div className="item">
          <div className="head-item">
            <Typography.Title level={5}>Fotos e videos</Typography.Title>
          </div>
          <div className="body-item">
            <UploadMultFile
              path={"product"}
              hint={""}
              onFile={handlerAddNewFile}
              list={fileList}
              maxFilesCount={4}
            />
          </div>
          <Button
            onClick={handlerSubmit}
            type={"dashed"}
            size={"large"}
            loading={loading}
          >
            PRÓXIMO
          </Button>
        </div>
      </Space>
    </>
  );
};

export default withRouter(ProductMedia);
