import {
  Row,
  Col,
  Statistic,
  Space,
  Button,
  Divider,
  Form,
  Input,
  Typography,
  Dropdown,
  Menu,
  message,
  Radio,
  Table,
  Image,
  Popconfirm
} from 'antd';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import APP_ROUTE from '../../../routes/routes';
import moment from 'moment';
import { Modeling } from '../../../classes/Modeling';
import { Paginate } from '../../../classes/Paginate';
import ModelingApi from '../../../services/ModelingApi';
import ProductionApi from '../../../services/ProductionApi';

const { Title, Text } = Typography;

const CreatorModeling = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [list, setList] = useState<Paginate<Modeling>>();

  const [filterType, setFilterType] = useState<'MODELING' | 'STOCK'>(
    'MODELING'
  );

  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
  }, [filter]);

  useEffect(() => {
    // onLoad();
    setFilter({...filter, ...{type:'modeling'}});
  }, []);

  function goDetail(item: Modeling) {
    history.push(APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(item.id));
  }

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    
    ModelingApi.list(filter, 'creator')
      .then((resp) => {
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function goPayment(data: Modeling) {
    if (data.payment) {
      window.open(data.payment.url, '_blank');
    }
  }

  function changeProductionStatus(recordId:number,productionId:any, newStatus:string)
  {
    ModelingApi.changeStatus(recordId, {
      status:newStatus,
      relation_id:productionId
    }).then((resp) =>{
      message.success(resp?.data.msg);
      onLoad();
    }).catch((e) =>{
      message.error(e.response.data.msg);
    })
  }

  function approve(data: Modeling, option: string) {
    ModelingApi.changeApprovation({ situation: option }, Number(data.id))
      .then((resp) => {
        message.success(resp.data.msg);
        setLoading(true);
        changeProductionStatus(Number(data.id),!!data?.production ? data.production.id : 0,'APPROVED')
        onLoad();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  }

  const OptionsMenu = (record: Modeling) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item
            onClick={() =>
              history.push(
                APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(record.id)
              )
            }
          >
            {record.status == 'CREATED' ? 'Ver/editar' : 'Ver'}
          </Menu.Item>

          {record.situation == 'ON_APPROVED' && record.production?.current_status.status == 'ON_APPROVED' ? (
            <>
              <Menu.Item onClick={() => approve(record, 'APPROVED')}>
                Aprovar
              </Menu.Item>
              <Menu.Item onClick={() => approve(record, 'REPPROVED')} danger>
                Reprovar
              </Menu.Item>
            </>
          ) : (<></>)}

          {record.situation == 'APPROVED' && (
            <Menu.Item>
              <Popconfirm
                title={`Solicitar produção de peça -  ${record.name}`}
                onConfirm={() => history.push(APP_ROUTE.CREATOR_REQUEST_PRODUCTION('modeling',record.id))}
              >
                <Button type="text" style={{ padding: 0 }}>
                  Solicitar produção
                </Button>
              </Popconfirm>
            </Menu.Item>  
          )}

          {record.production?.current_status.status == 'IN_DELIVERY' && (
            <Menu.Item>
              <Popconfirm
                title={`Confirmar recebimento de peça piloto -  ${record.name}`}
                onConfirm={() => changeProductionStatus(Number(record.id),!!record?.production ? record.production.id : 0,'RECEIVED')}
              >
                <Button type="text" style={{ padding: 0 }}>
                  Confirmar recebimento
                </Button>
              </Popconfirm>
            </Menu.Item>  
            )}
          <Menu.Item onClick={() => goPayment(record)}>Ver Cobrança</Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Row justify={'space-between'}>
        <Col>
          <Title level={2}>Modelagem</Title>
        </Col>
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: 'Produto solicitado',
              key: 'name',
              render: (text: any, record: Modeling) => (
                <Space.Compact direction='horizontal'>
                    {!!record.media.reference && (
                      <Image
                        width={50}
                        src={`${process.env.REACT_APP_API}/storage/${record.media?.reference[0]?.file}`}
                      />
                    )}
                    <Space direction="vertical" style={{marginLeft:20}}>
                      <Text>
                        {moment(record.created_at).format('DD/MM/YYYY')}
                      </Text>
                      <Text strong>{record.name}</Text>
                    </Space>
                </Space.Compact>
              ),
            },
            {
              title: 'Tipo',
              key: 'type',
              render: (text: any, record: Modeling) => (
                <Text>
                  {filterType == 'MODELING' && 'Modelagem'}
                  {filterType == 'STOCK' && 'Estoque'}
                </Text>
              ),
            },
            {
              title: 'Status',
              key: 'value',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.status == 'CREATED' && 'Iniciado'}
                  {record.status == 'WAITING_BUDGET' && 'Em orçamento'}
                  {record.status == 'IN_PROGRESS' && 'Em progresso'}
                  {record.status == 'IN_CHARGE' && 'Em produção'}
                  {record.status == 'IN_PAYMENT' && 'Aguardando pagamento'}
                  {record.status == 'PAYED' && 'Pago'}
                </Text>
              ),
            },
            {
              title: 'Situação',
              key: 'situation',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.situation == 'CREATED' && 'Iniciado'}
                  {record.situation == 'ON_APPROVED' && 'Aguardando aprovação'}
                  {record.situation == 'REPPROVED' && 'Reprovada'}
                  {record.situation == 'APPROVED' && 'Aprovada'}
                </Text>
              ),
            },
            {
              title: 'Produção',
              key: 'situation',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.production_situation == 'STARTED' && 'Aguardando inicio'}
                  {record.production_situation == 'IN_PRODUCTION' && 'Em produção'}
                  {record.production_situation == 'IN_CUT' && 'Em corte'}
                  {record.production_situation == 'FINALIZED' && 'Finalizada'}
                  
                  {/* {record.production_situation == 'IN_PILOT' && 'Produzindo piloto'} */}

                  {record.production?.current_status.status == 'IN_PRODUCTION_PILOT' && 'Produzindo Piloto'}
                  {record.production?.current_status.status == 'PILOT_FINALIZED' && 'Piloto finalizado'}
                  {record.production?.current_status.status == 'IN_DELIVERY' && 'Em entrega'}
                  {record.production?.current_status.status == 'RECEIVED' && 'Recebido'}
                  {record.production?.current_status.status == 'ON_APPROVED' && 'Em aprovação'}
                </Text>
              ),
            },
            {
              title: '',
              key: 'options',
              render: (text: any, record: Modeling) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={'middle'}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ['bottomCenter'],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default CreatorModeling;
