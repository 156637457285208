import BaseApi from "./BaseApi";

export default {
  list: (filter?: any) => {
    return BaseApi.get(`admin/product-attribute`, { params: filter });
  },
  addnew: (body: any) => {
    return BaseApi.post(`admin/product-attribute`, body);
  },
};
