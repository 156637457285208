import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Switch,
  Typography,
  Divider,
  Table,
  Menu,
} from "antd";

import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import FellaStatisct from "../../../../../components/FellaStatistic";
import { Paginate } from "../../../../../classes/Paginate";

const { Title, Text } = Typography;

const PageSiteSale = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({});

  const [data, setData] = useState<Paginate<any>>();

  useEffect(() => {
    onLoad();
  }, [filter]);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {}

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <FellaStatisct
            title={"Total de vendas"}
            line={[
              { symbol: "R$", value: "0.00" },
              { symbol: "Qtd", value: "0.00" },
            ]}
          />
        </Col>
        <Col span={6}>
          <FellaStatisct
            title={"Vendas em andamento"}
            line={[
              { symbol: "R$", value: "0.00" },
              { symbol: "Qtd", value: "0.00" },
            ]}
          />
        </Col>
        <Col span={6}>
          <FellaStatisct
            title={"Total vendido"}
            line={[
              { symbol: "R$", value: "0.00" },
              { symbol: "Qtd", value: "0.00" },
            ]}
          />
        </Col>
      </Row>

      <Divider />

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="page-title" level={2}>
            Venda site
          </Title>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Produto",
              key: "product",
            },
            {
              title: "Data",
              key: "data",
            },
            {
              title: "Cliente",
              key: "client",
            },
            {
              title: "Pagamento",
              key: "payment",
            },
            {
              title: "Valor",
              key: "value",
            },
            {
              title: "Status",
              key: "status",
            },
            {
              title: "",
              key: "actions",
            },
          ]}
          dataSource={data?.data}
        ></Table>
      </div>
    </>
  );
};

export default PageSiteSale;
