import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Drawer,
  Breadcrumb,
  Space,
  Typography,
  message,
} from 'antd';
import {
  PlusSquareOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { BaseDict } from '../../../../../../classes/Util';
import { useEffect, useState } from 'react';
import { ProductAttribute } from '../../../../../../classes/ProductAttribute';
import CreatorForm from '../../../../../../components/CreatorForm';
import DepartmentApi from '../../../../../../services/DepartmentApi';
import UserApi from '../../../../../../services/UserApi';
import DepartmentForm from '../../../../../../components/DepartmentForm';
import UploadFile from '../../../../../../components/Upload';
import { useHistory } from 'react-router-dom';
import ProductAttributeForm from '../../../../../../components/ProductAttributeForm';
import ProductAttributeApi from '../../../../../../services/ProductAttributeApi';
import ProductApi from '../../../../../../services/ProductApi';
import APP_ROUTE from '../../../../../../routes/routes';

const ProductBasicInfo = () => {
  const history = useHistory();
  const [formbasic] = Form.useForm();
  const [newDepartment, setNewDepartment] = useState(false);
  const [newCreator, setNewCreator] = useState(false);
  const [departmentList, setDepartmentList] = useState<BaseDict[]>([]);
  const [creatorList, setCreatorList] = useState<BaseDict[]>([]);
  const [productAttributes, setProductAttributes] =
    useState<ProductAttribute>();
  const [newProductAttribute, setNewProductAttribute] = useState(false);
  const [highlightImage, setHighLightImage] = useState<any>();

  const [productTypeNew, setProductTypeNew] = useState('');

  const [sending, setSending] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      loadDepartment();
      loadCreator();
      loadProductAttributes();
    }, 300);
  }, []);

  function loadDepartment() {
    DepartmentApi.listAll({ list: true }).then((resp) => {
      setDepartmentList(resp?.data);
    });
  }

  function loadCreator() {
    UserApi.list({ type: 'CREATOR' })
      .then((resp) => {
        setCreatorList(resp?.data);
      })
      .finally(() => {
        setNewCreator(false);
      });
  }

  function loadProductAttributes() {
    ProductAttributeApi.list().then((resp) => {
      setProductAttributes(resp?.data);
    });
  }

  function updateDepartment() {
    loadDepartment();
  }

  function setNewValue(val?: any, key?: string) {
    if (!!key) {
      const currentAttributes = productAttributes;

      if (key == 'COLLECTION') {
        let collection = currentAttributes?.collection;
        collection?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'COLOR') {
        let colors = currentAttributes?.color;
        colors?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'CATEGORY') {
        let category = currentAttributes?.category;
        category?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'STYLE') {
        let style = currentAttributes?.style;
        style?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'TYPE') {
        let type = currentAttributes?.type;
        type?.push({
          id: val.id,
          name: val.name,
        });
      }

      setProductAttributes(currentAttributes);
    }
  }

  const handerlBasicSubmit = (values: any) => {
    setSending(true);
    values = {
      ...values,
      ...{
        highlight_image: highlightImage,
        step: 'BASIC',
      },
    };
    ProductApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        history.push(APP_ROUTE.PRODUCT_CREATE('media', resp?.data.productId));
      })
      .catch((e) => {
        message.error(e.response?.data.msg);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <>
      <Drawer
        title={'Novo criador'}
        open={newCreator}
        closable={true}
        size={'large'}
        destroyOnClose={true}
        onClose={() => {
          setNewCreator(false);
        }}
      >
        <CreatorForm onUpdate={loadCreator} />
      </Drawer>

      <Drawer
        title={'Novo departamento'}
        open={newDepartment}
        closable={true}
        size={'large'}
        destroyOnClose={true}
        onClose={() => setNewDepartment(false)}
      >
        <DepartmentForm
          onUpdate={updateDepartment}
          newItem={(e) => {
            console.log(e);
          }}
          onClose={() => setNewDepartment(false)}
        />
      </Drawer>

      <Drawer
        title={'Incluir novo'}
        open={newProductAttribute}
        closable={true}
        size={'large'}
        destroyOnClose={true}
        onClose={() => setNewProductAttribute(false)}
      >
        <ProductAttributeForm
          newItem={(e) => {
            setNewValue(e, productTypeNew);
          }}
          onClose={() => setNewProductAttribute(false)}
          type={productTypeNew}
        />
      </Drawer>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Produtos</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          Dados básicos
        </Breadcrumb.Item>
      </Breadcrumb>
      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: '100%' }}
        wrap
      >
        <div className="item">
          <div className="head-item">
            <Typography.Title level={5}>Dados do produto</Typography.Title>
          </div>
          <div className="body-item">
            <Form
              form={formbasic}
              layout={'vertical'}
              onFinish={handerlBasicSubmit}
            >
              <Form.Item
                name={'name'}
                label={'Nome do produto'}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              

              <Row align={'middle'} gutter={[16, 16]}>
                <Col span={20}>
                  <Form.Item
                    name={'creator'}
                    label={'Criador'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecione um criador',
                      },
                    ]}
                  >
                    <Select
                      labelInValue
                      showSearch
                      placeholder={'Criador'}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={creatorList.map((item: BaseDict) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    icon={<PlusSquareOutlined />}
                    onClick={() => setNewCreator(true)}
                  ></Button>
                </Col>
              </Row>

              <Row align={'middle'} gutter={[16, 16]}>
                <Col span={20}>
                  <Form.Item
                    name={'department'}
                    label={'Departamento'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecione um departamento',
                      },
                    ]}
                  >
                    <Select
                      labelInValue
                      showSearch
                      placeholder={'Departamento'}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={departmentList.map((item: BaseDict) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    icon={<PlusSquareOutlined />}
                    onClick={() => setNewDepartment(true)}
                  ></Button>
                </Col>
              </Row>

              <Row align={'middle'} gutter={[16, 16]}>
                <Col span={20}>
                  <Form.Item
                    name={'collection'}
                    label={'Coleção'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecione uma coleção',
                      },
                    ]}
                  >
                    <Select
                      labelInValue
                      showSearch
                      placeholder={'Coleção'}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={productAttributes?.collection.map(
                        (item: BaseDict) => ({
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    icon={<PlusSquareOutlined />}
                    onClick={() => [
                      setProductTypeNew('COLLECTION'),
                      setNewProductAttribute(true),
                    ]}
                  ></Button>
                </Col>
              </Row>

              <Row>
                <Form.Item label={'Imagem de destaque'}>
                  <UploadFile
                    path={'product'}
                    hint={''}
                    onFile={setHighLightImage}
                  />
                </Form.Item>
              </Row>

              <Form.Item>
                <Button htmlType={'submit'} type={'dashed'} size={'large'}>
                  PRÓXIMO
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Space>
    </>
  );
};

export default ProductBasicInfo;
