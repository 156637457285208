import { CountItens } from '../classes/Stats';
import BaseApi from './BaseApi';

export default {
  list: (filter?: any, endpoint?: string) => {
    return BaseApi.get(`/${endpoint}/modeling`, { params: filter });
  },
  requestApprove: (id: number) => {
    return BaseApi.put(`/admin/modeling/${id}/send-approve`);
  },
  startBudget:(id:number) =>{
    return BaseApi.put(`/admin/modeling/${id}/start-budget`);
  },
  productState:(id:number, status:string) =>{
    return BaseApi.put(`/admin/modeling/${id}/product-state`, {production_situation:status});
  },
  onSearch:(term:string) =>{
    return BaseApi.get(`/admin/modeling/search/search-by-term`,{params:{term:term}});
  },
  getItem: (id: number, endpoint?: string) => {
    return BaseApi.get(`/${endpoint}/modeling/${id}`);
  },
  addnew: (body: any, endpoint: string) => {
    return BaseApi.post(`/${endpoint}/modeling`, body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`/admin/modeling/${id}`);
  },
  update: (body: any, id: number, endpoint: string) => {
    return BaseApi.put(`/${endpoint}/modeling/${id}`, body);
  },
  enable: (id: number) => {
    return BaseApi.put(`/admin/modeling/${id}/enable`);
  },
  charge: (id: number) => {
    return BaseApi.put(`/admin/modeling/${id}/generate-charge`);
  },
  stats:(body?:any) =>{
    return BaseApi.get<CountItens>(`/admin/modeling/utils/stats`, {params:body});
  },

  //CREATOR
  changeApprovation: (body: any, id: number) => {
    return BaseApi.put(`/creator/modeling/${id}/send-approve`, body);
  },
  changeStatus:(id:number, body:any)=>{
    return BaseApi.put(`/creator/modeling/production/${id}/change-status`, body);
  }
};
