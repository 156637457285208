import { Col, Form, Row, Space, Spin, Typography, Divider, Image, Radio, Button, Input, InputNumber, Table, Select, Drawer, Modal, message } from "antd";
import {
  PlusSquareOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UploadOutlined,
  PlusOutlined,
  SaveOutlined,
  EditOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ProductMeasure } from "../../../classes/Measure";
import { Modeling } from "../../../classes/Modeling";
import ModelingApi from "../../../services/ModelingApi";
import ProductMeasureApi from "../../../services/ProductMeasureApi";
import { CurrencyFormatter, CurrencyParser } from "../../../utils/currencyFormater";
import CommonApi from "../../../services/CommonApi";
import { ProductAttribute } from "../../../classes/ProductAttribute";
import { BaseDict } from "../../../classes/Util";
import { ProductionItem, ProductionItemList } from "../../../classes/Production";
import ProductionItemForm from "./productionitem";
import modal from "antd/lib/modal";
import ProductionApi from "../../../services/ProductionApi";
import APP_ROUTE from "../../../routes/routes";
import ProductApi from "../../../services/ProductApi";

const {Title, Text} = Typography;

interface ParamsUrl{
    type:string,
    relation:string
}




const ProductionPage = () =>{
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [currentModeling, setCurrentModeling] = useState<Modeling>();
    const [listMeasure, setListMeasure] = useState<ProductMeasure[]>([]);
    const [finalListMeasure, setFinalListMeasure] = useState<ProductMeasure[]>([]);
    const [suggestedValue, setSuggestedValue] = useState<any>();
    const [itens, setItens] = useState<ProductionItem[]>([]);
    const [newItem, setNewItem] = useState(false);
    const [loadingMeasure, setLoadingMeasure] = useState(false);
    const [colors, setColors] = useState<BaseDict[]>([]);

    const [finalizing, setFinalizing] = useState(false);

    const [currentItem, setCurrentItem] = useState<ProductionItem>();
    const [currentIndex, setCurrentIndex] = useState(null);
    const [totalValue, setTotalValue] = useState(0);
    
    const [selectColorId, setSelectColorId] = useState<Array<number>>([]);


    let params = useParams<ParamsUrl>();

    useEffect(() =>{
        loadColors();
        loadMeasure();
        if(params.type.toUpperCase() == 'MODELING'){
            setLoading(true);
            ModelingApi.getItem(Number(params.relation),'creator').then((resp) =>{
                setCurrentModeling(resp?.data);
            }).finally(() =>{
                setLoading(false);
            })
        }

        if(params.type.toUpperCase() == 'PRODUCT'){
            setLoading(true);
            ProductApi.getItem(Number(params.relation),'creator').then((resp) =>{
                
            })
        }
    },[params.relation]);

    useEffect(() =>{
        if(!!currentModeling?.measure_type){
            setFinalListMeasure([]);
            let measureList = listMeasure.filter((item) =>{
                return item.unity === currentModeling?.measure_type;
            });
            setListMeasure(measureList);
        }
    },[currentModeling]);

    useEffect(() =>{
        let total = 0;
        itens.forEach((data) =>{
            data.production_itens?.forEach((it) =>{
                total += it.value
            });
        });
        setTotalValue(total);
    },[itens])

    function loadColors(){
        CommonApi.attributes('COLOR').then((resp) =>{
            setColors(resp?.data);
        });
    }


    function loadMeasure() {
        setLoadingMeasure(true);
        CommonApi.measures()
        .then((resp) => {
            setListMeasure(resp?.data);
        })
        .finally(() => {
            setLoadingMeasure(false);
        });
    }

    const changeProductionItem = (values:any, index:any) =>{
        setSelectColorId([...selectColorId, values.color.key]);
        let data = [...itens];
        if(typeof index === 'number'){
           
            data[Number(index)] = values;
            setNewItem(false);
            setItens(data);
        }else{
            setNewItem(false);
            
            setItens([...itens, values]);
        }       
    }

    const openEdit = (value:any, index:any) =>{
        setCurrentItem(value);
        setCurrentIndex(index)

        setTimeout(() =>{
            setNewItem(true);
        },300)
        
    }

    const removeItem = (item:any, index:number) =>{
        let data = [...itens];
        
        let currentColorId = data[index].color?.key;

        if(!!currentColorId){
            let list = [...selectColorId];
            let colorIndxId = selectColorId.findIndex(it => it === currentColorId);
            list.splice(colorIndxId, 1);
            setSelectColorId(list);
        }

        data.splice(index, 1);
        setItens(data);
    }

    const openNewProduction = () =>{
        setCurrentItem(undefined);
        setNewItem(true)
    }

    const convertValue = (value:any) =>{
        let cValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'BRL',
        });

        return cValue.format(value);
    }

    

    const handlerSubmit = (values:any) =>{
        modal.confirm({
            title: 'Informação',
            icon: <CheckOutlined />,
            content:
                `Será gerado uma cobrança no valor de ${convertValue(totalValue)}. Após a confirmação do pagamento, iremos iniciar a produção das peças!`,
            okText: 'Ok',
            onOk: () => {
                setFinalizing(true);
                ProductionApi.new('creator',{
                    old_item:params.type.toUpperCase(),
                    type:'PRODUCTION',
                    item_type:'PRODUCT',
                    item_id:Number(params.relation),
                    itens:itens
                }).then((resp) =>{
                    message.success(resp?.data.msg);
                    history.push(APP_ROUTE.CREATOR_PRODUCT_SOLICITATION());
                }).catch((e)=>{
                    message.error(e.response.data.msg)
                }).finally(() =>{
                    setFinalizing(false);
                });
            },
        });
        console.log(values);
        console.log(itens);
    }
    
    return(
        <>  
            <Drawer size={'large'} open={newItem} title={'Novo item'} closable={true} destroyOnClose={true} onClose={() => setNewItem(false)}>
                <ProductionItemForm 
                    onSave={changeProductionItem} 
                    onOpen={newItem}
                    currentItem={currentItem}
                    currentIndex={currentIndex}
                    selectedColors={selectColorId}
                    colors={colors}    
                    suggested_value={currentModeling?.total_material} 
                    measure={listMeasure}           
                />
            </Drawer>

            <Row justify={'space-between'}>
                <Col>
                    <Title level={2}>Solicitação de produção</Title>
                </Col>
            </Row>

            <Form
                style={{ width: "100%" }}
                layout={"vertical"}
                form={form}
                onFinish={handlerSubmit}
            >
                <Space
                    size={[40, 40]}
                    align="start"
                    direction="vertical"
                    style={{ width: "100%" }}
                >
                    {loading ? (
                        <div style={{ width: "100%", margin:'100px auto' }}>
                            <Row justify={"center"}>
                                <Spin size="large"></Spin>
                            </Row>
                        </div>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <Text>Aqui você pode fazer a solicitação para produção de peça, que será vendida em sua loja. Preencha os campos abaixo:</Text>
                            <Divider />
                            {!!currentModeling && (
                                <div>
                                    {currentModeling.media.reference && (
                                        <Image src={`${process.env.REACT_APP_API}/storage/${currentModeling?.media?.reference[0].file}`}/>
                                    )}
                                    <p><strong>Modelagem:</strong> {currentModeling.name}</p>
                                    <p><strong>Descrição:</strong> {currentModeling.description}</p>

                                    <p><strong>Custo da modelagem(Profissionais / Materiais):</strong> {convertValue(currentModeling.total_pearson + currentModeling.total_material)}</p>
                                    <p><strong>Valor de produção</strong> {convertValue(currentModeling.total_material)}</p>
                                </div>
                            )}
                            
                               
                              <Divider />
                                
                                <Space direction="vertical" style={{width:'100%'}}>
                                    {itens.map((item:ProductionItem, index:number) => (
                                        <>
                                            <Row justify={'space-between'} align={"middle"}>
                                                <Col>
                                                    <Title level={3}>{item.color?.label}</Title>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Button icon={<EditOutlined />} type="text" onClick={() => {
                                                            openEdit(item, index);
                                                        }}></Button>
                                                        <Button icon={<MinusCircleOutlined />} type="text" onClick={() => {
                                                            removeItem(item, index)
                                                        }}></Button>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Table 
                                                dataSource={item.production_itens}
                                                columns={[
                                                    {
                                                        title: 'Tamanho',
                                                        key: 'name',
                                                        render: (text: any, record: any) => (
                                                            <>{record.label}</>
                                                        )
                                                    },
                                                    {
                                                        title: 'Quantidade',
                                                        key: 'name',
                                                        render: (text: any, record: any) => (
                                                            <>{record.size}</>
                                                        )
                                                    },
                                                    {
                                                        title: 'Valor',
                                                        key: 'value',
                                                        render: (text: any, record: any) => (
                                                            <>{convertValue(record.value)}</>
                                                        )
                                                    }
                                                ]}
                                            />

                                            <Divider />
                                        </>
                                    ))}
                    
                                </Space>
                                {totalValue > 0 && (
                                    <Row justify={'space-between'}>
                                        <Col>
                                            <Text strong>Total:</Text>
                                        </Col>

                                        <Col>
                                            <Text>{convertValue(totalValue)}</Text>
                                        </Col>
                                    </Row>            
                                )}
                                <Row justify={'end'}>
                                    <Button icon={<PlusOutlined />} onClick={() => openNewProduction()}>Novo item</Button>
                                </Row>
                        </div>
                    )}
                   
                   <Button type="primary" className="btn-orange" htmlType="submit" disabled={itens.length > 0 ? false : true} loading={finalizing}>Finalizar solicitação</Button>

                </Space>

            </Form>
        </>
    )
}

export default ProductionPage;