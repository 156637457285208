import BaseApi from "./BaseApi"

export default {
    loadTypes:(type?:string) =>{
        return BaseApi.get(`/admin/types?type=${type}`);
    },
    loadTypesFilter:(type?:string) =>{
        return BaseApi.get(`/admin/types?type=${type}&filter=true`);
    },
    addnew:(body:any) =>
    {
        return BaseApi.post(`/admin/types`,body);
    }
}