import { Menu, Typography } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { Item, MenuItens } from '../../classes/Util';
import { useAppContext } from '../../context/app-context';
import APP_ROUTE from '../../routes/routes';
import {OptionsMenuCreator, OptionsMenuAdministrator} from './menu'

export default function AppMenu(){
  const history = useHistory();
  let match = useRouteMatch();
  const { user } = useAppContext();
  const [menuItens, setMenuItens] = useState<MenuItens[]>([]);

  useEffect(() => {
    if (user?.profile == 'CREATOR') {
      setMenuItens(OptionsMenuCreator);
    }

    if (user?.profile == 'ADMINISTRATOR') {
      setMenuItens(OptionsMenuAdministrator);
    }
  }, []);

  return (
    <>
      {user ? (
        <Menu
          mode="inline"
          theme="light"
          defaultOpenKeys={['sub1']}
          defaultSelectedKeys={['1']}
        >
          {menuItens.map((item: MenuItens, index: number) => (
            <>
              {!!item.children && item.children?.length > 0 ? (
                <>
                  <Menu.SubMenu
                    title={item.label}
                    key={`${item.key}_i_${index}`}
                  >
                    {item.children?.map((children: Item, subIndex: number) => (
                      <>
                        <Menu.Item key={`${item.key}_s_${subIndex}`}>
                          <NavLink
                            to={
                              !!children.route
                                ? children.route
                                : APP_ROUTE.HOME()
                            }
                          >
                            {children.label}
                          </NavLink>
                        </Menu.Item>
                      </>
                    ))}
                  </Menu.SubMenu>
                </>
              ) : (
                <>
                  <Menu.Item>
                    <NavLink
                      to={{
                        pathname: !!item.route ? item.route : APP_ROUTE.HOME(),
                      }}
                      target={item.newTab ? '_blank' : '_self'}
                    >
                      {item.label}
                    </NavLink>
                  </Menu.Item>
                </>
              )}
            </>
          ))}
        </Menu>
      ) : (
        <></>
      )}
    </>
  );
};

