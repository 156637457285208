import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Button, Tooltip, Upload, UploadFile, message } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import { FileListItem } from '../../classes/Util';
import ImgCrop from 'antd-img-crop';
import { DndProvider, useDrag, useDrop } from 'react-dnd';

import { UploadOutlined } from '@ant-design/icons';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AuthApi from '../../services/AuthApi';
import ProductApi from '../../services/ProductApi';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const type = 'DragableUploadList';

interface DragableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
  removeFilePath?: string;
}

const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${
        isOver ? dropClassName : ''
      }`}
      style={{ cursor: 'move' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

interface Options {
  list: FileListItem[];
  onFile: (val?: any) => void;
  reOrder: (val?: Array<{ id?: string; position: number }>) => void;
  path: string;
  removeFilePath?: string;
}

const CustomUploadFiles: FC<Options> = ({
  list,
  onFile,
  reOrder,
  path,
  removeFilePath,
}: Options) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const { access_token } = AuthApi.getToken();

    setAccessToken(access_token);
  }, [AuthApi.getToken()]);

  useEffect(() => {
    setFileList([]);
    if (!!list && list.length) {
      let files: Array<UploadFile> = [];
      let newList = list.map((item) => {
        return {
          uid: !!item.id
            ? item.id.toString()
            : generateRandomInteger(9999).toString(),
          name: item.original_name,
          url: `${process.env.REACT_APP_API}/storage/${item.file}`,
        };
      });

      setFileList(newList);
    }
  }, [list]);

  useEffect(() => {
    let orderItens: Array<{ id: string; filename: string; position: number }> =
      [];
    fileList.forEach((item, index) => {
      console.log(item);
      orderItens.push({
        id: !!item.uid ? item.uid : '',
        filename: item.name,
        position: index,
      });
    });
    reOrder(orderItens);
  }, [fileList]);

  function generateRandomInteger(max: number) {
    return Math.floor(Math.random() * max) + 1;
  }

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];
      setFileList((currentFileList) => {
        const newFileList = [...currentFileList];
        newFileList.splice(dragIndex, 1);
        newFileList.splice(hoverIndex, 0, dragRow);
        return newFileList;
      });
    },
    [fileList]
  );

  const onChange: UploadProps['onChange'] = (info) => {
    let itens = [...info.fileList];

    itens = itens.map((file, index: number) => {
      if (file.response) {
        onFile({
          file: file.response.file,
          type: file.type,
          order: index,
          original_name: file.originFileObj?.name,
        });
      }
      return file;
    });

    setFileList(itens);
  };

  const handleRemove = (values: any) => {
    console.log(values);
    ProductApi.removeMedia(values.uid, 'endpoint')
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ImgCrop rotate>
        <Upload
          name={'image'}
          data={{
            path: path,
          }}
          action={`${process.env.REACT_APP_API}/api/upload-file`}
          method={'POST'}
          multiple={true}
          headers={{
            authorization: `Bearer ${accessToken}`,
          }}
          fileList={fileList}
          listType={'picture-card'}
          onChange={onChange}
          onRemove={handleRemove}
          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
        >
          <UploadOutlined />
        </Upload>
      </ImgCrop>
    </DndProvider>
  );
};

export default CustomUploadFiles;
