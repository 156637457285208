import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Row,
  Switch,
  Typography,
} from 'antd';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  StarOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import FeedbackApi from '../../../services/FeedbackApi';
import { Paginate } from '../../../classes/Paginate';
import Feedback from '../../../classes/Feedback';
import moment from 'moment';

const FeedBackAdminPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Paginate<Feedback>>();

  const [filter, setFilter] = useState({});

  useEffect(() => {
    console.log('euuu');
    onLoad();
  }, []);

  useEffect(() => {
    onLoad();
  }, [filter]);

  function onLoad() {
    setLoading(true);
    FeedbackApi.list(filter)
      .then((resp) => {
        setData(resp?.data);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  return (
    <>
      <Typography.Title level={2}>Feedback</Typography.Title>
      <Row align="middle" justify="space-between" gutter={[16, 16]}>
        {' '}
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />

      <div className="wrapper-table">
        <List
          loading={loading}
          dataSource={data?.data}
          pagination={{
            pageSize: !!data?.per_page ? data?.per_page : 0,
            current: data?.current_page ? data?.current_page : 1,
            total: data?.total ? data?.total : 0,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
          renderItem={(item, index: number) => (
            <List.Item
              key={index}
              actions={[
                <>
                  {[...Array(item.rating + 1)].map((v: any, index: number) => (
                    <StarOutlined />
                  ))}
                  <p>
                    <CalendarOutlined />{' '}
                    {moment(item.created_at).format('DD/MM/YYYY')}
                  </p>
                </>,
              ]}
            >
              <List.Item.Meta title={item.name} description={item.text}>
                <p>
                  <strong>Classificação:</strong>
                </p>
              </List.Item.Meta>
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default FeedBackAdminPage;
