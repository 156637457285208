import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.css';
import './assets/style/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import React from 'react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
   <App />
);
