import {
  Form,
  Row,
  Button,
  Breadcrumb,
  Typography,
  Space,
  Input,
  Select,
  Col,
  Image,
  InputNumber,
  Table,
  Divider,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  RouteProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import { ProductMeasure } from '../../../../../classes/Measure';
import { Product } from '../../../../../classes/Product';
import { BaseDict, ProductDict } from '../../../../../classes/Util';
import APP_ROUTE from '../../../../../routes/routes';
import ProductApi from '../../../../../services/ProductApi';
import SaleApi from '../../../../../services/SaleApi';
import UserApi from '../../../../../services/UserApi';
import {
  CurrencyFormatter,
  CurrencyParser,
} from '../../../../../utils/currencyFormater';

import './style.scss';

const { Title, Text } = Typography;

const PageWholeSaleForm = (props: RouteProps) => {
  const history = useHistory();
  let params = useParams<{ id?: string }>();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState<ProductDict[]>([]);
  const [clientList, setClientList] = useState<BaseDict[]>([]);
  const [isEdit, setIsEdit] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [currentSelectedProduct, setCurrentSelectedProduct] =
    useState<ProductDict>();
  const [currentProduct, setCurrentProduct] = useState<Product>();

  const [loadingProduct, setLoadingProduct] = useState(false);

  useEffect(() => {
    loadClient();
    loadProduct();
  }, []);

  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);
    }
  }, [params.id]);

  useEffect(() => {
    setLoadingProduct(true);
    let productSel = productList.filter((item) => {
      return item.id == Number(selectedProduct?.value);
    });

    setCurrentSelectedProduct(productSel[0]);

    ProductApi.getItem(Number(selectedProduct?.value),'admin', { showHasValue: true })
      .then((resp) => {
        setCurrentProduct(resp?.data);
        form.setFieldsValue({
          value: resp?.data.price,
          cost: resp?.data.expense,
          margin: resp?.data.margin,
        });
      })
      .finally(() => {
        setLoadingProduct(false);
      });
  }, [selectedProduct]);

  function loadProduct() {
    ProductApi.listDict().then((resp) => {
      setProductList(resp?.data);
    });
  }

  function loadClient() {
    UserApi.list({ type: 'CLIENT' }).then((resp) => {
      setClientList(resp?.data);
    });
  }

  const handlerMargin = (e: any) => {
    let marginvalue = Number(form.getFieldValue('value')) - Number(e);
    form.setFieldValue('margin', marginvalue);
    console.log(e);
  };

  const handlerSize = (val: any) => {
    let measures = form.getFieldValue('measure');

    let sizeAll = 0;

    form
      .getFieldValue('measure')
      .forEach((item: ProductMeasure, index: number) => {
        sizeAll += Number(item.stock);
      });

    form.setFieldsValue({
      totalCost: sizeAll * form.getFieldValue('value'),
      totalMargin: sizeAll * form.getFieldValue('margin'),
    });
    console.log(form.getFieldValue('measure'));
    console.log(val);
  };

  const handlerSubmit = (values: any) => {
    setLoading(true);
    SaleApi.newItem(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        history.replace(APP_ROUTE.PURCHASE_WHOLESALE());
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row justify="end">
        <Button
          type="text"
          onClick={() => {
            history.goBack();
          }}
        >
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? 'Atualizar' : 'Cadastrar'} Venda
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>
            {isEdit ? 'Atualizar venda' : 'Cadastrar venda'}
          </Title>
        </Col>
      </Row>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: '100%' }}
        wrap
      >
        <Space
          size={[40, 40]}
          align="start"
          direction="vertical"
          style={{ width: '100%' }}
          className="form-steps"
        >
          <Form form={form} layout={'vertical'} onFinish={handlerSubmit}>
            <Space
              size={[40, 40]}
              align="start"
              direction="vertical"
              style={{ width: '100%' }}
              className="form-steps"
            >
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item
                    name={'client'}
                    label={'Cliente'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecione o cliente',
                      },
                    ]}
                  >
                    <Select
                      labelInValue
                      showSearch
                      placeholder={'Cliente'}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={clientList.map((item: BaseDict) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={'product'}
                    label={'Produt'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecione o produto',
                      },
                    ]}
                  >
                    <Select
                      labelInValue
                      showSearch
                      placeholder={'Produto'}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      onChange={(e) => {
                        //setSelectedProduct
                        setSelectedProduct(e);
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={productList.map((item: BaseDict) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>

                {currentSelectedProduct && (
                  <Col span={8}>
                    <Row
                      gutter={[16, 16]}
                      align={'middle'}
                      className={'product-show'}
                    >
                      <Col>
                        <Image
                          width={40}
                          src={`${process.env.REACT_APP_API}/storage/${currentSelectedProduct?.media}`}
                        />
                      </Col>
                      <Col>
                        <Text>{currentSelectedProduct?.name}</Text>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name={'value'} label={'Valor de venda'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={'cost'} label={'Custo'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      onChange={handlerMargin}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={'margin'} label={'Margem'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {currentProduct && (
                <Row>
                  <Form.List name={'measure'}>
                    {(fields) => (
                      <>
                        <Table
                          size="small"
                          className="measure-table"
                          pagination={false}
                          loading={loadingProduct}
                          rowKey={(record) => record.id || 0}
                          columns={[
                            {
                              title: 'Tamanho',
                              key: 'size',
                              render: (
                                text: any,
                                record: ProductMeasure,
                                index: number
                              ) => (
                                <>
                                  <Form.Item
                                    name={[index, 'name']}
                                    initialValue={record.label}
                                  >
                                    <Input
                                      value={record.label}
                                      readOnly
                                      disabled
                                    />
                                  </Form.Item>
                                </>
                              ),
                            },

                            {
                              title: 'Estoque',
                              key: 'stock',
                              render: (
                                text: any,
                                record: ProductMeasure,
                                index: number
                              ) => (
                                <>
                                  <Form.Item
                                    name={[index, 'stock']}
                                    initialValue={record.stock}
                                  >
                                    <Input
                                      type="number"
                                      value={record.stock}
                                      onChange={handlerSize}
                                    />
                                  </Form.Item>
                                </>
                              ),
                            },
                          ]}
                          dataSource={currentProduct?.measure}
                        />
                      </>
                    )}
                  </Form.List>
                </Row>
              )}

              <Divider />

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name={'totalCost'} label={'Custo total'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={'totalMargin'} label={'Lucro'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>

            <Form.Item>
              <Button
                htmlType="submit"
                type="default"
                size="large"
                loading={loading}
              >
                Gerar venda
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Space>
    </>
  );
};

export default withRouter(PageWholeSaleForm);
