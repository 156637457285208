import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { StarOutlined, StarFilled } from '@ant-design/icons';
import { Button, Divider, Form, message, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FeedbackApi from '../../services/FeedbackApi';

const { Title, Text } = Typography;

const FeedbackPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loadingStats, setLoadingStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentRating, setCurrentRating] = useState(0);

  const ratingLimit = 5;

  const handleSubmit = (values: any) => {
    setLoading(true);
    values = {
      ...values,
      ...{
        rating: currentRating,
      },
    };

    FeedbackApi.add(values)
      .then((resp) => {
        message.success(resp.data.msg);
        form.resetFields();
        setCurrentRating(0);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Title level={2}>Feedback</Title>
      </Row>
      <Divider />
      <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
        <Form.Item label={'Como está sendo a sua experiência om a fella?'}>
          <Row>
            {[...Array(ratingLimit)].map((x: number, index: number) => (
              <>
                {index - 1 < currentRating ? (
                  <StarFilled onClick={() => setCurrentRating(index)} />
                ) : (
                  <StarOutlined onClick={() => setCurrentRating(index)} />
                )}
              </>
            ))}
          </Row>
        </Form.Item>
        <Form.Item name="text" label="Gostaria de contar como tem sido?">
          <TextArea
            showCount
            maxLength={200}
            rows={4}
            placeholder="Conte-nos mais"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            className="btn-orange"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FeedbackPage;
