import BaseApi from './BaseApi';

export default {
  list: (filter?: any) => {
    return BaseApi.get('/admin/feedback', { params: filter });
  },
  add: (body: any) => {
    return BaseApi.post('/feedback/add', body);
  },
  delete: (id: number) => {
    return BaseApi.delete(`/admin/feedback/${id}`);
  },
};
