import { Modal, Typography } from "antd";
import { FC } from "react";

const { Title, Text } = Typography;

export interface AppModalOptions {
  show?: boolean;
  title?: string;
  text?: string;
  type: "CONFIRM" | "SUCCESS";
  onOk: () => void;
  onCancel: () => void;
}

const AppModal: FC<AppModalOptions> = ({
  show,
  title,
  text,
  type,
  onOk,
  onCancel,
}: AppModalOptions) => {
  return (
    <>
      {type == "CONFIRM" && (
        <Modal
          visible={show}
          title={title}
          onOk={onOk}
          onCancel={onCancel}
          okText={"Sim"}
          cancelText={"Não"}
        >
          <Text>{text}</Text>
        </Modal>
      )}

      {type == "SUCCESS" && (
        <Modal
          visible={show}
          title={title}
          onOk={onOk}
          onCancel={onCancel}
          okText={"Fechar"}
        >
          <Text>{text}</Text>
        </Modal>
      )}
    </>
  );
};

export default AppModal;
