import { Form, Input, Modal, Radio, RadioChangeEvent, Select, Space, Typography } from "antd"
import { FC, useState } from "react";
import { Shipping } from "../../../../../classes/Shipping";
import { BaseDict } from "../../../../../classes/Util";
import ModelingApi from "../../../../../services/ModelingApi";
import UtilApi from "../../../../../services/UtilApi";

const {Text} = Typography;

interface Options{
    open?:boolean;
    onClose:() => void;
    creatorId:number;
    modelingId:number;
    listPacking:BaseDict[];
    onCharge:(val?:any) => void;
}

const DialogCharge:FC<Options> = ({open,creatorId,listPacking,modelingId, onCharge,onClose}:Options) =>{

    const [form] = Form.useForm();
    const [loadingShipping, setLoadingShipping] = useState(false);
    const [shippingOptions, setShippingOptions] = useState<Shipping[]>();
    const [currentShipping, setCurrentShipping] = useState<Shipping>();

    const [allowSubmit, setAllowSubmit] = useState(false);

    const [isSeding, setIsSending] = useState(false)

    const submitCharge = (values:any) =>{
        setLoadingShipping(true);
        let fieldValue = form.getFieldsValue();
        fieldValue = {...fieldValue, ...{creatorId:creatorId }}
        UtilApi.Shipping(fieldValue).then((resp) =>{
          setShippingOptions(resp?.data);
        }).finally(()=>{
          setLoadingShipping(false);
        })
    }

    const onChange = (e: RadioChangeEvent) => {
      setAllowSubmit(true);

      let opt = shippingOptions?.filter((item) => {return item.tipo == e.target.value});
      if(!!opt){
        setCurrentShipping(opt[0] as Shipping);
      }
      console.log('radio checked', e.target.value);
      // setValue(e.target.value);
    };

    function handler(){
      let fieldValues = form.getFieldsValue();
      fieldValues = {...fieldValues, ...{shipping:currentShipping, modeling:modelingId}};
      console.log(fieldValues);
      onCharge(fieldValues); 
    }

    function onCloseDialog(){
      form.resetFields();
      setShippingOptions([]);
      setLoadingShipping(false);
      setAllowSubmit(!allowSubmit)
      onClose();
    }

    return(
        <Modal title="Cobrança e frete" 
        open={open} 
        onCancel={onCloseDialog}
        okText={!!allowSubmit ? 'Gerar cobrança' : 'Calcular frete'}
        okButtonProps={{ disabled: !allowSubmit, onClick:() => handler(), loading:isSeding}}
        cancelButtonProps={{danger:true}}
      >
        <Text>Preencha as informações abaixo para o calculo do frete de envio da <strong>peça piloto</strong>.</Text> <br /><br />
        <Form form={form} onFinish={submitCharge} layout={'vertical'}>
            <Form.Item label={'Peso aproximado'} name={'weight'}>
              <Input />
            </Form.Item>
            <Form.Item label={'Caixa'} name={'packing'} help={'Selecione uma caixa para a entrega'}>
                <Select 
                  style={{width:200}}
                  placeholder={'Escolha uma opção'}
                  options={listPacking}
                  onChange={submitCharge}
                  loading={loadingShipping}
                />
            </Form.Item>
            {!!shippingOptions && shippingOptions.length > 0 ? (
            <Form.Item label={'Frete'} name={'shipping'} help={'Selecione uma opção para entrega'}>
                <Radio.Group onChange={onChange}>
                  <Space direction="vertical">
                    {shippingOptions.map((item) =>(
                      <Radio value={item.tipo}>
                        <p><strong>{item.tipo}</strong> <br /> R$ {item.valor}<br /> Em até {item.prazo} dia(s)</p>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
            </Form.Item>
            ) : (<></>)}
        </Form>
      </Modal>
    )
}

export default DialogCharge;