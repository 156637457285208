import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APP_ROUTE from "../../../../routes/routes";
import CouponApi from "../../../../services/CouponApi";
import { Paginate } from "../../../../classes/Paginate";
import { Coupon } from "../../../../classes/Coupon";
import { fromOptions, Options } from "../form/const";

const { Title, Text } = Typography;

const CouponPage = () => {
  const history = useHistory();
  const [loadingStats, setLoadingStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const [couponsList, setCouponsList] = useState<Paginate<Coupon>>();

  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    onLoad();
  }, [filter]);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    CouponApi.listAll(filter)
      .then((resp) => {
        setCouponsList(resp?.data);
      })
      .catch((e) => {
        console.log(e.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function removeItem(item: Coupon) {
    CouponApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        // onStats();
        onLoad();
      });
    console.log(item);
  }

  function goDetail(item: Coupon) {
    history.push(APP_ROUTE.COUPON_VIEW(item.id));
  }

  const OptionsMenu = (record: Coupon) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Cupons ativos`}
                prefix={"Qtd."}
                value={0}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Cupons inativos`}
                prefix={"Qtd."}
                value={0}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.COUPON_CREATE())}
          >
            Gerar cupom
          </Button>
        </Col>
      </Row>

      <Divider />
      <Title level={2}>Cupons de desconto</Title>
      <Row align="middle" justify="space-between" gutter={[16, 16]}>
        <Col>
          <Row gutter={[16, 16]}>
            <Col>
              <Button onClick={() => setFilter({ ...filter, ...{ from: "" } })}>
                Todos
              </Button>
            </Col>
            {fromOptions.map((item: Options, index: number) => (
              <Col>
                <Button
                  onClick={() =>
                    setFilter({ ...filter, ...{ from: item.value } })
                  }
                >
                  {item.label}
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Nome",
              dataIndex: "name",
            },
            {
              title: "Gerado por",
              key: "created_by",
              render: (text: any, record: Coupon) => (
                <Text>{record?.created_user?.name}</Text>
              ),
            },
            {
              title: "Desconto",
              key: "discount",
              render: (text: any, record: Coupon) => (
                <Text>
                  {record.discount}% de desconto em
                  {record.apply_on == "ALL_PRODUCT" && "todos os produtos"}
                  {record.apply_on == "DEPARTMENT" &&
                    "todos os produtos do departamento"}
                  {record.apply_on == "ABOVE" &&
                    `peças acima de ${record.value}`}
                  {record.apply_on == "SHOPPING_ABOVE" &&
                    `compras acima de ${record.value}`}
                  {record.apply_on == "DISCOUNT_ON_THIRD_PRODUCT" &&
                    "desconto na terceira peça"}
                  {record.apply_on == "SPECIFIC_PRODUCT" &&
                    "produto especifico"}
                  {record.apply_on == "PROGRESSIVE_DISCOUNT" &&
                    "desconto progressivo"}
                </Text>
              ),
            },
            {
              title: "Tipo",
              key: "type",
              render: (text: any, record: Coupon) => (
                <Text>
                  {record?.type == "FREE_DELIVERY" && "Frete"}
                  {record?.type == "PERCENT" && "%"}
                </Text>
              ),
            },
            {
              title: "Código",
              dataIndex: "code",
            },
            {
              title: "Usos",
              dataIndex: "used",
            },
            {
              title: "Status",
              key: "status",
              render: (text: any, record: Coupon) => (
                <Tag
                  color={record.expired ? "#00A838" : "#939393"}
                  key={record.id}
                >
                  {record.expired ? "Ativo" : "Encerrado"}
                </Tag>
              ),
            },
            {
              title: "",
              render: (text: any, record: Coupon) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={couponsList?.data}
          size={"middle"}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: couponsList?.per_page,
            current: couponsList?.current_page,
            total: couponsList?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default CouponPage;
