import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Button,
  Drawer,
  Divider,
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import Packing from "../../../../classes/Packing";
import { Paginate } from "../../../../classes/Paginate";
import APP_ROUTE from "../../../../routes/routes";
import PackingApi from "../../../../services/PackingApi";
import FormPacking from "./form";

const { Title, Text } = Typography;

const PagePacking = () => {
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [list, setList] = useState<Paginate<Packing>>();
  const [currentPacking, setCurrentPacking] = useState<Packing>();

  const [loadingRemove, setLoadingRemove] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    onLoad();
  }, [filters]);

  function onLoad() {
    setLoading(true);
    PackingApi.list(filters)
      .then((resp) => {
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function editItem(item: Packing) {
    setIsEdit(true);
    setShowForm(true);
    setCurrentPacking(item);
  }

  function removeItem(item: Packing) {
    PackingApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
  }

  const OptionsMenu = (record: Packing) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => editItem(record)}>Editar</Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Drawer
        title={`${isEdit ? "Atualizar caixa" : "Criar caixa"}`}
        open={showForm}
        onClose={() => setShowForm(false)}
        closable={true}
        destroyOnClose={true}
        size={"large"}
      >
        <FormPacking
          isEdit={isEdit}
          onUpdate={onLoad}
          onClose={() => setShowForm(false)}
          packing={currentPacking}
        />
      </Drawer>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Caixas de entrega</Breadcrumb.Item>
      </Breadcrumb>
      <Row justify={"space-between"} align="middle">
        <Col>
          <Title level={2}>Caixas de entrega</Title>
        </Col>
        <Col>
          <Button
            type={"default"}
            onClick={(e) => [
              setIsEdit(false),
              setShowForm(true),
              setCurrentPacking(undefined),
            ]}
          >
            Criar caixa
          </Button>
        </Col>
      </Row>
      <Divider />
      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Nome",
              dataIndex: "name",
            },
            {
              title: "Seguro",
              key: "value",
              render: (text: any, record: Packing) => (
                <Text>R$ {record.secure_value}</Text>
              ),
            },
            {
              title: "Itens",
              dataIndex: "size_itens",
            },
            {
              title: "Peso",
              key: "weight",
              render: (text: any, record: Packing) => (
                <Text>{record.weight} KG</Text>
              ),
            },
            {
              title: "Largura",
              key: "width",
              render: (text: any, record: Packing) => (
                <Text>{record.width} cm</Text>
              ),
            },
            {
              title: "Altura",
              key: "height",
              render: (text: any, record: Packing) => (
                <Text>{record.height} cm</Text>
              ),
            },
            {
              title: "Comprimento",
              key: "length",
              render: (text: any, record: Packing) => (
                <Text>{record.length} cm</Text>
              ),
            },
            {
              title: "",
              key: "actions",
              render: (text: any, record: Packing) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={"middle"}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilters({ ...filters, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default PagePacking;
