import { Col, message, Modal, Upload, UploadFile, UploadProps } from 'antd';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { FC, useEffect, useState } from 'react';
import AuthApi from '../../services/AuthApi';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'rc-upload/lib/interface';
import { FileListItem } from '../../classes/Util';

export interface UploadFileOptions {
  list?: FileListItem[];
  path: string;
  hint: string;
  mult?: boolean;
  maxFilesCount?: number;
  urlUpload?: string;
  onFile: (val?: any) => void;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UploadMultFile: FC<UploadFileOptions> = ({
  list,
  path,
  hint,
  maxFilesCount,
  urlUpload,
  onFile,
}: UploadFileOptions) => {
  const [accessToken, setAccessToken] = useState('');

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    const { access_token } = AuthApi.getToken();

    setAccessToken(access_token);
  }, [AuthApi.getToken()]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const uploadButton = (
    <div>
      <p className="ant-upload-drag-icon">
        <InboxOutlined height={50} width={60} />
      </p>
      <p className="ant-upload-text">Clique ou arraste</p>
    </div>
  );

  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        onFile({
          file: file.response.file,
          type: file.type,
          original_name: file.name,
        });
      }
      return file;
    });

    setFileList(newFileList);
  };

  return (
    <>
      <>
        <ImgCrop rotate>
          <Upload
            name={'image'}
            data={{
              path: path,
            }}
            maxCount={!!maxFilesCount ? maxFilesCount : 4}
            action={`${process.env.REACT_APP_API}/api/upload-file`}
            method={'POST'}
            multiple={true}
            headers={{
              authorization: `Bearer ${accessToken}`,
            }}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            <>{fileList.length >= 8 ? null : uploadButton}</>
          </Upload>
        </ImgCrop>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img alt="example-xxx" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    </>
  );
};

export default UploadMultFile;
