import {
  PlusSquareOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Row,
  Button,
  Form,
  Typography,
  Breadcrumb,
  Col,
  Space,
  Input,
  Select,
  Drawer,
  Steps,
  Upload,
  InputNumber,
  Radio,
  Table,
  Skeleton,
  Divider,
  Checkbox,
  FormListFieldData,
  message,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState, useRef } from 'react';
import { RouteProps, useHistory, useParams, withRouter } from 'react-router';
import { ProductMeasure } from '../../../../../classes/Measure';
import { ProductAttribute } from '../../../../../classes/ProductAttribute';
import { BaseDict, FileListItem } from '../../../../../classes/Util';
import AppModal from '../../../../../components/AppModal';
import DepartmentForm from '../../../../../components/DepartmentForm';
import ProductAttributeForm from '../../../../../components/ProductAttributeForm';
import UploadMultFileCustom from '../../../../../components/UploadMultFileCustom';
import DepartmentApi from '../../../../../services/DepartmentApi';
import ProductAttributeApi from '../../../../../services/ProductAttributeApi';
import ProductMeasureApi from '../../../../../services/ProductMeasureApi';
import ProductMeasureField from '../../components/productmeasure';
import {
  CurrencyFormatter,
  CurrencyParser,
} from '../../../../../utils/currencyFormater';

import '../style.scss';
import UserApi from '../../../../../services/UserApi';
import CreatorForm from '../../../../../components/CreatorForm';
import AuthApi from '../../../../../services/AuthApi';
import ProductMeasureForm from '../../../../../components/MeasureForm';
import UploadMultFile from '../../../../../components/UploadMultFile';
import ProductApi from '../../../../../services/ProductApi';
import APP_ROUTE from '../../../../../routes/routes';
import UploadFile from '../../../../../components/Upload';

const { Title, Text } = Typography;

interface StepItems {
  title: string;
  open?: boolean;
  finished?: boolean;
  description: JSX.Element;
}

const ProductFormCreate = (props: RouteProps) => {
  const history = useHistory();
  let params = useParams<{ id?: string }>();
  const [formbasic] = Form.useForm();
  const [formdata] = Form.useForm();
  const [formmedia] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [newProductAttribute, setNewProductAttribute] = useState(false);
  const [newMeasure, setNewMeasure] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [departmentList, setDepartmentList] = useState<BaseDict[]>([]);
  const [fileList, setFileList] = useState<FileListItem[]>([]);
  const [productAttributes, setProductAttributes] =
    useState<ProductAttribute>();

  const [productTypeNew, setProductTypeNew] = useState('');

  const [creatorList, setCreatorList] = useState<BaseDict[]>([]);

  const [loadingMeasure, setLoadingMeasure] = useState(false);
  const [listMeasure, setListMeasure] = useState<ProductMeasure[]>([]);

  const [newDepartment, setNewDepartment] = useState(false);
  const [newCreator, setNewCreator] = useState(false);

  const [stepList, setStepList] = useState<StepItems[]>([]);
  const [productState, setProductState] = useState<any>();

  const [highlightImage, setHighLightImage] = useState<any>();

  const [currentMeasure, setCurrentMeasure] = useState<'NUMBER' | 'LETTER'>(
    'NUMBER'
  );

  useEffect(() => {
    setTimeout(() => {
      loadDepartment();
      loadMeasure();
      loadCreator();
      loadProductAttributes();
    }, 300);
  }, []);

  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);

      ProductApi.getItem(Number(params.id)).then((resp) => {
        var data = resp?.data;
        setProductState(resp?.data);

        formbasic.setFieldsValue(data);

        var formdataInfo = {
          description: data.description,
          information: data.information,
          price: data.price,
          cost: data.expense,
          margin: data.margin,
          currentMeasure: data.scale_size,
          color: data.color.id,
          category: data.category,
          style: data.style,
          type: data.type,
        };
        formdata.setFieldsValue(formdataInfo);
        setListMeasure(data.measure);
      });
    }
    formdata.setFieldsValue({ color: 14 });
  }, [params.id]);

  // useEffect(() => {
  //   // if (currentStep == 2) {
  //   //   console.log(productState);

  //   //   var formdataInfo = {
  //   //     description: productState.description,
  //   //     information: productState.information,
  //   //     price: productState.price,
  //   //     cost: productState.expense,
  //   //     margin: productState.margin,
  //   //     currentMeasure: productState.scale_size,
  //   //     color: productState.color.id,
  //   //     category: productState.category,
  //   //     style: productState.style,
  //   //     type: productState.type,
  //   //   };
  //   //   formdata.setFieldsValue(formdataInfo);
  //   //   setListMeasure(productState.measure);
  //   // }
  // }, [currentStep]);

  function loadDepartment() {
    DepartmentApi.listAll({ list: true }).then((resp) => {
      setDepartmentList(resp?.data);
    });
  }

  function loadCreator() {
    UserApi.list({ type: 'CREATOR' })
      .then((resp) => {
        setCreatorList(resp?.data);
      })
      .finally(() => {
        setNewCreator(false);
      });
  }

  function loadProductAttributes() {
    ProductAttributeApi.list().then((resp) => {
      setProductAttributes(resp?.data);
    });
  }

  function loadMeasure() {
    setLoadingMeasure(true);
    ProductMeasureApi.list()
      .then((resp) => {
        setListMeasure(resp?.data);
      })
      .finally(() => {
        setLoadingMeasure(false);
      });
  }

  const handerlBasicSubmit = (values: any) => {
    let currentIndex = currentStep + 1;

    const data = [...stepList];

    setCurrentStep(currentIndex);
  };

  const handlerSubmit = (values: any) => {
    values = {
      ...values,
      ...{
        highlightImage: highlightImage,
        name: formbasic.getFieldValue('name'),
        sku: formbasic.getFieldValue('sku'),
        creator: formbasic.getFieldValue('creator'),
        department: formbasic.getFieldValue('department'),
        collection: formbasic.getFieldValue('collection'),
        scale_size: currentMeasure,
        media: fileList,
      },
    };

    ProductApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        history.replace(APP_ROUTE.PRODUCT());
      })
      .catch((e) => {
        message.error(e.responseo.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function setNewValue(val?: any, key?: string) {
    if (!!key) {
      const currentAttributes = productAttributes;

      if (key == 'COLOR') {
        let colors = currentAttributes?.color;
        colors?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'CATEGORY') {
        let category = currentAttributes?.category;
        category?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'STYLE') {
        let style = currentAttributes?.style;
        style?.push({
          id: val.id,
          name: val.name,
        });
      }

      if (key == 'TYPE') {
        let type = currentAttributes?.type;
        type?.push({
          id: val.id,
          name: val.name,
        });
      }

      setProductAttributes(currentAttributes);
    }
  }

  const BasicInformation = () => {
    return (
      <>
        <Form
          form={formbasic}
          layout={'vertical'}
          onFinish={handerlBasicSubmit}
        >
          <Form.Item
            name={'name'}
            label={'Nome do produto'}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={'sku'}
            label={'Sku'}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row align={'middle'} gutter={[16, 16]}>
            <Col span={20}>
              <Form.Item
                name={'creator'}
                label={'Criador'}
                rules={[
                  {
                    required: true,
                    message: 'Selecione um criador',
                  },
                ]}
              >
                <Select
                  labelInValue
                  showSearch
                  placeholder={'Criador'}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={creatorList.map((item: BaseDict) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                icon={<PlusSquareOutlined />}
                onClick={() => setNewCreator(true)}
              ></Button>
            </Col>
          </Row>

          <Row align={'middle'} gutter={[16, 16]}>
            <Col span={20}>
              <Form.Item
                name={'department'}
                label={'Departamento'}
                rules={[
                  {
                    required: true,
                    message: 'Selecione um departamento',
                  },
                ]}
              >
                <Select
                  labelInValue
                  showSearch
                  placeholder={'Departamento'}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={departmentList.map((item: BaseDict) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                icon={<PlusSquareOutlined />}
                onClick={() => setNewDepartment(true)}
              ></Button>
            </Col>
          </Row>

          <Row align={'middle'} gutter={[16, 16]}>
            <Col span={20}>
              <Form.Item
                name={'collection'}
                label={'Coleção'}
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma coleção',
                  },
                ]}
              >
                <Select
                  labelInValue
                  showSearch
                  placeholder={'Coleção'}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={productAttributes?.collection.map(
                    (item: BaseDict) => ({
                      label: item.name,
                      value: item.id,
                    })
                  )}
                ></Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                icon={<PlusSquareOutlined />}
                onClick={() => [
                  setProductTypeNew('COLLECTION'),
                  setNewProductAttribute(true),
                ]}
              ></Button>
            </Col>
          </Row>

          <Row>
            <Form.Item label={'Imagem de destaque'}>
              {/* {highlightImage && (
                <Image
                width={300}
                src={`${process.env.REACT_APP_API}/storage/${currentDepartment?.banner}`}
              />
              )} */}
              <UploadFile
                path={'product'}
                hint={''}
                onFile={setHighLightImage}
              />
            </Form.Item>
          </Row>

          <Form.Item>
            <Button htmlType={'submit'} type={'dashed'} size={'large'}>
              PRÓXIMO
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  const handlerAddNewFile = (value: any) => {
    setFileList([...fileList, value]);
  };

  const ProductData = () => {
    return (
      <>
        <Form form={formdata} layout={'vertical'} onFinish={handlerSubmit}>
          <Row gutter={[16, 16]} justify={'space-between'}>
            <Col span={12}>
              <Form.Item name={'description'} label={'Descrição da peça'}>
                <TextArea />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'information'} label={'Informações da peça'}>
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify={'space-between'}>
            <Col span={8}>
              <Form.Item name={'price'} label={'Preço'}>
                <InputNumber
                  style={{
                    width: '100%',
                    marginRight: '1rem',
                  }}
                  formatter={CurrencyFormatter}
                  parser={CurrencyParser}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'cost'} label={'Custo'}>
                <InputNumber
                  style={{
                    width: '100%',
                    marginRight: '1rem',
                  }}
                  formatter={CurrencyFormatter}
                  parser={CurrencyParser}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'margin'} label={'Margem'}>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={'Tamanho'} name="scale_size">
            <Radio.Group
              defaultValue="NUMBER"
              buttonStyle="solid"
              onChange={(e) => {
                setCurrentMeasure(e.target.value);
              }}
            >
              <Radio.Button value="NUMBER">Números</Radio.Button>
              <Radio.Button value="LETTER">Letras</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Space direction="vertical">
            <div>
              <Title level={5}>Tabela de medidas</Title>
              <Text>Preencher somente os campos referentes a peça</Text>
            </div>
            <Form.List name={'measure'}>
              {(fields) => (
                <>
                  <Table
                    size="small"
                    className="measure-table"
                    pagination={false}
                    loading={loadingMeasure}
                    rowKey={(record) => record.id || 0}
                    columns={[
                      {
                        title: 'Tamanho',
                        key: 'size',
                        render: (
                          text: any,
                          record: ProductMeasure,
                          index: number
                        ) => (
                          <>
                            <Form.Item
                              name={[index, 'name']}
                              initialValue={record.label}
                            >
                              <Input value={record.label} readOnly disabled />
                            </Form.Item>
                          </>
                        ),
                      },
                      {
                        title: 'Preço',
                        key: 'value',
                        render: (
                          text: any,
                          record: ProductMeasure,
                          index: number
                        ) => (
                          <>
                            <Form.Item
                              name={[index, 'price']}
                              initialValue={record.value}
                            >
                              <InputNumber
                                style={{
                                  width: '100%',
                                  marginRight: '1rem',
                                }}
                                value={record.value}
                                formatter={CurrencyFormatter}
                                parser={CurrencyParser}
                              />
                            </Form.Item>
                          </>
                        ),
                      },
                      {
                        title: 'Estoque',
                        key: 'stock',
                        render: (
                          text: any,
                          record: ProductMeasure,
                          index: number
                        ) => (
                          <>
                            <Form.Item
                              name={[index, 'stock']}
                              initialValue={record.stock}
                            >
                              <Input type="number" value={record.stock} />
                            </Form.Item>
                          </>
                        ),
                      },
                      {
                        title: 'Peso',
                        key: 'weight',
                        render: (
                          text: any,
                          record: ProductMeasure,
                          index: number
                        ) => (
                          <>
                            <Form.Item
                              name={[index, 'weight']}
                              initialValue={record.weight}
                            >
                              <Input type="number" value={record.weight} />
                            </Form.Item>
                          </>
                        ),
                      },
                    ]}
                    dataSource={listMeasure.filter(
                      (item) => item.unity == currentMeasure
                    )}
                  />
                </>
              )}
            </Form.List>
            <Row>
              <Button
                type={'dashed'}
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewMeasure(true);
                }}
              >
                Nova medida
              </Button>
            </Row>
          </Space>
          <Divider />
          <Row>
            <Space direction="vertical">
              <Form.Item label={'Cor'} name="color">
                <Radio.Group
                  className="check-list"
                  options={productAttributes?.color.map((item: BaseDict) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Button
                type={'dashed'}
                icon={<PlusOutlined />}
                onClick={() => [
                  setNewProductAttribute(true),
                  setProductTypeNew('COLOR'),
                ]}
              >
                Adicionar cor
              </Button>
            </Space>
          </Row>
          <Divider />
          <Row>
            <Space direction="vertical">
              <Form.Item label={'Categoria'} name="category">
                <Checkbox.Group
                  className="check-list"
                  options={productAttributes?.category.map(
                    (item: BaseDict) => ({
                      label: item.name,
                      value: item.id,
                    })
                  )}
                />
              </Form.Item>
              <Button
                type={'dashed'}
                icon={<PlusOutlined />}
                onClick={() => [
                  setNewProductAttribute(true),
                  setProductTypeNew('CATEGORY'),
                ]}
              >
                Adicionar categoria
              </Button>
            </Space>
          </Row>
          <Divider />
          <Row>
            <Space direction="vertical">
              <Form.Item label={'Estilo'} name="style">
                <Checkbox.Group
                  className="check-list"
                  options={productAttributes?.style.map((item: BaseDict) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Button
                type={'dashed'}
                icon={<PlusOutlined />}
                onClick={() => [
                  setNewProductAttribute(true),
                  setProductTypeNew('STYLE'),
                ]}
              >
                Adicionar estilo
              </Button>
            </Space>
          </Row>
          <Divider />
          <Row>
            <Space direction="vertical">
              <Form.Item label={'Tipo'} name="type">
                <Checkbox.Group
                  className="check-list"
                  options={productAttributes?.type.map((item: BaseDict) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Button
                type={'dashed'}
                icon={<PlusOutlined />}
                onClick={() => [
                  setNewProductAttribute(true),
                  setProductTypeNew('TYPE'),
                ]}
              >
                Adicionar tipo
              </Button>
            </Space>
          </Row>
          <Divider />

          <Form.Item>
            <Button htmlType="submit" type="dashed" size="large">
              Concluir cadastro
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  function updateDepartment() {
    loadDepartment();
  }

  function changeFile(e: any) {
    console.log(e);
  }

  const steps: Array<{
    title: string;
    content: JSX.Element;
    showNext: boolean;
    isOpen?: boolean;
  }> = [
    {
      title: 'Dados principais',
      content: <BasicInformation />,
      showNext: false,
      isOpen: true,
    },
    {
      title: 'Imagens e vídeos',
      content: (
        <UploadMultFile
          path={'product'}
          hint={''}
          onFile={handlerAddNewFile}
          list={fileList}
          maxFilesCount={4}
        />
      ),
      showNext: true,
      isOpen: isEdit ? true : false,
    },
    {
      title: 'Dados do produto',
      content: <ProductData />,
      showNext: false,
      isOpen: isEdit ? true : false,
    },
  ];

  // function setNext(step: number) {
  //   let newstep = step + 1;
  //   setCurrentStep(newstep);
  //   let item = steps.findIndex((item, index) => {
  //     index == newstep;
  //   });
  //   steps[item].isOpen = true;
  // }

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      <Drawer
        title={'Novo criador'}
        open={newCreator}
        closable={true}
        size={'large'}
        destroyOnClose={true}
        onClose={() => {
          setNewCreator(false);
        }}
      >
        <CreatorForm onUpdate={loadCreator} />
      </Drawer>

      <Drawer
        title={'Nova medida'}
        open={newMeasure}
        onClose={() => setNewMeasure(false)}
        destroyOnClose={true}
        closable={true}
        size={'large'}
      >
        <ProductMeasureForm
          type={currentMeasure}
          onClose={() => setNewMeasure(false)}
          onUpdate={(e) => {
            const options = [...listMeasure];

            setListMeasure([...options, e]);
          }}
        />
      </Drawer>

      <Drawer
        title={'Novo departamento'}
        open={newDepartment}
        closable={true}
        size={'large'}
        destroyOnClose={true}
        onClose={() => setNewDepartment(false)}
      >
        <DepartmentForm
          onUpdate={updateDepartment}
          newItem={(e) => {
            console.log(e);
          }}
          onClose={() => setNewDepartment(false)}
        />
      </Drawer>

      <Drawer
        title={'Incluir novo'}
        open={newProductAttribute}
        closable={true}
        size={'large'}
        destroyOnClose={true}
        onClose={() => setNewProductAttribute(false)}
      >
        <ProductAttributeForm
          newItem={(e) => {
            setNewValue(e, productTypeNew);
          }}
          onClose={() => setNewProductAttribute(false)}
          type={productTypeNew}
        />
      </Drawer>

      <Row justify="end">
        <Button
          type="text"
          onClick={() => {
            history.goBack();
          }}
        >
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? 'Atualizar' : 'Cadastrar'} Produto
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>
            {isEdit ? 'Atualizar produto' : 'Cadastrar novo produto'}
          </Title>
        </Col>
        <Col>
          {currentStep == 2 && isEdit ? (
            <Button type="ghost" size="large" loading={loading}>
              {isEdit ? 'Atualizar' : 'Salvar'}
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: '100%' }}
        wrap
      >
        <Space
          size={[40, 40]}
          align="start"
          direction="vertical"
          style={{ width: '100%' }}
          className="form-steps"
        >
          {steps.map((item, index: number) => (
            <>
              <div className="item">
                <div className="head-item">
                  <span className="item-number">
                    {currentStep == index && !isEdit ? (
                      <CheckOutlined />
                    ) : (
                      <>{index + 1}</>
                    )}
                  </span>
                  <Title level={5}>{item.title}</Title>
                </div>
                {index == currentStep ? (
                  <div className="body-item">
                    {item.content}
                    {item.showNext && (
                      <Row>
                        <Button
                          onClick={() => {
                            setCurrentStep(currentStep + 1);
                          }}
                        >
                          Próximo
                        </Button>
                      </Row>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Divider />
            </>
          ))}
        </Space>
      </Space>
    </>
  );
};

export default withRouter(ProductFormCreate);
