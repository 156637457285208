import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  PlusSquareOutlined,
  StopOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { ItemCityAndState } from "../../classes/StateAndCity";
import { User, UserDict } from "../../classes/User";
import CommonApi from "../../services/CommonApi";
import UserApi from "../../services/UserApi";
import UtilApi from "../../services/UtilApi";
import { isValidCPF } from "../../utils/cpfValidation";
import { mask } from "../../utils/mask";
import PearsonApi from "../../services/PearsonApi";
import TypeApi from "../../services/TypeApi";
import { Type } from "../../classes/Type";

const { Title, Text } = Typography;

const { Option } = Select;

export interface UserForm {
  id: number;
  name: string;
}

interface ProviderFormOptions {
  onUpdate: () => void;
}

const CreatorForm: FC<ProviderFormOptions> = ({
  onUpdate,
}: ProviderFormOptions) => {
  const [form] = Form.useForm();

  const [addNew, setAddNew] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [currentPearson, setCurrentPearson] = useState<User>();

  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [states, setStates] = useState<ItemCityAndState[]>([]);
  const [city, setCities] = useState<ItemCityAndState[]>([]);
  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [maskedValue, setMaskedValue] = useState("");

  const [loadingUser, setLoadingUser] = useState(false);
  const [users, setUsers] = useState<UserDict[]>([]);

  const [currentUser, setCurrentUser] = useState<UserForm>();

  useEffect(() => {
    loadState();
  }, []);

  const handlerChangeState = (option: any) => {
    const { value } = option;

    loadCity(value);
  };

  function handleChangeMask(event: any) {
    const { value } = event.target;
    form.setFieldsValue({
      doc_number: mask(value),
    });
  }

  function loadState() {
    CommonApi.State().then((resp) => {
      setStates(resp?.data);
    });
  }

  function loadCity(value?: any) {
    setLoadingCity(true);
    CommonApi.City(value)
      .then((resp) => {
        setCities(resp?.data);
      })
      .finally(() => {
        setLoadingCity(false);
        setTimeout(() => {
          if (!!currentPearson?.city) {
            form.setFieldValue("city", currentPearson.city.id);
          }
        }, 300);
      });
  }

  const handlerPass = () => {
    let r = Math.random().toString(36).slice(2, 10);
    form.setFieldsValue({
      password: r.toString(),
    });
  };

  const handlerSubmit = (values: any) => {
    setLoading(true);

    values = { ...values, ...{ profile: "CREATOR" } };
    UserApi.addnew(values)
      .then((resp) => {
        message.success(resp.data.msg);
        setCurrentUser(resp?.data.user);
        onUpdate();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
        form.resetFields();
        setAddNew(false);
      });
  };

  function changeEmail(e: any) {
    if (form.getFieldValue("login")) {
      console.log("has");
    } else {
      console.log("not has");
    }
    console.log(!!form.getFieldValue("login"));
    if (form.getFieldValue("login") == "") {
      form.setFieldValue("login", e.target.value);
    }
  }

  return (
    <>
      <Form
        style={{ width: "100%" }}
        layout={"vertical"}
        form={form}
        onFinish={handlerSubmit}
      >
        <Space
          size={[15, 15]}
          align="start"
          direction="vertical"
          style={{ width: "100%" }}
        >
          <div style={{ width: "100%" }}>
            <Title level={5}>Dados do criador</Title>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                <Form.Item
                  label="Nome"
                  name={"name"}
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input placeholder="Nome" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={`CPF/CNPJ`}
                  name={"doc_number"}
                  rules={[
                    { required: true, message: "Campo obrigatório" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let valid = isValidCPF(value);

                        if (!valid) {
                          return Promise.reject(
                            new Error(
                              `O ${
                                value.length > 14 ? `CNPJ` : `CPF`
                              } é inválido`
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input onChange={handleChangeMask} value={maskedValue} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                <Form.Item name="birth_date" label="Data de nascimento:">
                  <Input type="date" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={"Email"}
                  name="email"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Input placeholder="E-mail" onChange={changeEmail} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Telefone" name="phone">
                  <Input placeholder="Telefone" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"zipcode"}
                  label={"CEP"}
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input type="text" placeholder={"_____-___"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                <Form.Item
                  label="Estado"
                  name="state"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Select
                    labelInValue
                    style={{ width: `100%` }}
                    onChange={handlerChangeState}
                    loading={loadingState}
                  >
                    {states.map((item: ItemCityAndState) => (
                      <Option key={item.id} value={item.initials}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cidade"
                  name="city"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Select
                    labelInValue
                    style={{ width: `100%` }}
                    loading={loadingState}
                  >
                    {city.map((item: ItemCityAndState) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  label="Endereço"
                  name="address"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <TextArea placeholder="Endereço" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Title level={5}>Dados do bancários</Title>

            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                <Form.Item
                  label="Banco"
                  name="bank_name"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Input placeholder="Banco" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Agência"
                  name="bank_agency"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Input placeholder="Agência" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                <Form.Item
                  label="Conta"
                  name="bank_account"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Input placeholder="Conta" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Pix"
                  name="bank_pix"
                  rules={[{ required: true, message: "Campo é obrigatório!" }]}
                >
                  <Input placeholder="Pix" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          {!isEdit && (
            <div>
              <Title level={5}>Dados de acesso</Title>
              <Row gutter={[16, 16]}>
                <Col>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Form.Item name="password">
                        <Input.Password
                          placeholder="Senha"
                          type="password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Button type="dashed" onClick={handlerPass}>
                        Gerar senha
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Space>
        <div style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                className="btn-orange"
                type="ghost"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CreatorForm;
