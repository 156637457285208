import BaseApi from "./BaseApi"

export default{
    list:(endpoint:string, filter?:any) =>{
        return BaseApi.get(`${endpoint}/production`, {params:filter});
    },
    finalize:(itemId:number) =>{
        return BaseApi.put(`admin/production/utils/finalize/${itemId}`);
    },
    getItem:(endpoint:string,itemId:number) => {
        return BaseApi.get(`${endpoint}/production/${itemId}`);
    },
    new:(endpoint:string, body:any) =>{
        return BaseApi.post(`/${endpoint}/production`,body);
    },
    setnewStatus:(id:number,body?:any) =>{
        return BaseApi.put(`/admin/production/${id}/change-status`, body);
    }
}