import { Button, Card, Divider, message, Modal } from 'antd';
import {
  InboxOutlined,
  EditOutlined,
  StarOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { FC, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import 'cropperjs/dist/cropper.css';
import { Cropper, ReactCropperElement } from 'react-cropper';

import './style.scss';
import { number } from 'yup';
import axios from 'axios';
import AuthApi from '../../services/AuthApi';

export interface UploadFileOptions {
  accept?: [];
  maxFile?: number;
  setHighlight?: boolean;
  path: string;
  hint: string;
  mult?: boolean;
  urlUpload?: string;
  onFile: (val?: any) => void;
}

const UploadFile: FC<UploadFileOptions> = ({
  maxFile,
  setHighlight,
  accept,
  path,
  hint,
  urlUpload,
  onFile,
}: UploadFileOptions) => {
  const [files, setFiles] = useState([]);

  const [showSave, setShowSave] = useState(false);

  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState<any>();
  const imageRef = useRef<ReactCropperElement>(null);
  const [image, setImage] = useState();

  const [showCrop, setShowCrop] = useState(false);
  const [fileIndex, setFileIndex] = useState(0);
  const [fileCrop, setFileCrop] = useState<any>();

  const [currentMaxFile, setCurrentMaxFile] = useState(1);

  useEffect(() => {
    if (!!maxFile && maxFile > 1) {
      setCurrentMaxFile(maxFile);
    }
  }, [maxFile]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: currentMaxFile,
    onDrop: (acceptedFiles: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        return reader.result;
      };
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      const listFiles = [...files];
      let currentFile = listFiles[fileIndex];

      Object.assign(currentFile, {
        preview: cropper.getCroppedCanvas().toDataURL(),
        data: cropper.getCroppedCanvas().toDataURL(),
      });

      setFiles(listFiles);
      setShowCrop(false);
      setFileCrop(undefined);
    }
  };

  function onCrop(file: any, fileindex: number) {
    setFileIndex(fileindex);
    setFileCrop(file);
    setShowCrop(true);
    setShowSave(false);
  }

  function sendFile(file: any) {
    var bodyFormData = new FormData();
    bodyFormData.append('image', file);
    bodyFormData.append('path', path);

    const { access_token } = AuthApi.getToken();

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}/api/upload-file`,
      data: bodyFormData,
      headers: {
        authorization: `Bearer ${access_token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(function (response) {
        var data = response.data;
        data = {
          ...data,
          ...{
            type: file.type,
            original_name: file.name,
          },
        };
        onFile(data);
        setShowSave(true);
        message.success('Arquivo enviado com sucesso!');
      })
      .catch(function (response) {
        console.log(response);
        message.error('Tente novamente!');
      });
  }

  function cancelCrop() {
    setShowCrop(false);
    setFileCrop(undefined);
    setShowSave(true);
  }

  function remove(index: number) {
    let currentList = [...files];
    currentList.splice(index, 1);
    setFiles(currentList);
  }

  useEffect(() => {
    if (!!fileCrop) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(fileCrop);
    }
  }, [fileCrop]);

  return (
    <>
      {!!files && files.length > 0 ? (
        <div className="preview-list">
          {files.map((item: any, index: number) => (
            <Card
              title={item.name}
              style={{ width: 300 }}
              cover={
                <img
                  alt="example"
                  src={item.preview}
                  onLoad={() => {
                    URL.revokeObjectURL(item.preview);
                  }}
                />
              }
              actions={[
                <Button
                  type="default"
                  icon={<EditOutlined key="edit" />}
                  onClick={() => {
                    onCrop(item, index);
                  }}
                ></Button>,
                <Button
                  type="default"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    remove(index);
                  }}
                ></Button>,
                <Button
                  type="default"
                  disabled={showSave}
                  icon={<SaveOutlined />}
                  onClick={() => {
                    sendFile(item);
                  }}
                ></Button>,
              ]}
            ></Card>
          ))}
        </div>
      ) : (
        <></>
      )}

      <Divider />
      {files.length < currentMaxFile && (
        <div {...getRootProps({ className: 'crop-image file-upload' })}>
          <input {...getInputProps()} />
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste para fazer o Upload
          </p>
          <p className="ant-upload-hint">{hint}</p>
        </div>
      )}

      <Modal
        title="Basic Modal"
        open={showCrop}
        onOk={getCropData}
        onCancel={cancelCrop}
      >
        <Cropper
          style={{ height: 400, width: '100%' }}
          guides={true}
          src={image}
          ref={imageRef}
          dragMode={'move'}
          zoomTo={0.5}
          initialAspectRatio={1}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={true}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </Modal>
    </>
  );
};

export default UploadFile;
