import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Upload,
  UploadProps,
  RadioChangeEvent,
  Radio,
  message,
  Image,
} from "antd";
import { useEffect, useState } from "react";
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { FileImageFilled, UploadOutlined } from "@ant-design/icons";
import { Department } from "../../../../../classes/Department";
import AppModal from "../../../../../components/AppModal";
import DepartmentApi from "../../../../../services/DepartmentApi";
import APP_ROUTE from "../../../../../routes/routes";
import UploadFile from "../../../../../components/Upload";

const { Title, Text } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;

export interface BannerFile {
  uid: string;
  name: string;
  size: number;
  type: string;
  base64: string;
}

export interface BannerFile {
  uid: string;
  name: string;
  size: number;
  type: string;
  base64: string;
}

const StoreDepartmentFormPage = (props: RouterProps) => {
  let params = useParams<{ id?: string }>();
  //const [fileList, setFileList] = useState<UploadFile>();
  const [banner, setBanner] = useState("");
  const [bannerFileList, setBannerFIleList] = useState<BannerFile>();

  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [currentDepartment, setCurrentDeparment] = useState<Department>();

  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);
      DepartmentApi.getItem(Number(params.id)).then((resp) => {
        setCurrentDeparment(resp?.data);
      });
    }
  }, [params.id]);

  useEffect(() => {
    form.setFieldsValue({
      name: currentDepartment?.name,
      description: currentDepartment?.description,
      highlight_type: currentDepartment?.highlight_type,
    });
  }, [currentDepartment]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  // const propsUpload: UploadProps = {
  //   multiple: false,
  //   onRemove: (file) => {
  //     setFileList(undefined);
  //     // newFileList.splice(index, 1);
  //     // setFileList(newFileList);
  //   },
  //   beforeUpload: async (file) => {
  //     setFileList(file);
  //     toBase64(file).then((resp) => {
  //       setBannerFIleList({
  //         uid: file.uid,
  //         name: file.name,
  //         size: Number(file.size),
  //         type: file.type.toString(),
  //         base64: String(resp),
  //       });
  //     });

  //     return false;
  //   },
  //   onDrop: (ev) => {
  //     console.log("here");
  //   },
  // };

  // const toBase64 = (file: Blob) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });

  const onChangeHighlight = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    // setValue4(value);
  };

  const handlerSubmit = (values: any) => {
    values = { ...values, ...{ banner: banner } };
    if (!isEdit) {
      DepartmentApi.addnew(values)
        .then((resp) => {
          message.success(resp?.data.msg);
          history.push(APP_ROUTE.STORE_DEPARTMENT());
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      DepartmentApi.update(values, Number(currentDepartment?.id))
        .then((resp) => {
          message.success(resp?.data.msg);
          history.push(APP_ROUTE.STORE_DEPARTMENT());
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          Departamento
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={2}>Cadastrar novo departamento</Title>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    {isEdit ? "Atualizar" : "Salvar"}
                  </Button>
                </Col>
              </Row>
            </div>

            <div style={{ width: "100%" }}>
              <Title level={5}>Dados principais</Title>

              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: "Campo é obrigatório!" }]}
              >
                <Input placeholder="Nome do departamento" />
              </Form.Item>

              <Form.Item
                label="Descrição"
                name="description"
                rules={[{ required: true, message: "Campo é obrigatório!" }]}
              >
                <TextArea placeholder="Nome do departamento" />
              </Form.Item>

              <Form.Item name="highlight_type">
                <Radio.Group
                  options={[
                    { label: "Destaque principal", value: "MAIN" },
                    {
                      label: "Destaque secundário",
                      value: "SECONDARY",
                    },
                  ]}
                  onChange={onChangeHighlight}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Title level={5}>Banner</Title>
              {isEdit && (
                <Image
                  width={300}
                  src={`${process.env.REACT_APP_API}/storage/${currentDepartment?.banner}`}
                />
              )}

              <Form.Item label={"Banner"} name={"banner"}>
                <UploadFile
                  path={"department"}
                  hint={"PNG, JPG"}
                  onFile={setBanner}
                />
              </Form.Item>
            </div>
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(StoreDepartmentFormPage);
