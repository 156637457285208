import BaseApi from "./BaseApi";

export default {
  list: (filters?: any) => {
    return BaseApi.get(`admin/packing`, { params: filters });
  },
  addnew: (body: any) => {
    return BaseApi.post(`admin/packing`, body);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`admin/packing/${id}`, body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`admin/packing/${id}`);
  },
};
