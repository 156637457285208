import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import APP_ROUTE from "../../../../../routes/routes";
import { useEffect, useState } from "react";
import { Paginate } from "../../../../../classes/Paginate";
import { Department } from "../../../../../classes/Department";
import DepartmentApi from "../../../../../services/DepartmentApi";

const { Title, Text } = Typography;

const StoreDeparmentPage = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({});

  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState<Paginate<Department>>();

  const [loadingRemove, setLoadingRemove] = useState(false);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function goDetail(item: Department) {
    history.push(APP_ROUTE.STORE_DEPARTMENT_VIEW(item.id));
  }

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    onLoad();
  }, [filter]);

  function enableItem(item: Department) {
    DepartmentApi.enable(Number(item?.id))
      .then((resp) => {
        message.success(resp?.data.msg);
        onLoad();
      })
      .catch((e) => {
        message.error(e.response?.data.msg);
      })
      .finally(() => {});
  }

  function removeItem(item: Department) {
    DepartmentApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
    console.log(item);
  }

  function onLoad() {
    setLoading(true);

    DepartmentApi.listAll(filter)
      .then((resp) => {
        setDepartment(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const OptionsMenu = (record: Department) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>

          <Menu.Item onClick={() => enableItem(record)}>
            {record.active ? "Inativar" : "Ativar"}
          </Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.STORE_DEPARTMENT_CREATE())}
          >
            CADASTRAR DEPARTAMENTO
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>Departamentos</Title>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Nome",
              dataIndex: "name",
            },
            {
              title: "Slug",
              dataIndex: "slug",
            },
            {
              title: "Produtos cadastrados",
              key: "product_registered",
              render: (text: any, record: Department) => (
                <Text>{record.products || 0}</Text>
              ),
            },
            {
              title: "Descrição",
              dataIndex: "description",
            },
            {
              title: "Status",
              key: "status",
              render: (text: any, record: Department) => (
                <Tag
                  color={record.active ? "#00A838" : "#939393"}
                  key={record.id}
                >
                  {record.active ? "Ativo" : "Inativo"}
                </Tag>
              ),
            },
            {
              title: "",
              key: "options",
              render: (text: any, record: Department) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={department?.data}
          size={"middle"}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: department?.per_page,
            current: department?.current_page,
            total: department?.total,
            onChange: (page: any, pageSize: any) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default StoreDeparmentPage;
