import {
  Breadcrumb,
  Col,
  Form,
  Row,
  Typography,
  Button,
  Space,
  Image,
  Input,
  InputNumber,
  message,
  Skeleton,
  List,
  Card,
  Popconfirm,
  Select,
  Radio,
  Modal,
  Divider,
  Alert,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import {
  PlusOutlined,
  MinusSquareOutlined,
  CalculatorOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';

import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import { PersonDict } from '../../../../classes/Pearson';
import AppModal from '../../../../components/AppModal';
import PearsonForm from '../../../../components/PearsonForm';
import UploadFile from '../../../../components/Upload';
import ModelingApi from '../../../../services/ModelingApi';
import MaterialForm from '../../../../components/MaterialForm';
import { MaterialDict } from '../../../../classes/Material';
import {
  CurrencyFormatter,
  CurrencyParser,
} from '../../../../utils/currencyFormater';
import UploadMultFile from '../../../../components/UploadMultFile';
import APP_ROUTE from '../../../../routes/routes';
import { BaseDict, FileListItem } from '../../../../classes/Util';
import ModelingMediaApi from '../../../../services/ModelingMediaApi';
import PearsonApi from '../../../../services/PearsonApi';
import { UserDict } from '../../../../classes/User';
import MaterialApi from '../../../../services/MaterialApi';
import Packing from '../../../../classes/Packing';
import PackingApi from '../../../../services/PackingApi';
import { Shipping } from '../../../../classes/Shipping';
import UtilApi from '../../../../services/UtilApi';
import DialogCharge from './charge';
import { Modeling } from '../../../../classes/Modeling';

const { Title, Text } = Typography;

export interface PearsonItem {
  value?: string;
  pearson: PersonDict;
  id: number;
}

export interface MaterialItem {
  value?: any;
  size: number;
  material: MaterialDict;
  id: number;
}

const ModelingFormPage = (props: RouterProps) => {
  const history = useHistory();

  let params = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const [formCharge] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState('');

  const [pearsonList, setPearsonList] = useState<PearsonItem[]>([]);
  const [materialList, setMaterialList] = useState<MaterialItem[]>([]);

  const [fileList, setFileList] = useState<FileListItem[]>([]);

  const [referenceList, setReferenceList] = useState<FileListItem[]>([]);
  const [pearsons, setPearsons] = useState<UserDict[]>([]);
  const [materialDict, setMaterialDict] = useState<MaterialDict[]>([]);
  const [listPacking, setListPacking] = useState<BaseDict[]>([]);
  const [currentCreator, setCurrentCreator] = useState<any>();

  const [allowGenPayment, setAllowGenPayment] = useState(false);
  const [generateCharge, setGenerateCharge] = useState(false);

  const [totalValue, setTotalValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [readOnly, setReadOnly] = useState(false);

  const [modeling, setModeling] = useState<Modeling>();

  //shipping
  const [chargeDialog, setChargeDialog] = useState(false);

  useEffect(() => {
    loadPearson();
    loadMaterial();
    loadingPacking();
  }, []);

  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);
      setIsLoading(true);
      ModelingApi.getItem(Number(params.id), 'admin')
        .then((resp) => {
          form.setFieldsValue(resp?.data);
          setModeling(resp?.data);
          setCurrentCreator(resp?.data.creator);
          setMaterialList(resp?.data.materials);
          setPearsonList(resp?.data.pearsons);
          setFileList(resp?.data.media?.cut || []);
          setReferenceList(resp?.data.media.reference);
          //reference
          setTotalValue(resp?.data.value);
          // resp?.data.situation == 'APPROVED'
          if (['APPROVED', 'ON_APPROVED'].includes(resp?.data.situation)) {
            setReadOnly(true);
          }

          if(!['CREATED','PAYED'].includes(resp?.data.situation)){
            setAllowGenPayment(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params.id]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  const handlerSubmit = (values: any) => {
    // setLoading(true);
    values = {
      ...values,
      ...{
        creator: currentCreator,
        media: fileList,
        generateCharge:generateCharge
      },
    };

    setChargeDialog(generateCharge);

    
    if(!generateCharge){
      if (isEdit) {
      ModelingApi.update(values, Number(params.id), 'admin')
        .then((resp) => {
          message.success('Modelagem atualizada com sucesso!');
          history.push(APP_ROUTE.MODELING());
        })
        .catch((e) => {
          message.error(e.response?.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ModelingApi.addnew(values, 'admin')
        .then((resp) => {
          message.success('Modelagem criada com sucesso!');
          history.push(APP_ROUTE.MODELING());
        })
        .catch((e) => {
          message.error(e.response?.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    }

    
  };

  const handlerAddNewFile = (value: any) => {
    setFileList([...fileList, value]);
  };

  function removeMedia(id: number) {
    ModelingMediaApi.delete(id)
      .then((resp) => {
        message.success(resp?.data.msg);
        ModelingMediaApi.list(Number(params.id)).then((resp) => {
          setFileList(resp?.data);
        });
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  }

  function loadPearson() {
    PearsonApi.search({ profile: 'TEAM' }).then((resp) => {
      setPearsons(resp?.data);
    });
  }

  function loadMaterial() {
    MaterialApi.list({ list: true }).then((resp) => {
      setMaterialDict(resp?.data);
    });
  }


 
  function loadingPacking(){
    PackingApi.list({dict:true}).then((resp) =>{
      setListPacking(resp?.data);
    })
  }

  //name, e.target.value, key
  function changeMaterialSize(field: any, value: any, key: any) {
    let materialFields = form.getFieldValue('material');
    let currentField = materialFields[key];
    materialFields[key].value = currentField.material.value * value;
    form.setFieldValue('material', materialFields);

    setTotal();
  }

  function setTotal() {
    let materialFields = form.getFieldValue('material');
    let pearsonFields = form.getFieldValue('pearsons');

    let total = 0;

    materialFields.forEach((item: any, index: number) => {
      if (!!materialFields[index].value) total += materialFields[index].value;
    });

    pearsonFields.forEach((item: any, index: number) => {
      if (!!pearsonFields[index].value) total += pearsonFields[index].value;
    });

    form.setFieldValue('total', total);
  }

  const goChargeGenerate=(values:any) =>{
    let formValues = form.getFieldsValue();
    formValues = {...formValues, ...{
      send_packing_id:values.packing,
      send_weight: values.weight,
      shipping_value:values.shipping.valor,
      shipping_type:values.shipping.tipo,
      creator: currentCreator,
      media: fileList,
      generateCharge:generateCharge
    }};

    ModelingApi.update(formValues, Number(params.id), 'admin')
    .then((resp) => {
      message.success(resp?.data.msg);
      history.push(APP_ROUTE.MODELING());
    })
    .catch((e) => {
      message.error(e.response?.data.msg);
    })
    .finally(() => {
      setLoading(false);
      setChargeDialog(false);
    });
  }


  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      
      {allowGenPayment && <DialogCharge 
        onCharge={goChargeGenerate}
        modelingId={Number(params.id)}
        onClose={() => setChargeDialog(false)}
        open={chargeDialog} 
        listPacking={listPacking} 
        creatorId={Number(currentCreator.id)}/>
      }


      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? 'Atualizar' : 'Cadastrar'} Modelagem
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>
            {isEdit ? 'Atualizar modelagem' : 'Cadastrar nova modelagem'}
          </Title>
        </Col>
        <Col></Col>
      </Row>
      {!isLoading && (
        <Space
          size={[40, 40]}
          direction="vertical"
          align="start"
          style={{ width: '100%' }}
          wrap
        >
          {modeling?.payment?.status == 'pending' && (
            <Alert
              message="Aguardando pagamento de peça piloto / modelagem inicial!"
              type="warning"
              closable
            />
          )}
          <Form
            style={{ width: '100%' }}
            layout={'vertical'}
            form={form}
            onFinish={handlerSubmit}
          >
            <Space
              size={[40, 40]}
              align="start"
              direction="vertical"
              style={{ width: '100%' }}
            >
              {/* <div style={{ width: '100%' }}>
                <Form.Item
                  name={'type'}
                  label={'Finalidade'}
                  rules={[{ required: true, message: 'Escolha uma opção!' }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="SALE">Venda</Radio.Button>
                    <Radio.Button value="MARKETING">Marketing</Radio.Button>
                    <Radio.Button value="PILOT">Piloto</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div> */}
              <div style={{ width: '100%' }}>
                <Form.Item
                  name="name"
                  label={'Nome'}
                  rules={[{ required: true, message: 'Nome obrigatório!' }]}
                >
                  <Input readOnly={readOnly} disabled={readOnly} />
                </Form.Item>

                <Form.Item
                  name="description"
                  label={'Descritivo detalhado'}
                  rules={[
                    { required: true, message: 'Descrição obrigatório!' },
                  ]}
                >
                  <TextArea readOnly={readOnly} disabled={readOnly} />
                </Form.Item>
                <PearsonForm
                  onSelect={(val) => {
                    setCurrentCreator(val);
                  }}
                  provider={currentCreator}
                  disabled={readOnly}
                  type={'CREATOR'}
                  label={'Criador'}
                />
              </div>

              <div style={{ width: '100%' }}>
                <Title level={4}>Arquivo de referência</Title>
                <small>(PNG, JPG ou PDF)</small>

                {isEdit && (
                  <>
                    <List
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 4,
                      }}
                      dataSource={referenceList}
                      renderItem={(item) => (
                        <List.Item>
                          <Card
                            title={item.original_name}
                            style={{ width: 300 }}
                          >
                            <Image
                              width={200}
                              src={`${process.env.REACT_APP_API}/storage/${item.file}`}
                            />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </div>

              <div style={{ width: '100%' }}>
                <Title level={4}>Arquivo de corte</Title>
                <small>(PNG, JPG ou PDF)</small>

                {isEdit && (
                  <>
                    <List
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 4,
                      }}
                      dataSource={fileList}
                      renderItem={(item) => (
                        <List.Item>
                          <Card
                            title={item.original_name}
                            style={{ width: 300 }}
                            actions={[
                              <>
                                {!readOnly && (
                                  <Popconfirm
                                    title="Remover arquivo？"
                                    okText="Sim"
                                    cancelText="Não"
                                    onConfirm={(e) => removeMedia(item.id)}
                                  >
                                    <Button
                                      icon={<DeleteOutlined />}
                                      type={'ghost'}
                                    ></Button>
                                  </Popconfirm>
                                )}
                              </>,
                            ]}
                          >
                            <Image
                              width={200}
                              src={`${process.env.REACT_APP_API}/storage/${item.file}`}
                            />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </>
                )}
                {!readOnly && (
                  <UploadMultFile
                    path={'material'}
                    hint={''}
                    onFile={handlerAddNewFile}
                    list={fileList}
                    maxFilesCount={3}
                  />
                )}
              </div>

              <div style={{ width: '100%' }}>
                <Title level={5}>Profissionais </Title>

                <Form.List name="pearsons">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            label="Profissional"
                            name={[name, 'pearson']}
                            rules={[
                              {
                                required: true,
                                message: 'Profissional é obrigatório',
                              },
                            ]}
                          >
                            <Select
                              labelInValue
                              showSearch
                              placeholder={'Profissional'}
                              defaultActiveFirstOption={false}
                              dropdownMatchSelectWidth={false}
                              showArrow={true}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '')
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                  )
                              }
                              options={pearsons.map((item: UserDict) => ({
                                label: item.name,
                                value: item.id,
                              }))}
                              disabled={readOnly}
                            ></Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'value']}
                            label={'Valor'}
                            rules={[
                              {
                                required: true,
                                message: 'Valor é obrigatório',
                              },
                            ]}
                          >
                            <InputNumber
                              style={{
                                width: '100%',
                                marginRight: '1rem',
                              }}
                              onChange={() => setTotal()}
                              formatter={CurrencyFormatter}
                              parser={CurrencyParser}
                              readOnly={readOnly}
                              disabled={readOnly}
                            />
                          </Form.Item>
                          {!readOnly && (
                            <Form.Item label={' '}>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Form.Item>
                          )}
                        </Space>
                      ))}
                      {!readOnly && (
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Adicionar profissional
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>
              </div>

              <div style={{ width: '100%' }}>
                <Title level={5}>Materiais </Title>

                <Form.List name="material">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            label="Material"
                            name={[name, 'material']}
                            rules={[
                              {
                                required: true,
                                message: 'Material é obrigatório',
                              },
                            ]}
                          >
                            <Select
                              labelInValue
                              showSearch
                              placeholder={'Material'}
                              defaultActiveFirstOption={false}
                              dropdownMatchSelectWidth={false}
                              showArrow={true}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '')
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                  )
                              }
                              options={materialDict.map(
                                (item: MaterialDict) => ({
                                  label: item.name,
                                  value: item.id,
                                })
                              )}
                              disabled={readOnly}
                            ></Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'size']}
                            label={'Quantidade'}
                            rules={[
                              {
                                required: true,
                                message: 'Quantidade é obrigatório',
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              onChange={(e) => {
                                changeMaterialSize(name, e.target.value, key);
                              }}
                              readOnly={readOnly}
                              disabled={readOnly}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, 'value']}
                            label={'Valor'}
                            rules={[
                              {
                                required: true,
                                message: 'Valor é obrigatório',
                              },
                            ]}
                          >
                            <InputNumber
                              style={{
                                width: '100%',
                                marginRight: '1rem',
                              }}
                              formatter={CurrencyFormatter}
                              parser={CurrencyParser}
                              disabled
                              readOnly
                            />
                          </Form.Item>
                          {!readOnly && (
                            <Form.Item label={' '}>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Form.Item>
                          )}
                        </Space>
                      ))}
                      {!readOnly && (
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Adicionar material
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>
              </div>

              <div>
                <Form.Item label={'Valor total'} name={'total'}>
                  <InputNumber
                    defaultValue={0}
                    style={{
                      width: '100%',
                      marginRight: '1rem',
                    }}
                    formatter={CurrencyFormatter}
                    parser={CurrencyParser}
                    disabled={readOnly}
                    readOnly={readOnly}
                  />
                </Form.Item>
              </div>
            </Space>

            <Form.Item>
              <Row gutter={[16,16]}>
                <Col>
              {!readOnly ? (
                <Button
                  type="ghost"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  {isEdit ? 'Atualizar' : 'Salvar'}
                </Button>
              ) : (
                <Button
                  type="ghost"
                  size="large"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => history.goBack()}
                  htmlType="button"
                >
                  Voltar
                </Button>
              )}
              </Col>
              <Col>
              {allowGenPayment && (
                <Tooltip placement="rightTop" title={'A cobrança gerada é referente a produção da peça piloto'}>
                  <Button type='primary' htmlType='submit' size="large" className='btn-orange' onClick={() => setGenerateCharge(true)}>Salvar e gerar cobrança</Button>
                </Tooltip>
              )}
              </Col>
              </Row>
            </Form.Item>
          </Form>
        </Space>
      )}
    </>
  );
};

export default withRouter(ModelingFormPage);
