import BaseApi from "./BaseApi";

export default {
  stats: (type?: any) => {
    return BaseApi.get(`/admin/pearson`, {
      params: {
        stats: true,
        type: type,
      },
    });
  },
  list: (filter?: any) => {
    return BaseApi.get(`/admin/pearson`, { params: filter });
  },
  getItem: (id: number) => {
    return BaseApi.get(`/admin/pearson/${id}`);
  },
  addnew: (body: any) => {
    return BaseApi.post(`/admin/pearson`, body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`/admin/pearson/${id}`);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`/admin/pearson/${id}`, body);
  },
  enable: (id: number) => {
    return BaseApi.put(`/admin/pearson/${id}/enable`);
  },
  search: (filter?: any) => {
    return BaseApi.get("/admin/pearson/utils/search-item", {
      params: filter,
    });
  },
};
