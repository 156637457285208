import { Route, Switch } from "react-router-dom";
import { PageRouteItem, PageRoutes } from "./pageroutes";

const FellaRoute = () => {
  return (
    <Switch>
      {PageRoutes.map((item: PageRouteItem, index: number) => (
        <Route key={index} path={item.route} component={item.component} exact />
      ))}
    </Switch>
  );
};

export default FellaRoute;
