import BaseApi from "./BaseApi";

export default {
  listAll: (filter?: any) => {
    return BaseApi.get(`/admin/coupon`, { params: filter });
  },
  addnew: (body: any) => {
    return BaseApi.post(`/admin/coupon`, body);
  },
  getItem: (id: number) => {
    return BaseApi.get(`/admin/coupon/${id}`);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`/admin/coupon/${id}`, body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`/admin/coupon/${id}`);
  },
  enable: (id: number) => {
    return BaseApi.put(`/admin/coupon/${id}/enable`);
  },
};
