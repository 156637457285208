import { Spin, Typography } from 'antd';
import './style.scss';

const Loading = () => {
  return (
    <>
      <div className="loading">
        <Typography.Title level={4}>Carregando...</Typography.Title>
        <Spin />
      </div>
    </>
  );
};

export default Loading;
