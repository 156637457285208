import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Typography,
  Image,
  Skeleton,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import APP_ROUTE from "../../../../routes/routes";
import { Material } from "../../../../classes/Material";
import MaterialApi from "../../../../services/MaterialApi";
import { Paginate } from "../../../../classes/Paginate";
import TypeApi from "../../../../services/TypeApi";
import { Type } from "../../../../classes/Type";
import { CountItem } from "../../../../classes/Util";

const { Title, Text } = Typography;

const PageMaterial = () => {
  const history = useHistory();
  const [loadingStats, setLoadingStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadintRemove] = useState(false);

  const [loadingTypes, setLoadingTypes] = useState(false);
  const [list, setList] = useState<Paginate<Material>>();
  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [countItens, setCountItens] = useState<CountItem[]>([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
  }, [filter]);

  useEffect(() => {
    loadStats();
    loadTypes();
  }, []);

  function goDetail(item: Material) {
    history.push(APP_ROUTE.MATERIAL_VIEW(item.id));
  }

  function removeItem(item: Material) {
    MaterialApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
    console.log(item);
  }

  const OptionsMenu = (record: Material) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    MaterialApi.list(filter)
      .then((resp) => {
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadTypes() {
    setLoadingTypes(true);
    TypeApi.loadTypesFilter("MATERIAL")
      .then((resp) => {
        setDataTypes(resp?.data);
      })
      .finally(() => {
        setLoadingTypes(false);
      });
  }

  function loadStats() {
    setLoadingStats(true);
    MaterialApi.list({ stats: true })
      .then((resp) => {
        setCountItens(resp?.data);
      })
      .finally(() => {
        setLoadingStats(false);
      });
  }

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <>
              {countItens.map((item: CountItem, index: number) => (
                <Col span={8}>
                  <Statistic
                    title={`${item.name}`}
                    prefix={"Qtd."}
                    value={item.count}
                    loading={loadingStats}
                  />
                </Col>
              ))}
            </>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.MATERIAL_CREATE())}
          >
            NOVO MATERIAL
          </Button>
        </Col>
      </Row>

      <Divider />
      <Row>
        <Col>
          <Title level={2}>Materiais</Title>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          {loadingTypes ? (
            <Skeleton />
          ) : (
            <>
              {!!dataTypes && (
                <Row gutter={[16, 16]}>
                  {dataTypes.map((item: Type, index: number) => (
                    <Col>
                      <Button
                        onClick={() =>
                          setFilter({ ...filter, ...{ type_id: item.id } })
                        }
                      >
                        {item.name}
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Imagem",
              key: "image",
              render: (text: any, record: Material) => (
                <>
                  <Image
                    width={50}
                    src={`${process.env.REACT_APP_API}/storage/${record.banner}`}
                  />
                </>
              ),
            },
            {
              title: "Fornecedor",
              key: "provider_n",
              render: (text: any, record: Material) => (
                <Text>{record.provider?.name}</Text>
              ),
            },
            {
              title: "Nome",
              dataIndex: "name",
            },
            {
              title: "Valor",
              dataIndex: "normal_value",
            },
            {
              title: "",
              key: "options",
              render: (text: any, record: Material) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={"middle"}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default PageMaterial;
