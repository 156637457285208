const APP_ROUTE: any = {
  HOME: () => '/',
  LOGIN: () => '/login',
  LOGIN_WITH_CODE: () => '/login-withcode',
  DASHBOARD: () => '/dashboard',

  PROFILE: () => `/profile`,

  //MENU ITENS ADMINISTRATOR
  PEARSON: (type?: string) => `/pearson/${type ? type : ':type'}`,
  PEARSON_CREATE: (type: string) => `/pearson/${type ? type : ':type'}/new`,
  PEARSON_VIEW: (type: string, id?: number) =>
    `/pearson/${type ? type : ':type'}/${id ? id : ':id'}/edit`,

  CREATOR: () => `/creator`,
  CREATOR_CREATE: () => `/creator/new`,
  CREATOR_VIEW: (id: any) => `/creator/${id ? id : `:id`}/edit`,

  INDICATOR: () => `/indicator`,
  INDICATOR_CREATE: () => `/indicator/new`,
  INDICATOR_VIEW: (id: any) => `/indicator/${id ? id : `:id`}/edit`,

  COLABORATOR: () => `/colaborator`,
  COLABORATOR_CREATE: () => `/colaborator/new`,
  COLABORATOR_VIEW: (id: any) => `/colaborator/${id ? id : `:id`}/edit`,

  USER: (type?: string) => `/user/${type ? type : ':type'}`,
  USER_CREATE: (type: string) => `/user/${type ? type : ':type'}/new`,
  USER_VIEW: (type: string, id?: number) =>
    `/user/${type ? type : ':type'}/${id ? id : ':id'}/edit`,

  SHOP_PAGE: (id?: any) => `/store/page/${id ? id : ':id'}`,
  SHOP_PAGE_CREATE: (id?: any) => `/store/page/${id ? id : ':id'}/new`,

  SHOP: (id?: any) => `/store/shop`,
  SHOP_CREATE: (id?: any) => `/store/shop/new`,
  SHOP_VIEW: (id?: any) => `/store/shop/${id ? id : ':id'}/edit`,

  STORE_DEPARTMENT: () => `/store/department`,
  STORE_DEPARTMENT_CREATE: () => `/store/department/new`,
  STORE_DEPARTMENT_VIEW: (id?: number) =>
    `/store/department/${id ? id : ':id'}/edit`,

  COUPON: () => `/coupon`,
  COUPON_CREATE: () => `/coupon/new`,
  COUPON_VIEW: (id?: number) => `/coupon/${id ? id : ':id'}/edit`,

  MATERIAL: () => `/material`,
  MATERIAL_CREATE: () => `/material/new`,
  MATERIAL_VIEW: (id?: number) => `/material/${id ? id : ':id'}/edit`,

  MODELING: () => `/modeling`,
  MODELING_CREATE: () => `/modeling/new`,
  MODELING_VIEW: (id?: number) => `/modeling/${id ? id : ':id'}/edit`,

  PRODUCT: () => `/product`,
  PRODUCT_VIEW_STOCK: () => `/product/stock`,
  PRODUCT_CREATE: (view?: any, id?: any) =>
    `/product/${view ? view : ':view'}/new/${id ? id : ':id'}`,
  PRODUCT_VIEW: (id?: number, view?: string) =>
    `/product/${id ? id : ':id'}/${view ? view : ':view'}`,
  PRODUCT_STOCK: (id?: number) => `/product/stock/${id ? id : ':id'}/view`,

  SOLICITATION:() => `/solicitation`,
  SOLICITATION_VIEW:(id?:any) => `/solicitation/${id ? id : ':id'}`,
 
  PURCHASE_WHOLESALE: () => `/requests/wholesale`,
  PURCHASE_WHOLESALE_CREATE: () => `/requests/wholesale/new`,
  PURCHASE_WHOLESALE_VIEW: (id?: number) =>
    `/requests/wholesale/${id ? id : ':id'}/edit`,

  PURCHASE_SITESALE: () => `/requests/site`,
  PURCHASE_SITESALE_VIEW: (id?: number) =>
    `/requests/site/${id ? id : ':id'}/view`,

  PURCHASE_EXCHANGE: () => `/requests/exchange`,
  PURCHASE_EXCHANGE_VIEW: (id?: number) =>
    `/requests/site/${id ? id : ':id'}/exchange`,

  SYSTEM_SETTINGS_CREDENTIALS: () => `/system/credentials`,
  SYSTEM_SETTINGS_PACKING: () => `/system/packing`,
  SYSTEM_SETTINGS_GENERAL: () => `/system/general`,

  //MENU ITENS CREATOR
  CREATOR_PRODUCT: () => `/product-creator`,
  CREATOR_PRODUCT_VIEW_STOCK: () => `/product-creator/stock`,
  CREATOR_PRODUCT_VIEW: (id?: number) =>
    `/product-creator/view/${id ? id : ':id'}`,
  CREATOR_PRODUCT_STOCK: (id?: number) =>
    `/product-creator/stock/${id ? id : ':id'}/view`,

  FEEDBACK_ADMIN: () => `/system/feedback`,

  CREATOR_PRODUCT_CREATE: () => `/creator/modeling`,
  CREATOR_PRODUCT_CREATE_VIEW: (id?: any) => `/creator/modeling/new`,
  CREATOR_PRODUCT_CREATE_EDIT: (id?: any) =>
    `/creator/modeling/${id ? id : ':id'}/edit`,

  CREATOR_CRIATION: () => `/creator/creations`,

  CREATOR_PRODUCT_SOLICITATION: () => `/product-creator/solicitation`,
  CREATOR_PRODUCT_SOLICITATION_VIEW: (id?: number) =>
    `/product-creator/solicitation/${id ? id : ':id'}/view`,
  FEEDBACK: () => '/system-feedback',

  CREATOR_REQUEST_PRODUCTION:(type:string, relation:number) => `/production/${type ? type : ':type'}/${relation? relation : ':relation'}`
};

export default APP_ROUTE;

