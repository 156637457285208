import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message, Radio,
  RadioChangeEvent, Row, Select, Skeleton, Space,
  Spin,
  Typography
} from "antd";

import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MaskField } from "react-mask-field";
import {
  RouteProps,
  RouterProps,
  useHistory,
  useParams,
  withRouter
} from "react-router-dom";
import { Pearson } from "../../../../classes/Pearson";
import { ItemCityAndState } from "../../../../classes/StateAndCity";
import { Type } from "../../../../classes/Type";
import { User } from "../../../../classes/User";
import AppModal from "../../../../components/AppModal";
import CommonApi from "../../../../services/CommonApi";
import PearsonApi from "../../../../services/PearsonApi";
import TypeApi from "../../../../services/TypeApi";
import UserApi from "../../../../services/UserApi";
import UtilApi from "../../../../services/UtilApi";
import { isValidCPF } from "../../../../utils/cpfValidation";
import { mask } from "../../../../utils/mask";

const { Title, Text } = Typography;

const { Option } = Select;

export interface ProviderFormPageParams {
  type?: string;
  id?: any;
}

const ColaboratorFormPage = (props: RouteProps) => {
  let params = useParams<ProviderFormPageParams>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  const [currentPearson, setCurrentPearson] = useState<User>();

  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [states, setStates] = useState<ItemCityAndState[]>([]);
  const [city, setCities] = useState<ItemCityAndState[]>([]);

  const [maskedValue, setMaskedValue] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!!params.id) {

      loadState();
      setIsEdit(true);
      UserApi.getItem(Number(params.id)).then((resp) => {
        setCurrentPearson(resp?.data);
      });
    }
  }, [params.id]);

  useEffect(() => {
    form.setFieldsValue(currentPearson);
    form.setFieldValue(
      "birth_date",
      moment(currentPearson?.birth_date).format("YYYY-MM-DD")
    );

    if (!!currentPearson?.state) {
      form.setFieldValue("state", currentPearson.state.initials);
      if (!!currentPearson.state.initials) {
        loadCity(currentPearson.state.initials);
      }
    }
  }, [currentPearson]);

  useEffect(() => {
    loadState();
  }, []);

  const handlerChangeState = (option: any) => {
    const { value } = option;

    loadCity(value);
  };

  function handleChangeMask(event: any) {
    const { value } = event.target;
    console.log(mask(value));
    form.setFieldsValue({
      doc_number: mask(value),
    });
  }

  function loadState() {
    CommonApi.State().then((resp) => {
      setStates(resp?.data);
    });
  }

  function loadCity(value?: any) {
    setLoadingCity(true);
    CommonApi.City(value)
      .then((resp) => {
        setCities(resp?.data);
      })
      .finally(() => {
        setLoadingCity(false);
        setTimeout(() => {
          if (!!currentPearson?.city) {
            form.setFieldValue("city", currentPearson.city.id);
          }
        }, 300);
      });
  }

  function getZipCode() {
    let zipcode = form.getFieldValue("zipcode");

    let clearZipCode = zipcode.replace(/[^a-zA-Z0-9]/g, "");

    UtilApi.ZipCode(clearZipCode)
      .then((resp) => {
        let response = resp?.data;

        form.setFieldsValue({
          address: response.logradouro ? response.logradouro : "",
          complement: response.complemento ? response.complemento : "",
          city: response.localidade ? response.localidade : "",
          state: response.uf ? response.uf : "",
          number: "",
        });
      })
      .catch((e) => {
        message.error("Cep não encontrado!");
      });
  }

  const handlerPass = () => {
    let r = Math.random().toString(36).slice(2, 10);
    form.setFieldsValue({
      password: r.toString(),
    });
  };

  const handlerSubmit = (values: any) => {
    console.log(values);
    setLoading(true);

    values = { ...values, ...{ profile: "COLABORATOR" } };
    if (isEdit) {
      UserApi.update(values, Number(currentPearson?.id))
        .then((resp) => {
          message.success(resp.data.msg);
          setTimeout(() => {
            history.goBack();
          }, 300);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      UserApi.addnew(values)
        .then((resp) => {
          message.success(resp.data.msg);
          setTimeout(() => {
            history.goBack();
          }, 300);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function changeEmail(e: any) {
    console.log(e.target.value);
    if (form.getFieldValue("login")) {
      console.log("has");
    } else {
      console.log("not has");
    }
    console.log(!!form.getFieldValue("login"));
    if (form.getFieldValue("login") == "") {
      form.setFieldValue("login", e.target.value);
    }
  }

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
    // console.log(form.getFieldsError());
    // history.goBack()
  }

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? "Atualizar" : "Cadastrar"} Colaborador
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Form.Item>
            <input type="hidden" name="profile" value="CREATOR" />
          </Form.Item>
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={2}>Cadastrar Colaborador</Title>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    {isEdit ? "Atualizar" : "Salvar"}
                  </Button>
                </Col>
              </Row>
            </div>
            <div style={{ width: "100%" }}>
              <Title level={5}>Dados do criador</Title>
              <Row gutter={[16, 16]} justify="space-between">
                <Col span={8}>
                  <Form.Item
                    label="Nome"
                    name={"name"}
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input placeholder="Nome" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={`CPF/CNPJ`}
                    name={"doc_number"}
                    rules={[
                      { required: true, message: "Campo obrigatório" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let valid = isValidCPF(value);

                          if (!valid) {
                            return Promise.reject(
                              new Error(
                                `O ${value.length > 14 ? `CNPJ` : `CPF`
                                } é inválido`
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input onChange={handleChangeMask} value={maskedValue} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="birth_date" label="Data de nascimento:">
                    <Input type="date" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="E-mail" onChange={changeEmail} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Telefone" name="phone">
                    <Input placeholder="Telefone" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name={"zipcode"}
                    label={"CEP"}
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input type="text" placeholder={"_____-___"} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Estado"
                    name="state"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Select
                      labelInValue
                      style={{ width: `100%` }}
                      onChange={handlerChangeState}
                      loading={loadingState}
                    >
                      {states.map((item: ItemCityAndState) => (
                        <Option key={item.id} value={item.initials}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Cidade"
                    name="city"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Select
                      labelInValue
                      style={{ width: `100%` }}
                      loading={loadingState}
                    >
                      {city.map((item: ItemCityAndState) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Endereço"
                    name="address"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <TextArea placeholder="Endereço" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div>
              <Title level={5}>Dados do bancários</Title>

              <Row gutter={[16, 16]} justify="space-between">
                <Col span={6}>
                  <Form.Item
                    label="Banco"
                    name="bank_name"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Banco" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Agência"
                    name="bank_agency"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Agência" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Conta"
                    name="bank_account"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Conta" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Pix"
                    name="bank_pix"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Pix" />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            {!isEdit && (
              <div>
                <Title level={5}>Dados de acesso</Title>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Form.Item name="password">
                          <Input.Password
                            placeholder="Senha"
                            type="password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col>
                        <Button type="dashed" onClick={handlerPass}>
                          Gerar senha
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(ColaboratorFormPage);
