import BaseApi from "./BaseApi";

export default {
  list: (filter?: any) => {
    return BaseApi.get(`/admin/sale`, { params: filter });
  },
  newItem: (body: any) => {
    return BaseApi.post(`/admin/sale`, body);
  },
  getItem: (id: number) => {
    return BaseApi.get(`/admin/sale/${id}`);
  },
};
