import { message } from "antd";
import axios from "axios";
import AuthApi from "./AuthApi";
import { useHistory } from "react-router-dom";
import APP_ROUTE from "../routes/routes";

const BaseApi = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api`,
  responseType: "json",
  maxContentLength: 2000 * 100,
  timeout: 1000 * 60,
});
BaseApi.interceptors.request.use(async (config) => {
  const { access_token } = AuthApi.getToken();

  if (access_token) {
    config.headers!.Authorization = `Bearer ${access_token}`;
  }

  return config;
});

BaseApi.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 403) {
      // message.error("Usuário não tem permissão");
      AuthApi.logout();
      //history.replace(APP_ROUTE.LOGIN());
    }
    return Promise.reject(error);
    // const history = useHistory();
  }
);

export default BaseApi;
