import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Skeleton,
  Select,
  Space,
  Spin,
  Typography,
  Radio,
  RadioChangeEvent,
} from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  RouteProps,
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { Type } from "../../../../classes/Type";
import TypeApi from "../../../../services/TypeApi";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { isValidCPF } from "../../../../utils/cpfValidation";
import { mask } from "../../../../utils/mask";
import { MaskField } from "react-mask-field";
import UtilApi from "../../../../services/UtilApi";
import PearsonApi from "../../../../services/PearsonApi";
import CommonApi from "../../../../services/CommonApi";
import { ItemCityAndState } from "../../../../classes/StateAndCity";
import { Pearson } from "../../../../classes/Pearson";

const { Title, Text } = Typography;

const { Option } = Select;

export interface ProviderFormPageParams {
  type?: string;
  id?: any;
}

const UserFormPage = (props: RouteProps) => {
  let params = useParams<ProviderFormPageParams>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [formPearson] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  const [currentPearson, setCurrentPearson] = useState<Pearson>();

  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [states, setStates] = useState<ItemCityAndState[]>([]);
  const [city, setCities] = useState<ItemCityAndState[]>([]);

  const [loadingTypes, setLoadingTypes] = useState(false);
  const [addNewType, setAddNewType] = useState(false);
  const [sendNewType, setSendNewType] = useState(false);

  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [currentType, setCurrentType] = useState(0);

  const [maskedValue, setMaskedValue] = useState("");

  useEffect(() => {
    if (!!params.id) {
      loadState();
      setIsEdit(true);
      PearsonApi.getItem(Number(params.id)).then((resp) => {
        setCurrentPearson(resp?.data);
      });
    }
  }, [params.id]);

  useEffect(() => {
    formPearson.setFieldsValue(currentPearson);

    if (!!currentPearson?.state) {
      formPearson.setFieldValue("state", currentPearson.state.initials);
      if (!!currentPearson.state.initials) {
        loadCity(currentPearson.state.initials);
      }
    }
  }, [currentPearson]);

  useEffect(() => {
    loadTypes();
    loadState();
  }, []);

  const handlerSubmitType = (values: any) => {
    setSendNewType(false);
    values = {
      ...values,
      ...{
        type: params.type?.toUpperCase(),
        active: true,
      },
    };

    TypeApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        form.resetFields();
        setSendNewType(false);
        setAddNewType(false);
        loadTypes();
      });
  };

  const handlerChangeState = (option: any) => {
    const { value } = option;

    loadCity(value);
  };

  const handlerType = (e: RadioChangeEvent) => {
    setCurrentType(e.target.value);
  };

  function handleChangeMask(event: any) {
    const { value } = event.target;
    console.log(mask(value));
    formPearson.setFieldsValue({
      doc_number: mask(value),
    });
  }

  function loadTypes() {
    setLoadingTypes(true);
    TypeApi.loadTypesFilter(String(params.type)?.toUpperCase())
      .then((resp) => {
        setDataTypes(resp?.data);
      })
      .finally(() => {
        setLoadingTypes(false);
      });
  }

  function loadState() {
    CommonApi.State().then((resp) => {
      setStates(resp?.data);
    });
  }

  function loadCity(value?: any) {
    setLoadingCity(true);
    CommonApi.City(value)
      .then((resp) => {
        setCities(resp?.data);
      })
      .finally(() => {
        setLoadingCity(false);
        setTimeout(() => {
          if (!!currentPearson?.city) {
            formPearson.setFieldValue("city", currentPearson.city.id);
          }
        }, 300);
      });
  }

  function getZipCode() {
    let zipcode = formPearson.getFieldValue("zipcode");

    let clearZipCode = zipcode.replace(/[^a-zA-Z0-9]/g, "");

    UtilApi.ZipCode(clearZipCode)
      .then((resp) => {
        let response = resp?.data;

        formPearson.setFieldsValue({
          address: response.logradouro ? response.logradouro : "",
          complement: response.complemento ? response.complemento : "",
          city: response.localidade ? response.localidade : "",
          state: response.uf ? response.uf : "",
          number: "",
        });
      })
      .catch((e) => {
        message.error("Cep não encontrado!");
      });
  }

  const handlerSubmit = async (values: any) => {
    if (!currentType && params.type != "client") {
      message.error("Selecione um Tipo");
    } else {
      values = {
        ...values,
        ...{
          type: String(params.type)?.toUpperCase(),
          type_id: currentType,
        },
      };
      setLoading(true);
      try {
        let response = isEdit
          ? await PearsonApi.update(values, Number(currentPearson?.id))
          : await PearsonApi.addnew(values);

        message.success(response.data.msg);
        setLoading(false);

        setTimeout(() => {
          history.goBack();
        }, 300);
      } catch (e) {
        console.log(e);
        // message.error(e.);
      }
    }
  };

  return (
    <>
      <Row justify="end">
        <Button type="text" onClick={() => history.goBack()}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {params.type == "team" && "Equipe"}
          {params.type == "provider" && "Fornecedores"}
          {params.type == "client" && "Cliente"}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Detalhe {params.type == "team" && "Equipe"}
          {params.type == "provider" && "Fornecedores"}
          {params.type == "client" && "Cliente"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Title level={2}>
          Cadastrar {params.type == "team" && "Equipe"}
          {params.type == "provider" && "Fornecedores"}
          {params.type == "client" && "Cliente"}
        </Title>

        {loadingTypes ? (
          <Skeleton />
        ) : (
          <Row gutter={[16, 16]}>
            <Col>
              <Radio.Group
                onChange={handlerType}
                defaultValue={
                  !!currentPearson?.type_id ? currentPearson?.type_id : ""
                }
              >
                {dataTypes.map((item: Type, index: number) => (
                  <Radio.Button value={item.id}>{item.name}</Radio.Button>
                ))}
              </Radio.Group>
            </Col>

            <Col>
              {!addNewType ? (
                <Button
                  icon={<PlusSquareOutlined />}
                  onClick={() => setAddNewType(true)}
                ></Button>
              ) : (
                <Form form={form} onFinish={handlerSubmitType} layout="inline">
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Tipo obrigatório!" }]}
                  >
                    <Input placeholder="Tipo" allowClear />
                  </Form.Item>
                  {sendNewType && <Spin></Spin>}
                </Form>
              )}
            </Col>
          </Row>
        )}

        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={formPearson}
          onFinish={handlerSubmit}
        >
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <Title level={5}>
                Dados do {params.type == "team" && "Equipe"}
                {params.type == "provider" && "Fornecedores"}
                {params.type == "client" && "Cliente"}
              </Title>
              <Row gutter={[16, 16]} justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Nome"
                    name={"name"}
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input placeholder="Nome" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={`CPF/CNPJ`}
                    name={"doc_number"}
                    rules={[
                      { required: true, message: "Campo obrigatório" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let valid = isValidCPF(value);

                          if (!valid) {
                            return Promise.reject(
                              new Error(
                                `O ${
                                  value.length > 14 ? `CNPJ` : `CPF`
                                } é inválido`
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input onChange={handleChangeMask} value={maskedValue} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name={"zipcode"}
                    label={
                      <>
                        <Row gutter={[16, 16]} justify="start">
                          <Col>
                            <Text>CEP</Text>
                          </Col>
                          <Col>
                            <a onClick={() => getZipCode()}>(Buscar cep)</a>
                          </Col>
                        </Row>
                      </>
                    }
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input type="text" placeholder={"_____-___"} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Estado"
                    name="state"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Select
                      labelInValue
                      style={{ width: `100%` }}
                      onChange={handlerChangeState}
                      loading={loadingState}
                    >
                      {states.map((item: ItemCityAndState) => (
                        <Option key={item.id} value={item.initials}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Cidade"
                    name="city"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Select
                      labelInValue
                      style={{ width: `100%` }}
                      loading={loadingState}
                    >
                      {city.map((item: ItemCityAndState) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Endereço"
                    name="address"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <TextArea placeholder="Endereço" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]} justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Telefone" name="phone">
                    <Input placeholder="Telefone" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div>
              <Title level={5}>Dados do bancários</Title>

              <Row gutter={[16, 16]} justify="space-between">
                <Col span={6}>
                  <Form.Item
                    label="Banco"
                    name="bank_name"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Banco" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Agência"
                    name="bank_agency"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Agência" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Conta"
                    name="bank_account"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Conta" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Pix"
                    name="bank_pix"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Input placeholder="Pix" />
                  </Form.Item>
                </Col>
              </Row>

              <Button
                className="submit-form"
                type="ghost"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                {isEdit ? "Atualizar" : "Salvar"}
              </Button>
            </div>
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(UserFormPage);
