import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Typography,
  Image,
} from 'antd';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';

import moment from 'moment';
import { Product } from '../../../../classes/Product';
import ProductApi from '../../../../services/ProductApi';
import APP_ROUTE from '../../../../routes/routes';
const { Text, Title } = Typography;

const CreatorProductPage = () => {
  const history = useHistory();
  const params = useParams<{ view: string }>();
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [list, setList] = useState<any>();
  const [filter, setFilter] = useState({});
  const [showVariation, setShowvariation] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(0);

  useEffect(() => {
    onLoad();
  }, [filter]);

  useEffect(() => {
    onLoad();
    if (!!params) {
      if (!!params.view && params.view == 'stock') {
        setFilter({ ...filter, ...{ stock: true } });
        setShowStock(true);
      }
    }
    // console.log(params);
  }, []);

  function goDetail(item: Product) {}

  function removeItem(item: Product) {
    ProductApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
  }

  const handlerFilter = (values: Product) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    ProductApi.list(filter, 'creator')
      .then((resp) => {
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createNewVariation(product_id: number) {
    ProductApi.newvariation({
      id: product_id,
    })
      .then((resp) => {
        message.success(resp?.data.msg);
        history.replace(APP_ROUTE.PRODUCT_VIEW(resp?.data.id, 'edit'));
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  }

  const OptionsMenu = (record: Product) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item
          // onClick={() => {
          //   history.push(APP_ROUTE.PRODUCT_VIEW(record.id));
          // }}
          >
            Ver
          </Menu.Item>
          {record.variations > 0 && (
            <Menu.Item
              onClick={() => [
                setFilter({
                  ...filter,
                  ...{ parent_id: record.id, variation: true, page: 0 },
                }),
                setShowvariation(true),
                setCurrentParentId(Number(record.id)),
              ]}
            >
              Variações ({record.variations})
            </Menu.Item>
          )}
          <Menu.Item
            onClick={() => {
              history.push(APP_ROUTE.CREATOR_PRODUCT_STOCK(record.id));
            }}
          >
            Estoque
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Total disponível para saque`}
                prefix={'R$'}
                value={1736295}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Vendas em andamento`}
                prefix={'R$'}
                value={955}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Valor recebido`}
                prefix={'R$'}
                value={955}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Space direction="vertical">
            <Button
              type="ghost"
              onClick={() => history.push(APP_ROUTE.PRODUCT_CREATE('basic'))}
            >
              Sacar valor disponível
            </Button>
            <Button
              type="ghost"
              onClick={() =>
                history.push(APP_ROUTE.CREATOR_PRODUCT_CREATE_VIEW())
              }
            >
              Criar nova peça
            </Button>
          </Space>
        </Col>
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col>
          <Title level={2}>Produtos</Title>
        </Col>
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {showVariation && (
        <Row>
          <Button
            icon={<LeftOutlined />}
            type="default"
            onClick={(e) => [
              setFilter({
                ...filter,
                ...{ page: 0, parent_id: 0, variation: false },
              }),
              setShowvariation(false),
            ]}
          >
            Ver original
          </Button>
        </Row>
      )}

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: 'Produto',
              key: 'name',
              render: (text: any, record: Product) => (
                <Row gutter={[16, 16]} align={'middle'}>
                  <Col>
                    <Image
                      width={60}
                      src={`${process.env.REACT_APP_API}/storage/${record.media?.file}`}
                    />
                  </Col>
                  <Col>
                    <Text>
                      {record.name} <br /> {record.sku}
                    </Text>
                  </Col>
                </Row>
              ),
            },
            {
              title: 'Cor',
              key: 'created_at',
              render: (text: any, record: Product) => (
                <>
                  <div className="wrapper-color">
                    <span
                      className="color-ball"
                      style={{ background: record.color?.value }}
                    ></span>
                    <Text>{record.color?.name}</Text>
                  </div>
                </>
              ),
            },
            {
              title: 'Preço',
              dataIndex: 'value',
              render: (text: any, record: Product) => (
                <Text>R$ {record.price}</Text>
              ),
            },
            {
              title: 'Estoque',
              dataIndex: 'stock',
              render: (text: any, record: Product) => (
                <>
                  {record?.stock_status == 'OUT' && 'Estoque zerado'}
                  {record?.stock_status == 'FULL' && 'Em estoque'}
                  {record?.stock_status == 'PARTIALLY' && 'Estoque parcial'}
                </>
              ),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              render: (text: any, record: Product) => (
                <>
                  {record?.stock_status == 'OUT' && 'Estoque zerado'}
                  {record?.stock_status == 'FULL' && 'Em estoque'}
                  {record?.stock_status == 'PARTIALLY' && 'Estoque parcial'}
                </>
              ),
            },
            {
              title: '',
              dataIndex: 'options',
              render: (text: any, record: Product) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={'middle'}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ['bottomCenter'],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default CreatorProductPage;
