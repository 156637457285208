import BaseApi from "./BaseApi";

export default {
  State: () => {
    return BaseApi.get(`/common/state`);
  },
  City: (state: any, key?:any) => {
    return BaseApi.get(`/common/city`, {
      params: {
        state: state,
        key:key
      },
    });
  },

  attributes:(type:string)=>{
    return BaseApi.get(`/common/attribute`, {params:{
      type:type
    }})
  },
  measures:() =>{
    return BaseApi.get(`/common/measure`);
  }
};
