import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
  Image,
} from "antd";
import { useEffect, useState } from "react";
import { PlusSquareOutlined } from "@ant-design/icons";
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import NumberFormat from "react-number-format";
import { Material } from "../../../../classes/Material";
import { MeasureType, Type } from "../../../../classes/Type";
import AppModal from "../../../../components/AppModal";
import TextArea from "antd/lib/input/TextArea";
import MeasureApi from "../../../../services/MeasureApi";
import MeasureTable from "../../../../components/Measure";
import ColorsSelect from "../../../../components/Colors";
import UploadFile from "../../../../components/Upload";
import PearsonForm from "../../../../components/PearsonForm";
import MaterialApi from "../../../../services/MaterialApi";
import APP_ROUTE from "../../../../routes/routes";
import TypeApi from "../../../../services/TypeApi";
import { PersonDict } from "../../../../classes/Pearson";

import {
  CurrencyFormatter,
  CurrencyParser,
} from "../../../../utils/currencyFormater";
import TypeForm from "../../../../components/TypeForm";

const { Title, Text } = Typography;

export interface FieldValues {
  attacked_value?: string;
  normal_value?: string;
  payed_value?: string;
}

const FormMaterial = (props: RouterProps) => {
  const history = useHistory();

  let params = useParams<{ id?: string }>();
  const [formMaterial] = Form.useForm();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fieldValues, setFieldValues] = useState<FieldValues>();
  const [type, setType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentColor, setCurrentColor] = useState<any>();

  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [addNewType, setAddNewType] = useState(false);
  const [sendNewType, setSendNewType] = useState(false);

  const [currentMaterial, setCurrentMaterial] = useState<Material>();

  const [currentType, setCurrentType] = useState(0);
  const [currentFormColor, setCurrentFormColor] = useState<{
    label: string;
    value: string;
  }>();

  const [measureType, setMeasureType] = useState("METERS");
  const [providerId, setProviderId] = useState<any>();
  const [image, setImage] = useState("");

  const [currentProvider, setCurrentProvider] = useState<PersonDict>();

  useEffect(() => {
    formMaterial.setFieldValue("measure_type", measureType);
    loadTypes();
  }, []);

  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);
      MaterialApi.getItem(Number(params.id)).then((resp) => {
        formMaterial.setFieldsValue(resp?.data);
        setCurrentProvider(resp?.data.provider);
        setCurrentMaterial(resp?.data);
        setCurrentColor(resp?.data.color);

        setCurrentType(resp?.data.type_id);
        if (resp?.data.color) {
          setCurrentFormColor({
            label: resp?.data.color.name,
            value: resp?.data.color.id,
          });
        }
        setImage(resp?.data.banner);
      });
    }
  }, [params.id]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  const onChangeMeasure = (e: RadioChangeEvent) => {
    setMeasureType(e.target.value);
  };

  function loadTypes() {
    setLoadingTypes(true);
    TypeApi.loadTypesFilter("MATERIAL")
      .then((resp) => {
        setDataTypes(resp?.data);
      })
      .finally(() => {
        setLoadingTypes(false);
        setCurrentType(Number(currentMaterial?.type_id));
      });
  }

  const handlerSubmit = (values: any) => {
    setLoading(true);
    values = {
      ...values,
      ...{
        banner: image,
        provider_id: currentProvider?.id,
        color: currentColor,
        type_id: currentType,
      },
    };

    if (isEdit) {
      MaterialApi.update(values, Number(params.id))
        .then((resp) => {
          message.success(resp?.data.msg);
          history.replace(APP_ROUTE.MATERIAL());
        })
        .catch((e) => {
          message.error(e?.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      MaterialApi.addnew(values)
        .then((resp) => {
          message.success(resp?.data.msg);
          history.replace(APP_ROUTE.MATERIAL());
        })
        .catch((e) => {
          message.error(e?.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function handlerProvider(item: any) {
    setCurrentProvider({
      id: item.value,
      name: item.label,
    });
  }

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />
      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? "Atualizar" : "Cadastrar"} Material
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>Cadastrar novo material</Title>
        </Col>
        <Col>
          <Button
            type="ghost"
            size="large"
            onClick={() => {
              formMaterial.submit();
            }}
            loading={loading}
          >
            {isEdit ? "Atualizar" : "Salvar"}
          </Button>
        </Col>
      </Row>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={formMaterial}
          onFinish={handlerSubmit}
        >
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <TypeForm
                type={"MATERIAL"}
                loadItens={loadTypes}
                onSelect={setCurrentType}
                list={dataTypes}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Form.Item
                name="name"
                label={"Nome"}
                rules={[{ required: true, message: "Nome obrigatório!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label={"Descrição"}
                rules={[{ required: true, message: "Nome obrigatório!" }]}
              >
                <TextArea placeholder={"Descritivo detalhado do material"} />
              </Form.Item>

              <Form.Item
                label={"Tabela de medidas"}
                name="measure_type"
                rules={[{ required: true, message: "Campo obrigatório!" }]}
              >
                <Radio.Group
                  onChange={onChangeMeasure}
                  optionType="button"
                  buttonStyle="solid"
                  value={measureType}
                >
                  <Radio value={"METERS"}>Metro</Radio>
                  <Radio value={"CENTIMETERS"}>Centímetros</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="width"
                rules={[{ required: true, message: "Campo obrigatório!" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  prefix="Largura"
                  placeholder={`00,00${measureType == "METERS" ? "mt" : "cm"}`}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item
                name="height"
                rules={[{ required: true, message: "Campo obrigatório!" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  prefix="Comprimento"
                  placeholder={`00,00${measureType == "METERS" ? "mt" : "cm"}`}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item
                name="weight"
                rules={[{ required: true, message: "Campo obrigatório!" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  prefix="Peso"
                  placeholder={`00,00${measureType == "METERS" ? "mt" : "cm"}`}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Row>
                <Col span={24}>
                  <Form.Item label={"Valor normal"} name="normal_value">
                    <InputNumber
                      defaultValue={0}
                      style={{
                        width: "100%",
                        marginRight: "1rem",
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label={"Valor no atacado"} name="attacked_value">
                    <InputNumber
                      defaultValue={0}
                      placeholder="Preencha com o valor"
                      style={{
                        width: "100%",
                        marginRight: "1rem",
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"from_amount"}
                    label="A partir de"
                    rules={[{ required: true, message: "Campo obrigatório!" }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name={"size_purchased"}
                    label="Quantidade comprada"
                    rules={[{ required: true, message: "Campo obrigatório!" }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={"Valor pago"} name="payed_value">
                    <InputNumber
                      defaultValue={0}
                      placeholder="Preencha com o valor"
                      style={{
                        width: "100%",
                        marginRight: "1rem",
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <PearsonForm
                onSelect={handlerProvider}
                provider={currentProvider}
                type={"PROVIDER"}
                label={"Fornecedor"}
              />

              <Form.Item label={"Cor"}>
                <ColorsSelect
                  onColor={setCurrentColor}
                  defaultColor={currentFormColor || undefined}
                />
              </Form.Item>
              <Row gutter={[16, 16]}>
                {isEdit && (
                  <Col>
                    <>
                      <Image
                        width={250}
                        src={`${process.env.REACT_APP_API}/storage/${image}`}
                      />
                    </>
                  </Col>
                )}
                <Col>
                  <UploadFile
                    path={"material"}
                    hint={"PNG, JPG or PDF (max. 00 Mb)"}
                    onFile={setImage}
                  />
                </Col>
              </Row>
            </div>
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(FormMaterial);
