import { MenuItens } from '../../classes/Util';
import APP_ROUTE from '../../routes/routes';

const OptionsMenuAdministrator: Array<MenuItens> = [
  {
    label: 'Pedidos',
    key: 'pedidos',
    children: [
      {
        label: 'Venda site',
        key: 'venda-site',
        route: APP_ROUTE.PURCHASE_SITESALE(),
      },
      // {
      //   label: 'Trocas e devoluções',
      //   key: 'trocas-e-devolucoes',
      //   route: APP_ROUTE.PURCHASE_EXCHANGE(),
      // },
      {
        label: 'Vendas do atacado',
        key: 'vendas-do-atacado',
        route: APP_ROUTE.PURCHASE_WHOLESALE(),
      },
    ],
  },
  {
    label: 'Produtos da loja',
    key: 'produtos-da-loja',
    children: [
      {
        label: 'Produto',
        key: 'produto',
        route: APP_ROUTE.PRODUCT(),
      },
      {
        label:'Solicitações de produção',
        key:'solicitation-products-admin',
        route:APP_ROUTE.SOLICITATION()
      },
      {
        label: 'Estoque',
        key: 'estoque',
        route: APP_ROUTE.PRODUCT_VIEW_STOCK('stock'),
      },
      {
        label: 'Departamento',
        key: 'departamento',
        route: APP_ROUTE.STORE_DEPARTMENT(),
      },
    ],
  },
  {
    label: 'Cupons de descontos',
    key: 'cupons-de-desconto',
    route: APP_ROUTE.COUPON(),
  },
  {
    label: 'Lojas',
    key: 'lojas',
    route: APP_ROUTE.SHOP(),
  },
  {
    label: 'Cadastros',
    key: 'cadastros',
    children: [
      {
        label: 'Fornecedores',
        key: 'fornecedores',
        route: APP_ROUTE.PEARSON('provider'),
      },
      {
        label: 'Cliente',
        key: 'cliente',
        route: APP_ROUTE.USER('client'),
      },
      {
        label: 'Equipe',
        key: 'equipe',
        route: APP_ROUTE.PEARSON('team'),
      },
      {
        label: 'Criador',
        key: 'criador',
        route: APP_ROUTE.CREATOR(),
      },
      {
        label: 'Indicador',
        key: 'indicador',
        route: APP_ROUTE.INDICATOR(),
      },
      {
        label: 'Colaborador',
        key: 'colaborador',
        route: APP_ROUTE.COLABORATOR(),
      },
    ],
  },
  {
    label: 'Confecções',
    key: 'confeccoes',
    children: [
      {
        label: 'Materiais',
        key: 'materiais',
        route: APP_ROUTE.MATERIAL(),
      },
      {
        label: 'Modelagem',
        key: 'modelagem',
        route: APP_ROUTE.MODELING(),
      },
      {
        label: 'Criadores/Produções',
        key: 'criadores-producoes',
        route: APP_ROUTE.MODELING(),
      },
    ],
  },
  // {
  //   label: 'Financeiro',
  //   key: 'financeiro',
  //   children: [
  //     {
  //       label: 'Geral',
  //       key: 'geral',
  //     },
  //     {
  //       label: 'Pagamentos',
  //       key: 'pagamentos',
  //     },
  //     {
  //       label: 'Relatórios',
  //       key: 'relatorios',
  //     },
  //   ],
  // },
  {
    label: 'Sistema',
    key: 'system_settings',
    children: [
      {
        label: 'Geral',
        key: 'settings-general',
        route: APP_ROUTE.SYSTEM_SETTINGS_GENERAL(),
      },
      {
        label: 'Feedback',
        key: 'feedback',
        route: APP_ROUTE.FEEDBACK_ADMIN(),
      },
      {
        label: 'Caixas de entrega',
        key: 'box-shipping',
        route: APP_ROUTE.SYSTEM_SETTINGS_PACKING(),
      },
      // {
      //   label: 'Credenciais de apis',
      //   key: 'api-credentials',
      //   route: APP_ROUTE.SYSTEM_SETTINGS_CREDENTIALS(),
      // },
    ],
  },
  {
    label: 'Acessar site',
    key: 'acessar-site',
    route: 'http://127.0.0.1:8000',
    newTab: true,
  },
];

const OptionsMenuCreator: Array<MenuItens> = [
  {
    label: 'Produtos da loja',
    key: 'produtos-da-loja',
    children: [
      {
        label: 'Produtos',
        key: 'produtos',
        route: APP_ROUTE.CREATOR_PRODUCT(),
      },
      {
        label: 'Estoque',
        key: 'estoque',
        route: APP_ROUTE.CREATOR_PRODUCT_VIEW_STOCK('stock'),
      },
      {
        label: 'Solicitação de produtos',
        key: 'solicitacao',
        route: APP_ROUTE.CREATOR_PRODUCT_SOLICITATION(),
      },
    ],
  },
  {
    label: 'Cupons de descontos',
    key: 'cupons-de-desconto',
    route: APP_ROUTE.COUPON(),
  },
  {
    label: 'Confecções',
    key: 'confeccoes',
    children: [
      {
        label: 'Modelagem',
        key: 'modelagem',
        route: APP_ROUTE.CREATOR_PRODUCT_CREATE(),
      },
      {
        label: 'Criações',
        key: 'creations',
        route: APP_ROUTE.CREATOR_CRIATION(),
      },
    ],
  },
  // {
  //   label: 'Financeiro',
  //   key: 'financeiro',
  //   children: [
  //     {
  //       label: 'Informe de rendimentos',
  //       key: 'informe',
  //     },
  //     {
  //       label: 'Relatórios',
  //       key: 'relatorios',
  //     },
  //   ],
  // },
  {
    label: 'Página de vendas',
    key: 'page',
    children: [
      {
        label: 'Solicitar alteração de informação',
        key: 'solicitar-alteracoes',
      },
      {
        label: 'Feedback',
        key: 'feedback',
        route: APP_ROUTE.FEEDBACK(),
      },
    ],
  },
];

export { OptionsMenuAdministrator, OptionsMenuCreator };
