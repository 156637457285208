import { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom"

import { Breadcrumb, Button, Card, Col, Form, Input, Row, Space, Switch, Table, Typography, message } from "antd";
import {
    PlusSquareOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    CloseOutlined,
    MinusOutlined,
    StarOutlined,
  } from '@ant-design/icons';
import ProductApi from "../../../../services/ProductApi";
import { Product, ProductItem } from "../../../../classes/Product";
import { ProductMeasure } from "../../../../classes/Measure";
import APP_ROUTE from "../../../../routes/routes";
import modal from "antd/lib/modal";
import ProductionApi from "../../../../services/ProductionApi";

const {Text, Title} = Typography;

interface RouteOptions{
    id:string;
}


const CreatorProductStockPage = () =>{
    const history = useHistory();
    let params = useParams<RouteOptions>();
    const [form] = Form.useForm();

    const [currentProduct, setCurrentProduct] = useState<ProductItem>();
    const [sendingInformation, setSendingInformation] = useState(false);
    const [fieldChecked, setFieldChecked] = useState(false);
    const [listMeasure, setListMeasure] = useState<ProductMeasure[]>([]);
    const [currentColor, setCurrentColor] = useState<{name:string,value:string, id:number}>();
    const [disableRequest, setDisableRequest] = useState(true);


    const [totalItens, setTotalItens] = useState(0);
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() =>{
        console.log(params);
        if(!!params){
           getStock();
        }
    },[params]);

    useEffect(() =>{
        setTotalValue(
            Number(currentProduct?.product[0].price) * totalItens
        );
    },[totalItens]);

    
    function getStock(){
        ProductApi.getStock('creator', Number(params.id)).then((resp) =>{
            const {data} = resp;

            setCurrentProduct(data)
            setListMeasure(data.measures);
            setCurrentColor(data.product[0].color)
        })
    }

    const changeStock = (option: 'ADD' | 'REM', index: number) => {
        const items = [...listMeasure];
        let newStock = 0;
        if (option == 'ADD') {
            newStock = items[index].stock + 1;
        }
    
        if (option == 'REM') {
          let newValue = items[index].stock - 1;
          newStock = newValue <= 0 ? 0 : newValue;
        }
    
        items[index].stock = newStock;
        
        
        setListMeasure(items);

        let totalItens = 0;
        items.forEach((item) =>{
            totalItens += item.stock;
        })

        setTotalItens(totalItens);
    };

    function handlerValues() {

        let payload = {
            old_item:'PRODUCT',
            type:'REPOSITION',
            item_type:'PRODUCT',
            item_id:Number(params.id),
            itens:[
                {
                    color:{
                        label:currentColor?.name,
                        value:currentColor?.id,
                        key:currentColor?.id
                    },
                    production_itens:listMeasure.map((item) =>{
                        return {
                            id:item.id,
                            unity:item.unity,
                            label:item.label,
                            size:item.stock,
                            value:item.stock * Number(currentProduct?.product[0].price)
                        }
                    })
                }
            ]
        }

        modal.confirm({
            title: 'Confirme',
            icon: <CheckOutlined />,
            content:`Será gerada uma cobrança no valor de R$ ${totalValue}. Após o pagamento da cobrança a produção será iniciada!`,
            okText: 'Ok',
            onOk: () => {
                setSendingInformation(true);
                ProductionApi.new('creator', payload).then((resp) =>{
                    message.success(resp?.data.msg);
                }).finally(() =>{
                    setSendingInformation(false);
                })
             //history.push(APP_ROUTE.CREATOR_PRODUCT_CREATE());
            },
          });
    }

    function changeStatus() {
        setSendingInformation(true);
        ProductApi.enable(Number(params.id))
          .then((resp) => {
            message.success(resp?.data.msg);
          })
          .finally(() => {
            setSendingInformation(false);
            getStock();
          });
      }
    
    return (<>
        <Row justify="end">
            <Button
            type="text"
            onClick={() => {
                history.goBack();
            }}
            >
            <Text strong>VOLTAR</Text>
            </Button>
        </Row>
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item
            onClick={() => {
                history.goBack();
            }}
            >
            Alterar estoque
            </Breadcrumb.Item>
        </Breadcrumb>

        <Row justify="space-between" align="middle">
            <Col>
                <Title level={2}>Estoque da peça: {currentProduct?.product[0].name}</Title>
            </Col>
        </Row>

        <Space
            size={[40, 40]}
            direction="vertical"
            align="start"
            style={{ width: '100%' }}
            wrap
        >
            <div className="wrapper-table">
          <Table
            pagination={false}
            dataSource={currentProduct?.product}
            size={'small'}
            columns={[
              {
                title: 'Produto',
                dataIndex: 'name',
              },
              {
                title: 'Cor',
                dataIndex: 'color',
                render: (text: any, record: Product) => (
                    <div className="wrapper-color">
                    <span
                      className="color-ball"
                      style={{ background: record.color?.value }}
                    ></span>
                    <Text>{record.color?.name}</Text>
                  </div>
                )
              },
              {
                title: 'Estoque',
                dataIndex: 'creator_name',
                render: (text: any, record: Product) => (
                  <Text>
                    {record?.stock_status == 'OUT' && 'Estoque zerado'}
                    {record?.stock_status == 'FULL' && 'Em estoque'}
                    {record?.stock_status == 'PARTIALLY' && 'Estoque parcial'}
                    {currentProduct?.total == 0 && ('Estoque zerado')}
                  </Text>
                ),
              },
              {
                title: 'Status no site',
                dataIndex: 'status',
                render: (text: any, record: Product) => (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Form form={form}>
                          <Form.Item label={'Ativo'} name="active">
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              size={'small'}
                              onChange={(val: boolean) => {
                                changeStatus();
                              }}
                              defaultChecked={currentProduct?.product[0].active}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                      <Col>
                        <Button
                          type="link"
                          onClick={() => {
                            history.push(APP_ROUTE.PRODUCT_VIEW(record.id));
                          }}
                        >
                          Ver mais
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              },
            ]}
          ></Table>
        </div>
        <div>
          <Row justify={'space-between'} align="middle">
            <Col>
              {' '}
              <Title level={3}>Peça por tamanho</Title>
            </Col>
            <Col>
              <div className="card-text">
                <p>
                  <span>Peças em estoque</span>{' '}
                  <strong>{currentProduct?.total}</strong>
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <div className="block-card">
            {listMeasure.map((item: ProductMeasure, index: number) => (
              <Card style={{ width: '300px' }}>
                <Title level={4} className={'item-title'}>
                  {item.label}
                </Title>
                <Row>
                  <Col>
                    <Button
                      icon={<MinusOutlined />}
                      onClick={() => {
                        changeStock('REM', index);
                      }}
                      disabled={item.stock <= 0}
                    ></Button>
                  </Col>
                  <Col className="field-number">
                    <Input type={'text'} value={item.stock} />
                  </Col>
                  <Col>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        changeStock('ADD', index);
                      }}
                    ></Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </div>
            <Row  justify={'end'} align={'middle'}>
                <Col>
                    <p>Total: <strong>R$ {totalValue > 0 ? totalValue : 0}</strong></p>
                </Col>
            </Row>
            <Row justify={'end'} align={'middle'}>
                
                <Col>
                    <Button
                    type="default"
                    size="large"
                    disabled={totalItens <= 0 ? true : false}
                    loading={sendingInformation}
                    onClick={handlerValues}
                    >
                    Solicitar estoque
                    </Button>  
                </Col>
            </Row>

        </Space>
    </>)
}

export default CreatorProductStockPage