import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Table,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from "react";
import MeasureApi from "../../services/MeasureApi";

const { Title, Text } = Typography;

interface ProductMeasureFormOptions {
  type: "NUMBER" | "LETTER";
  onUpdate: (val?: any) => void;
  onClose: () => void;
}

const ProductMeasureForm: FC<ProductMeasureFormOptions> = ({
  type,
  onUpdate,
  onClose,
}: ProductMeasureFormOptions) => {
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [sending, setSending] = useState(false);

  const handlerSubmit = (values: any) => {
    setSending(true);

    values = { ...values, ...{ unity: type } };

    MeasureApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);

        var data = resp?.data.data;

        data = {
          ...data,
          ...{
            value: 0,
            stock: 0,
            weight: 0,
          },
        };
        onUpdate(data);
        form.resetFields();
        onClose();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <>
      <Form form={form} onFinish={handlerSubmit}>
        <Form.Item name="name">
          <Input />
        </Form.Item>

        <Form.Item name="unity">
          <Radio.Group defaultValue={type} buttonStyle="solid">
            <Radio.Button value="NUMBER">Número</Radio.Button>
            <Radio.Button value="LETTER">Letras</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" className="btn-orange">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ProductMeasureForm;
