import {
    Row,
    Col,
    Statistic,
    Space,
    Button,
    Divider,
    Form,
    Input,
    Typography,
    Dropdown,
    Menu,
    message,
    Radio,
    Table,
    Image
  } from 'antd';
  import {
    SearchOutlined,
    DownOutlined,
    CheckOutlined,
    CloseOutlined,
  } from '@ant-design/icons';
  import { useEffect, useState } from 'react';
  import { useHistory } from 'react-router-dom';
  import APP_ROUTE from '../../../routes/routes';
  import moment from 'moment';
  import { Modeling } from '../../../classes/Modeling';
  import { Paginate } from '../../../classes/Paginate';
  import ModelingApi from '../../../services/ModelingApi';
  
  const { Title, Text } = Typography;

const CreatorCreation = () =>{
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [loadingStats, setLoadingStats] = useState(false);
    const [list, setList] = useState<Paginate<Modeling>>();
  
    const [filterType, setFilterType] = useState<'MODELING' | 'STOCK'>(
      'MODELING'
    );
  
    const [filter, setFilter] = useState({});
  
    useEffect(() => {
      onLoad();
    }, [filter]);
  
    useEffect(() => {
      // onLoad();
      setFilter({...filter, ...{type:'creation'}});
    }, []);
  
    function goDetail(item: Modeling) {
      history.push(APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(item.id));
    }
  
    const handlerFilter = (values: any) => {
      setFilter({ ...filter, ...values });
    };
  
    function onLoad() {
      setLoading(true);
      
      ModelingApi.list(filter, 'creator')
        .then((resp) => {
          setList(resp?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  
    function goPayment(data: Modeling) {
      if (data.payment) {
        window.open(data.payment.url, '_blank');
      }
    }
  
    function approve(data: Modeling, option: string) {
      ModelingApi.changeApprovation({ situation: option }, Number(data.id))
        .then((resp) => {
          message.success(resp.data.msg);
          setLoading(true);
          onLoad();
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        });
    }
  
    const OptionsMenu = (record: Modeling) => {
      const Itens = () => {
        return (
          <Menu>
            <Menu.Item
              onClick={() =>
                history.push(
                  APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(record.id)
                )
              }
            >
              {record.status == 'CREATED' ? 'Ver/editar' : 'Ver'}
            </Menu.Item>
  
            {record.situation == 'ON_APPROVED' && (
              <>
                <Menu.Item onClick={() => approve(record, 'APPROVED')}>
                  Aprovar
                </Menu.Item>
                <Menu.Item onClick={() => approve(record, 'REPPROVED')} danger>
                  Reprovar
                </Menu.Item>
              </>
            )}
            <Menu.Item onClick={() => goPayment(record)}>Ver Cobrança</Menu.Item>
          </Menu>
        );
      };
  
      return (
        <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
          <Button type="ghost" onClick={(e) => e.preventDefault()}>
            <Space>
              Opções <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      );
    };
  
    return (
      <>
        <Row justify={'space-between'}>
          <Col>
            <Title level={2}>Modelagem</Title>
          </Col>
          <Col>
            <Form layout={'inline'} onFinish={handlerFilter}>
              <Form.Item name="term">
                <Input
                  type="text"
                  placeholder="Buscar"
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
  
        <div className="wrapper-table">
          <Table
            columns={[
              {
                title: 'Produto solicitado',
                key: 'name',
                render: (text: any, record: Modeling) => (
                  <Row>
                    <Col>
                      {!!record.media.reference && (
                        <Image
                          width={50}
                          src={`${process.env.REACT_APP_API}/storage/${record.media?.reference[0]?.file}`}
                        />
                      )}
                    </Col>
                    <Col>
                      <Space direction="vertical">
                        <Text>
                          {moment(record.created_at).format('DD/MM/YYYY')}
                        </Text>
                        <Text strong>{record.name}</Text>
                      </Space>
                    </Col>
                  </Row>
                ),
              },
              {
                title: 'Tipo',
                key: 'type',
                render: (text: any, record: Modeling) => (
                  <Text>
                    {filterType == 'MODELING' && 'Modelagem'}
                    {filterType == 'STOCK' && 'Estoque'}
                  </Text>
                ),
              },
              {
                title: 'Status',
                key: 'value',
                render: (text: any, record: Modeling) => (
                  <Text>
                    {record.status == 'CREATED' && 'Iniciado'}
                    {record.status == 'WAITING_BUDGET' && 'Aguardando pagamento'}
                    {record.status == 'IN_PROGRESS' && 'Em progresso'}
                    {record.status == 'IN_CHARGE' && 'Em produção'}
                    {record.status == 'IN_PAYMENT' && 'Em pagamento'}
                    {record.status == 'PAYED' && 'Pago'}
                  </Text>
                ),
              },
              {
                title: 'Situação',
                key: 'situation',
                render: (text: any, record: Modeling) => (
                  <Text>
                    {record.situation == 'CREATED' && 'Iniciado'}
                    {record.situation == 'ON_APPROVED' && 'Aguardando aprovação'}
                    {record.situation == 'REPPROVED' && 'Reprovada'}
                    {record.situation == 'APPROVED' && 'Aprovada'}
                  </Text>
                ),
              },
              {
                title: '',
                key: 'options',
                render: (text: any, record: Modeling) => (
                  <OptionsMenu {...record} />
                ),
              },
            ]}
            dataSource={list?.data}
            size={'middle'}
            loading={loading}
            rowKey={(record) => record.id || 0}
            pagination={{
              position: ['bottomCenter'],
              pageSize: list?.per_page,
              current: list?.current_page,
              total: list?.total,
              onChange: (page, pageSize) => {
                setFilter({ ...filter, ...{ page: page } });
              },
            }}
          />
        </div>
      </>
    );
}

export default CreatorCreation;