import BaseApi from './BaseApi';

export default {
  list: (filter?: any, endpoint?: string) => {
    return BaseApi.get(`/${endpoint}/product`, { params: filter });
  },
  listDict: () => {
    return BaseApi.get('/admin/product/utils/dict');
  },
  getItem: (id: number, endpoint?:string, filter?: any) => {
    return BaseApi.get(`/${!!endpoint ? endpoint : 'admin'}/product/${id}`, { params: filter });
  },
  getStock: (endpoint:string,id: number) => {
    return BaseApi.get(`/${endpoint}/product/${id}/stock`);
  },
  updateStock: (id: number, body: any) => {
    return BaseApi.put(`/admin/product/${id}/stock`, body);
  },
  addnew: (body: any) => {
    return BaseApi.post(`/admin/product`, body);
  },
  newvariation: (body: any) => {
    return BaseApi.post(`/admin/product/utils/new-variation`, body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`/admin/product/${id}`);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`/admin/product/${id}`, body);
  },
  enable: (id: number) => {
    return BaseApi.put(`/admin/product/${id}/enable`);
  },
  removeMedia: (id: number, endpoint: string) => {
    return BaseApi.delete(`${endpoint}/${id}`);
  },
  stockStats:(type:string) =>{
    return BaseApi.get(`/admin/product/utils/stats?type=${type}`);
  },
  search: (filter?: any) => {
    return BaseApi.get('/admin/product/utils/search-item', {
      params: filter,
    });
  },
};
