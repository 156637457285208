import { Button, Form, Input, InputRef, message, Space } from "antd";
import { ValueIteratorTypeGuard } from "lodash";
import { FC, useRef, useState } from "react";
import ProductAttributeApi from "../../services/ProductAttributeApi";

const ProductAttributeForm: FC<{
  newItem: (val?: any) => void;
  onClose: () => void;
  type: string;
}> = ({ newItem, onClose, type }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [color, setColor] = useState("");

  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  const handlerSubmit = (values: any) => {
    console.log(values);
    values = {
      ...values,
      ...{
        type: type,
      },
    };

    if (type == "COLOR") {
      values = { ...values, ...{ value: color } };
    }
    setLoading(true);
    ProductAttributeApi.addnew(values)
      .then((resp) => {
        message.success(resp.data.msg);
        newItem(resp.data);
        onClose();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: "Campo é obrigatório!" }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              {type == "COLOR" && (
                <Form.Item label="Cor" name="value">
                  <Input
                    type="color"
                    placeholder="Cor"
                    ref={inputRef}
                    value={color}
                    onChange={onColorChange}
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button type="primary" className="btn-orange" htmlType="submit">
                  Salvar
                </Button>
              </Form.Item>
            </div>
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default ProductAttributeForm;
