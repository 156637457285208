import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Typography,
  Image,
  Modal,
  AutoComplete,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import APP_ROUTE from '../../../../routes/routes';
import ProductApi from '../../../../services/ProductApi';
import { Product } from '../../../../classes/Product';
import moment from 'moment';

import './style.scss';
import { idText } from 'typescript';
import ModelingApi from '../../../../services/ModelingApi';
import FellaStatisct from '../../../../components/FellaStatistic';

const { Text, Title } = Typography;

interface Stats{
  count:number;
  value:number;
}

const ProductPageHome = (props: RouterProps) => {
  const history = useHistory();
  const params = useParams<{ view: string }>();
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [list, setList] = useState<any>();
  const [filter, setFilter] = useState({});
  const [showVariation, setShowvariation] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(0);

  const [registerNewProduct, setRegisterNewProduct] = useState(false);
  const [modelingsOptions, setModelingsOptions] = useState<{ value: string; label: string }[]>([]);

  
  const [stats, setStats] = useState<{
    products_sold:Stats,
    purchase_in_progress:Stats,
    products_for_sale:number
  }>();

  const [value, setValue] = useState('');

  useEffect(() => {
    onLoad();
    getStats();
  }, [filter]);

  useEffect(() => {
    onLoad();
    if (!!params) {
      if (!!params.view && params.view == 'stock') {
        setFilter({ ...filter, ...{ stock: true } });
        setShowStock(true);
      }
    }
    // console.log(params);
  }, []);

  function goDetail(item: Product) {}

  function removeItem(item: Product) {
    ProductApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
  }

  const handlerFilter = (values: Product) => {
    setFilter({ ...filter, ...values });
  };

  function getStats(){
    setLoadingStats(true);
    ProductApi.stockStats('purchase').then((resp) =>{
      setStats(resp?.data)
    }).finally(() =>{
      setLoadingStats(false);
    })
  }

  function onLoad() {
    setLoading(true);
    ProductApi.list(filter, 'admin')
      .then((resp) => {
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createNewVariation(product_id: number) {
    ProductApi.newvariation({
      id: product_id,
    })
      .then((resp) => {
        message.success(resp?.data.msg);
        history.replace(APP_ROUTE.PRODUCT_VIEW(resp?.data.id, 'edit'));
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  }

  const OptionsMenu = (record: Product) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item
            onClick={() => {
              history.push(APP_ROUTE.PRODUCT_VIEW(record.id, 'edit'));
            }}
          >
            Ver
          </Menu.Item>
          {record.variations > 0 && (
            <Menu.Item
              onClick={() => [
                setFilter({
                  ...filter,
                  ...{ parent_id: record.id, variation: true, page: 0 },
                }),
                setShowvariation(true),
                setCurrentParentId(Number(record.id)),
              ]}
            >
              Variações ({record.variations})
            </Menu.Item>
          )}
          <Menu.Item
            onClick={() => {
              history.push(APP_ROUTE.PRODUCT_STOCK(record.id));
            }}
          >
            Estoque
          </Menu.Item>
          <Menu.Item>
            <Popconfirm
              title={`Variação do produto ${record.name}`}
              onConfirm={() => createNewVariation(Number(record.id))}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Nova variação
              </Button>
            </Popconfirm>
          </Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const handleSearch = (value: string) => {
    ModelingApi.onSearch(value).then((resp) =>{
      setModelingsOptions(resp?.data);
    });
  };

  const onSelect = (data: string) => {
    console.log(modelingsOptions)

    if(!!data && !!modelingsOptions){
      let selectedValue =  modelingsOptions.filter((item) =>{
        // item.value.toString() === data
        if(item.value && data){
          return item.value == data;
        }
      });
      setValue(selectedValue[0].label);
    }
    console.log('onSelect', data);
  };
   

  return (
    // onClick={() => history.push(APP_ROUTE.PRODUCT_CREATE('basic'))}
    <>
      <Modal
        title="Novo produto"
        destroyOnClose={true}
        closable={true}
        open={registerNewProduct}
        onOk={() =>{console.log('onOk')}}
        confirmLoading={false}
        onCancel={() =>{setRegisterNewProduct(false)}}
      >
        <Space direction='vertical' style={{width:'100%'}}>
          <Text>Selecionar modelagem</Text>
          
          <AutoComplete
            value={value}
            style={{ width: '100%' }}
            onSearch={handleSearch}
            options={modelingsOptions}
            onSelect={onSelect}
          >
            <Input.Search size="large" placeholder="Buscar modelagem" enterButton/>
          </AutoComplete>
        </Space>
      </Modal>

      <Row justify="space-between">
        <Col span={16}>
          {!!stats && (
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <FellaStatisct
                title={`Total vendido`}
                linePosition="HORIZONTAL"
                highlight={{ symbol: "R$", value: Number(stats?.products_sold.value).toString() }}
                line={[
                  { symbol: "Qtd", value: Number(stats?.products_sold.count).toString() },
                ]}
              />
            </Col>
            <Col span={8}>
            <FellaStatisct
                title={`Vendas em andamento`}
                linePosition="HORIZONTAL"
                highlight={{ symbol: "R$", value: Number(stats?.purchase_in_progress.value).toString() }}
                line={[
                  { symbol: "Qtd", value: Number(stats?.purchase_in_progress.count).toString() },
                ]}
              />
            </Col>
            <Col span={8}>
            <Statistic
                title={`Produtos a venda`}
                prefix={'Qtd.'}
                value={stats?.products_for_sale}
                loading={loadingStats}
              />
            </Col>
          </Row>
          )}
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => {
              history.push(APP_ROUTE.PRODUCT_CREATE('basic'))
            }}
          >
            NOVO PRODUTO
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col>
          <Title level={2}>Produtos</Title>
        </Col>
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={'Mostrar deletados'}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {showVariation && (
        <Row>
          <Button
            icon={<LeftOutlined />}
            type="default"
            onClick={(e) => [
              setFilter({
                ...filter,
                ...{ page: 0, parent_id: 0, variation: false },
              }),
              setShowvariation(false),
            ]}
          >
            Ver original
          </Button>
        </Row>
      )}

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: 'Produto',
              key: 'name',
              render: (text: any, record: Product) => (
                <Row gutter={[16, 16]} align={'middle'}>
                  <Col>
                    <Image
                      width={60}
                      src={`${process.env.REACT_APP_API}/storage/${record.media?.file}`}
                    />
                  </Col>
                  <Col>
                    <Text>
                      {record.name} <br /> {record.sku}
                    </Text>
                  </Col>
                </Row>
              ),
            },
            {
              title: 'Cor',
              key: 'created_at',
              render: (text: any, record: Product) => (
                <>
                  <div className="wrapper-color">
                    <span
                      className="color-ball"
                      style={{ background: record.color?.value }}
                    ></span>
                    <Text>{record.color?.name}</Text>
                  </div>
                </>
              ),
            },
            {
              title: 'Preço',
              dataIndex: 'value',
              render: (text: any, record: Product) => (
                <Text>R$ {record.price}</Text>
              ),
            },
            {
              title: 'Criador',
              dataIndex: 'creator_name',
              render: (text: any, record: Product) => (
                <Text>{record?.creator_name}</Text>
              ),
            },
            {
              title: 'Estoque',
              dataIndex: 'stock',
              render: (text: any, record: Product) => (
                <>
                  {record?.stock_status == 'OUT' && 'Estoque zerado'}
                  {record?.stock_status == 'FULL' && 'Em estoque'}
                  {record?.stock_status == 'PARTIALLY' && 'Estoque parcial'}
                </>
              ),
            },
            {
              title: '',
              dataIndex: 'creator_name',
              render: (text: any, record: Product) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={'middle'}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ['bottomCenter'],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default withRouter(ProductPageHome);
