export interface Options {
  label: string;
  value:
    | "ALL_PRODUCT"
    | "DEPARTMENT"
    | "ABOVE"
    | "SHOPPING_ABOVE"
    | "DISCOUNT_ON_THIRD_PRODUCT"
    | "SPECIFIC_PRODUCT"
    | "PROGRESSIVE_DISCOUNT"
    | "CREATOR"
    | "TOTAL_PART"
    | "FREE_DELIVERY"
    | "PERCENT"
    | "UNIQUE_USE"
    | "ONE_BY_CLIENT"
    | "LIMITED_IN";
}

const ListOptions: Array<Options> = [
  {
    label: "Todos os produtos",
    value: "ALL_PRODUCT",
  },
  {
    label: "Departamento",
    value: "DEPARTMENT",
  },
  {
    label: "Peças acima de R$",
    value: "ABOVE",
  },
  {
    label: "Compras acima de R$",
    value: "SHOPPING_ABOVE",
  },
  {
    label: "Desconto na terceira peça",
    value: "DISCOUNT_ON_THIRD_PRODUCT",
  },
  {
    label: "Produto especifico",
    value: "SPECIFIC_PRODUCT",
  },
  {
    label: "Desconto Progressivo",
    value: "PROGRESSIVE_DISCOUNT",
  },
];

const fromOptions: Array<Options> = [
  {
    label: "Criador",
    value: "CREATOR",
  },
  {
    label: "Total da peça",
    value: "TOTAL_PART",
  },
];

const LimitOptions: Array<Options> = [
  {
    label: "Limitar o número de usos",
    value: "LIMITED_IN",
  },
  {
    label: "Limite de 1 por cliente",
    value: "ONE_BY_CLIENT",
  },
  {
    label: "Cupom de uso único",
    value: "UNIQUE_USE",
  },
];

export { ListOptions, fromOptions, LimitOptions };
