import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Typography,
  Table,
  Tag,
  Image,
  Modal,
  DatePicker,
  Select,
  Radio
} from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Modeling } from '../../../../classes/Modeling';
import { Paginate } from '../../../../classes/Paginate';
import APP_ROUTE from '../../../../routes/routes';
import ModelingApi from '../../../../services/ModelingApi';
import moment from 'moment';
import { Production } from '../../../../classes/Production';
import ProductionApi from '../../../../services/ProductionApi';
import { ProductAttribute } from '../../../../classes/ProductAttribute';
import ProductAttributeApi from '../../../../services/ProductAttributeApi';
import { BaseDict } from '../../../../classes/Util';
import { Measure } from '../../../../classes/Measure';
import MeasureApi from '../../../../services/MeasureApi';
import { CountItens } from '../../../../classes/Stats';
import DispatchApi from '../../../../services/DispatchApi';



const { Title, Text } = Typography;

const { Option } = Select;

const ModelingPageHome = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [currentModeling, setCurrentModeling] = useState<Modeling>();
  const [loadingStats, setLoadingStats] = useState(false);
  const [list, setList] = useState<Paginate<Modeling>>();
  const [filter, setFilter] = useState({});
  const [showFormProduction, setShowFormProduction] = useState(false);
  const [stats, setStats] = useState<CountItens>();
  const [currentUnityMeasure, setCurrentUnityMeasure] = useState<'NUMBER' | 'LETTER'>('LETTER');
  const [startProduction, setStartProduction] = useState(false);

  const [colors, setColors] =
    useState<BaseDict[]>([]);

  const [measure, setMeasure] = useState<Measure[]>([]);

  useEffect(() => {
    onLoad();
    getStats();
  }, [filter]);

  useEffect(() => {
    onLoad();
    loadExtra();
  }, []);

  function goDetail(item: Modeling) {
    history.push(APP_ROUTE.MODELING_VIEW(item.id));
  }

  function generateCharge(id: number) {
    ModelingApi.charge(id)
      .then((resp) => {
        message.success(resp?.data.msg);
        onLoad();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  }

  function getStats(){
    setLoadingStats(true);
    ModelingApi.stats(filter).then((resp) =>{
      setStats(resp?.data);
    }).finally(() =>{
      setLoadingStats(false);
    })
  }


  function removeItem(item: Modeling) {
    ModelingApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
  }

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    ModelingApi.list(filter, 'admin')
      .then((resp) => {
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadExtra() {
    ProductAttributeApi.list().then((resp) => {
      let data = resp?.data;
      setColors(data.color);
    })

    MeasureApi.list({ dict: true }).then((resp) => {
      setMeasure(resp?.data);
    })
  }

  function requestApprove(item: Modeling) {
    setLoading(true);
    ModelingApi.requestApprove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
        changeProductionStatus(Number(item.id),!!item?.production ? item.production.id : 0,'ON_APPROVED')
        onLoad();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function startBudget(item: Modeling) {
    setLoading(true);
    ModelingApi.startBudget(Number(item.id)).then((resp) => {
      message.success(resp?.data.msg);
      // history.push()
      goDetail(item);
    }).catch((e) => {
      message.error(e.response.data.msg);
    }).finally(() => {
      setLoading(false);
    })
  }

  const handlerProduction = (values:any)=>{
      // changeProductionStatus
      setStartProduction(true);

      let obj = {
        type:'PILOT',
        measure:values.measure.key,
        prevision_finalized:moment(values.prevision_finalized).format('YYYY-MM-DD'),
        item_type:'MODELING',
        relation_id:Number(currentModeling?.id),
        color:values.color.key,
        size:1,
      };
      ProductionApi.new('admin',obj).then((resp) =>{
        message.success(resp.data.msg);
        setCurrentModeling(undefined);
        setShowFormProduction(false);
        onLoad();
      }).catch((e) =>{
        message.error(e.response?.data.msg);
      }).finally(() =>{
        setStartProduction(false);
      })
  }

  // function changeProductionStatus(item: Modeling, type: string) {
  //   setLoading(true);

  //   ProductionApi.new({
  //     type: type,
  //     item_type: 'MODELING',
  //     relation_id: item.id,
  //   })
  //   // ModelingApi.productState(Number(item.id), newstatus).then((resp) =>{
  //   //   message.success(resp?.data.msg);
  //   //   onLoad();
  //   // }).catch((e) =>{
  //   //   message.error(e.response.data.msg);
  //   // }).finally(() =>{
  //   //   setLoading(false);
  //   // })
  // }

  function goPayment(data: Modeling) {
    if (data.payment) {
      window.open(data.payment.url, '_blank');
    }
  }

  function dispatchSend(recordId:number,productionId?:number){
    DispatchApi.send({
      type:'MODELING',
      relation_id:recordId,
      production_id:productionId
    }).then((resp) =>{
      message.success(resp?.data.msg);
    }).catch((e) =>{
      message.error(e.response.data.msg);
    }).finally(() =>{
      onLoad();
    })
  }


  function changeProductionStatus(recordId:number,productionId:any, newStatus:string)
  {
    ProductionApi.setnewStatus(recordId, {
      status:newStatus,
      relation_id:productionId
    }).then((resp) =>{
      message.success(resp?.data.msg);
      onLoad();
    }).catch((e) =>{
      message.error(e.response.data.msg);
    })
  }

  // function hasItem(key:string, data?:Production[])
  // {
  //   if(!!data && data.length > 0){
  //     let has = data?.filter((item) =>{
  //       item.type == key
  //     });

  //     return !!has ? true : false;
  //   }else{
  //     return true;
  //   }
  // }

  const OptionsMenu = (record: Modeling) => {
    const Itens = () => {
      return (
        <Menu>
          {record.status == 'CREATED' ? (
            <Menu.Item onClick={() => startBudget(record)}>Iniciar orçamento</Menu.Item>
          ) : (
            <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>
          )}

          {record.status != 'PAYED' && (
            <Menu.Item onClick={() => generateCharge(Number(record.id))}>
              Gerar cobrança
            </Menu.Item>
          )}
          {record.production?.current_status.status == 'RECEIVED'? (
            <Menu.Item>
              <Popconfirm
                title={`Confirmar solicitação de aprovação para ${record.name}`}
                onConfirm={() => requestApprove(record)}
              >
                <Button type="text" style={{ padding: 0 }}>
                  Solicitar aprovação
                </Button>
              </Popconfirm>
            </Menu.Item>
          ) : (<></>)}

          {!record.hasPilot && (
            <>
              <Menu.Item onClick={() => [setCurrentModeling(record),setShowFormProduction(true)]}>Iniciar piloto</Menu.Item>
            </>
          )}

          {record.production?.current_status.status == 'PILOT_FINALIZED' && (
            <>
              <Menu.Item onClick={() => dispatchSend(Number(record.id),!!record?.production ? record.production.id : 0)}>Enviar peça piloto</Menu.Item>
            </>
          )}

          {record.production?.current_status.status == 'IN_PRODUCTION_PILOT' && (
            <>
              <Menu.Item onClick={() => changeProductionStatus(Number(record.id),!!record?.production ? record.production.id : 0,'PILOT_FINALIZED')}>Piloto finalizado</Menu.Item>
            </>
          )}


  
          {/* {record.production_situation == 'STARTED' && (
            <Menu.Item onClick={() =>changeProductionStatus(record, 'IN_PRODUCTION')}>Iniciar produção</Menu.Item>
          )}

          {record.production_situation == 'IN_PRODUCTION' && (
            <Menu.Item onClick={() =>changeProductionStatus(record, 'IN_CUT')}>Em corte</Menu.Item>
          )}

          {record.production_situation == 'IN_CUT' && (
            <Menu.Item onClick={() =>changeProductionStatus(record, 'FINALIZED')}>Finalizar produção</Menu.Item>
          )} */}

          {record.status != 'PAYED' && (
            <>
              <Menu.Item onClick={() => goPayment(record)}>Ver Cobrança</Menu.Item>
              <Menu.Item danger>
                <Popconfirm
                  title={`Remover ${record.name}`}
                  onConfirm={() => removeItem(record)}
                  okButtonProps={{ loading: loadingRemove }}
                >
                  <Button type="text" style={{ padding: 0 }}>
                    Remover
                  </Button>
                </Popconfirm>
              </Menu.Item>
            </>

          )}
        </Menu>
      );
    };

    return (
      <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>

      <Modal open={showFormProduction} onCancel={() => setShowFormProduction(false)} destroyOnClose={true} title={'Produção'} onOk={()=>form.submit()} okButtonProps={{loading:startProduction}}>
        <Form form={form} layout={'vertical'} onFinish={handlerProduction}>
          <Form.Item name="prevision_finalized" label={'Data provavel de finalização'}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="color" label={'Cor'}>
            <Select
              labelInValue
              showSearch
              placeholder={'Cor'}
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={colors.map((item: BaseDict) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Radio.Group defaultValue="currentUnityMeasure" onChange={(e) => {setCurrentUnityMeasure(e.target.value)}} buttonStyle="solid">
              <Radio.Button value="LETTER">Letras</Radio.Button>
              <Radio.Button value="NUMBER">Números</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="measure" label={'Tamanho'}>
            <Select
              labelInValue
              showSearch
              placeholder={'Tamanho'}
              defaultActiveFirstOption={false}
              showArrow={true}
              options={measure.filter((item) => item.unity.toString() === currentUnityMeasure).map((item: Measure) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Modelagens criadas`}
                prefix={'Qtd.'}
                value={stats?.count}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.MODELING_CREATE())}
          >
            NOVO MODELAGEM
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col>
          <Title level={2}>Modelagem</Title>
        </Col>
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={'Mostrar deletados'}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: 'Produto solicitado',
              key: 'name',
              render: (text: any, record: Modeling) => (
                <Space.Compact direction='horizontal'>
                  {!!record.media.reference && (
                    <Image
                      width={50}
                      src={`${process.env.REACT_APP_API}/storage/${record.media?.reference[0]?.file}`}
                    />
                  )}
                  <Space direction="vertical" style={{ marginLeft: 20 }}>
                    <Text>
                      {record.name}<br />
                      <strong>{moment(record.created_at).format('DD/MM/YYYY')}</strong>
                    </Text>
                    {record.type && (
                      <Tag color="default">
                        {record.type == 'PILOT' && 'Piloto'}
                        {record.type == 'SALE' && 'Venda'}
                        {record.type == 'MARKETING' && 'Marketing'}
                      </Tag>
                    )}
                  </Space>
                </Space.Compact>
              ),
            },
            {
              title: 'Criado em',
              key: 'created_at',
              render: (text: any, record: Modeling) => (
                <Text>{moment(record.created_at).format('DD/MM/YYYY')}</Text>
              ),
            },
            {
              title: 'Criador',
              dataIndex: 'creator_name',
            },
            {
              title: 'Valor',
              dataIndex: 'value',
            },
            {
              title: 'Status',
              key: 'value',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.status == 'CREATED' && 'Iniciado'}
                  {record.status == 'WAITING_BUDGET' && 'Em orçamento'}
                  {record.status == 'IN_PROGRESS' && 'Em progresso'}
                  {record.status == 'IN_CHARGE' && 'Em produção'}
                  {record.status == 'IN_PAYMENT' && 'Em pagamento'}
                  {record.status == 'PAYED' && 'Pago'}
                </Text>
              ),
            },
            {
              title: 'Situação',
              key: 'situation',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.situation == 'CREATED' && 'Iniciado'}
                  {record.situation == 'ON_APPROVED' && 'Em aprovação'}
                  {record.situation == 'REPPROVED' && 'Reprovada'}
                  {record.situation == 'APPROVED' && 'Aprovada'}
                </Text>
              ),
            },
            {
              title: 'Produção',
              key: 'situation',
              render: (text: any, record: Modeling) => (
                <Text>
                  {/* {JSON.stringify(record.production?.current_status.status)} */}
                  {record.production?.current_status.status == 'IN_PRODUCTION_PILOT' && 'Produzindo Piloto'}
                  {record.production?.current_status.status == 'PILOT_FINALIZED' && 'Piloto finalizado'}
                  {record.production?.current_status.status == 'IN_DELIVERY' && 'Em entrega'}
                  {record.production?.current_status.status == 'RECEIVED' && 'Entregue'}
                  {record.production?.current_status.status == 'ON_APPROVED' && 'Em aprovação'}
                  {record.production?.current_status.status == 'APPROVED' && 'Aguardando solicitação de produção'}

                  {record.production_situation == 'STARTED' && 'Aguardando inicio'}
                  {record.production_situation == 'IN_PRODUCTION' && 'Em produção'}
                  {record.production_situation == 'IN_CUT' && 'Em corte'}
                  {record.production_situation == 'FINALIZED' && 'Finalizada'}
                  {/* {record.production_situation == 'IN_PILOT' && 'Produzindo piloto'} */}
                </Text>
              ),
            },
            {
              title: '',
              key: 'options',
              render: (text: any, record: Modeling) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={'middle'}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ['bottomCenter'],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default ModelingPageHome;
