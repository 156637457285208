import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import { FC, useState } from "react";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Type } from "../../classes/Type";
import TypeApi from "../../services/TypeApi";

interface TypeFormOptions {
  list?: Type[];
  type: string;
  loadItens: () => void;
  onSelect: (val?: any) => void;
}

const TypeForm: FC<TypeFormOptions> = ({
  list,
  type,
  onSelect,
  loadItens,
}: TypeFormOptions) => {
  const [form] = Form.useForm();
  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [addNewType, setAddNewType] = useState(false);
  const [sendNewType, setSendNewType] = useState(false);
  const [currentType, setCurrentType] = useState(0);

  const handlerType = (e: RadioChangeEvent) => {
    setCurrentType(e.target.value);
    onSelect(e.target.value);
  };

  const handlerSubmitType = (values: any) => {
    setSendNewType(false);

    values = {
      ...values,
      ...{
        name: values.typename,
        type: "MATERIAL",
        active: true,
      },
    };

    TypeApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        form.resetFields();
        setSendNewType(false);
        setAddNewType(false);
        loadItens();
      });
  };
  return (
    <Row gutter={[16, 16]}>
      {!!list && (
        <Col>
          <Radio.Group onChange={handlerType} defaultValue={currentType}>
            {list.map((item: Type, index: number) => (
              <Radio.Button value={item.id}>{item.name}</Radio.Button>
            ))}
          </Radio.Group>
        </Col>
      )}

      <Col>
        {!addNewType ? (
          <Button
            icon={<PlusSquareOutlined />}
            onClick={() => setAddNewType(true)}
          ></Button>
        ) : (
          <Form form={form} onFinish={handlerSubmitType} layout="inline">
            <Form.Item
              name="typename"
              rules={[{ required: true, message: "Tipo obrigatório!" }]}
            >
              <Input
                placeholder="Tipo"
                allowClear
                onPressEnter={(e) => form.submit()}
              />
            </Form.Item>
            <Button icon={<PlusSquareOutlined />} onClick={() => form.submit()}>
              Incluir
            </Button>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default TypeForm;
