import {
  Breadcrumb,
  Space,
  Typography,
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Radio,
  Table,
  Button,
  Divider,
  Checkbox,
  message,
} from 'antd';
import {
  PlusSquareOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { useState, useEffect } from 'react';
import { RouteProps, useHistory, withRouter } from 'react-router-dom';
import { BaseDict } from '../../../../../../classes/Util';
import APP_ROUTE from '../../../../../../routes/routes';
import ProductApi from '../../../../../../services/ProductApi';
import ProductAttributeApi from '../../../../../../services/ProductAttributeApi';
import ProductMeasureApi from '../../../../../../services/ProductMeasureApi';
import {
  CurrencyFormatter,
  CurrencyParser,
} from '../../../../../../utils/currencyFormater';
import { ProductAttribute } from '../../../../../../classes/ProductAttribute';
import { ProductMeasure } from '../../../../../../classes/Measure';

const ProductData = (props: RouteProps) => {
  const history = useHistory();
  const [formdata] = Form.useForm();

  const [loadingMeasure, setLoadingMeasure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newProductAttribute, setNewProductAttribute] = useState(false);
  const [newMeasure, setNewMeasure] = useState(false);

  const [productAttributes, setProductAttributes] =
    useState<ProductAttribute>();
  const [listMeasure, setListMeasure] = useState<ProductMeasure[]>([]);
  const [filteredList, setFilteredList] = useState<ProductMeasure[]>([]);

  const [currentMeasure, setCurrentMeasure] = useState<'NUMBER' | 'LETTER'>(
    'NUMBER'
  );
  const [productTypeNew, setProductTypeNew] = useState('');

  useEffect(() => {
    setTimeout(() => {
      loadMeasure();
      loadProductAttributes();
    }, 300);
  }, []);

  useEffect(() => {
    let newList = listMeasure.filter((item) => {
      return item.unity == currentMeasure;
    });

    console.log(newList);

    setFilteredList(newList);
  }, [currentMeasure]);

  const handlerSubmit = (values: any) => {
    ProductApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        history.replace(APP_ROUTE.PRODUCT('data', resp?.data.product_id));
      })
      .catch((e) => {
        message.error(e.responseo.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function loadMeasure() {
    setLoadingMeasure(true);
    ProductMeasureApi.list()
      .then((resp) => {
        setListMeasure(resp?.data);
      })
      .finally(() => {
        setLoadingMeasure(false);
      });
  }

  function loadProductAttributes() {
    ProductAttributeApi.list().then((resp) => {
      setProductAttributes(resp?.data);
    });
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Produtos</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          Medias
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: '100%' }}
        wrap
      >
        <div className="item">
          <div className="head-item">
            <Typography.Title level={5}>Fotos e videos</Typography.Title>
          </div>
          <div className="body-item">
            <Form form={formdata} layout={'vertical'} onFinish={handlerSubmit}>
              <Row gutter={[16, 16]} justify={'space-between'}>
                <Col span={12}>
                  <Form.Item name={'description'} label={'Descrição da peça'}>
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={'information'} label={'Informações da peça'}>
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={'space-between'}>
                <Col span={8}>
                  <Form.Item name={'price'} label={'Preço'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'cost'} label={'Custo'}>
                    <InputNumber
                      style={{
                        width: '100%',
                        marginRight: '1rem',
                      }}
                      formatter={CurrencyFormatter}
                      parser={CurrencyParser}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'margin'} label={'Margem'}>
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label={'Tamanho'} name="scale_size">
                <Radio.Group
                  defaultValue="NUMBER"
                  buttonStyle="solid"
                  onChange={(e) => {
                    setCurrentMeasure(e.target.value);
                  }}
                >
                  <Radio.Button value="NUMBER">Números</Radio.Button>
                  <Radio.Button value="LETTER">Letras</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Space direction="vertical">
                <div>
                  <Typography.Title level={5}>
                    Tabela de medidas
                  </Typography.Title>
                  <Typography.Text>
                    Preencher somente os campos referentes a peça
                  </Typography.Text>
                </div>
                <Form.List name={'measure'}>
                  {(fields) => (
                    <>
                      <Table
                        size="small"
                        className="measure-table"
                        pagination={false}
                        loading={loadingMeasure}
                        rowKey={(record) => record.id || 0}
                        columns={[
                          {
                            title: 'Tamanho',
                            key: 'size',
                            render: (
                              text: any,
                              record: ProductMeasure,
                              index: number
                            ) => (
                              <>
                                <Form.Item
                                  name={[index, 'label']}
                                  initialValue={record.label}
                                >
                                  <Input
                                    value={record.label}
                                    readOnly
                                    disabled
                                  />
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: 'Preço',
                            key: 'value',
                            render: (
                              text: any,
                              record: ProductMeasure,
                              index: number
                            ) => (
                              <>
                                <Form.Item
                                  name={[index, 'price']}
                                  initialValue={record.value}
                                >
                                  <InputNumber
                                    style={{
                                      width: '100%',
                                      marginRight: '1rem',
                                    }}
                                    value={record.value}
                                    formatter={CurrencyFormatter}
                                    parser={CurrencyParser}
                                  />
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: 'Estoque',
                            key: 'stock',
                            render: (
                              text: any,
                              record: ProductMeasure,
                              index: number
                            ) => (
                              <>
                                <Form.Item
                                  name={[index, 'stock']}
                                  initialValue={record.stock}
                                >
                                  <Input type="number" value={record.stock} />
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: 'Peso',
                            key: 'weight',
                            render: (
                              text: any,
                              record: ProductMeasure,
                              index: number
                            ) => (
                              <>
                                <Form.Item
                                  name={[index, 'weight']}
                                  initialValue={record.weight}
                                >
                                  <Input type="number" value={record.weight} />
                                </Form.Item>
                              </>
                            ),
                          },
                        ]}
                        dataSource={filteredList}
                      />
                    </>
                  )}
                </Form.List>
                <Row>
                  <Button
                    type={'dashed'}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setNewMeasure(true);
                    }}
                  >
                    Nova medida
                  </Button>
                </Row>
              </Space>
              <Divider />
              <Row>
                <Space direction="vertical">
                  <Form.Item label={'Cor'} name="color">
                    <Checkbox.Group
                      className="check-list"
                      options={productAttributes?.color.map(
                        (item: BaseDict) => ({
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    />
                  </Form.Item>
                  <Button
                    type={'dashed'}
                    icon={<PlusOutlined />}
                    onClick={() => [
                      setNewProductAttribute(true),
                      setProductTypeNew('COLOR'),
                    ]}
                  >
                    Adicionar cor
                  </Button>
                </Space>
              </Row>
              <Divider />
              <Row>
                <Space direction="vertical">
                  <Form.Item label={'Categoria'} name="category">
                    <Checkbox.Group
                      className="check-list"
                      options={productAttributes?.category.map(
                        (item: BaseDict) => ({
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    />
                  </Form.Item>
                  <Button
                    type={'dashed'}
                    icon={<PlusOutlined />}
                    onClick={() => [
                      setNewProductAttribute(true),
                      setProductTypeNew('CATEGORY'),
                    ]}
                  >
                    Adicionar categoria
                  </Button>
                </Space>
              </Row>
              <Divider />
              <Row>
                <Space direction="vertical">
                  <Form.Item label={'Estilo'} name="style">
                    <Checkbox.Group
                      className="check-list"
                      options={productAttributes?.style.map(
                        (item: BaseDict) => ({
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    />
                  </Form.Item>
                  <Button
                    type={'dashed'}
                    icon={<PlusOutlined />}
                    onClick={() => [
                      setNewProductAttribute(true),
                      setProductTypeNew('STYLE'),
                    ]}
                  >
                    Adicionar estilo
                  </Button>
                </Space>
              </Row>
              <Divider />
              <Row>
                <Space direction="vertical">
                  <Form.Item label={'Tipo'} name="type">
                    <Checkbox.Group
                      className="check-list"
                      options={productAttributes?.type.map(
                        (item: BaseDict) => ({
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    />
                  </Form.Item>
                  <Button
                    type={'dashed'}
                    icon={<PlusOutlined />}
                    onClick={() => [
                      setNewProductAttribute(true),
                      setProductTypeNew('TYPE'),
                    ]}
                  >
                    Adicionar tipo
                  </Button>
                </Space>
              </Row>
              <Divider />

              <Form.Item>
                <Button htmlType="submit" type="dashed" size="large">
                  Concluir cadastro
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Space>
    </>
  );
};

export default withRouter(ProductData);
