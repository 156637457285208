import {
  Form,
  message,
  RadioChangeEvent,
  Row,
  Button,
  Breadcrumb,
  Space,
  Col,
  Radio,
  Input,
  Select,
  Typography,
  Spin,
} from 'antd';
import {
  RouteProps,
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { ItemCityAndState } from '../../../../classes/StateAndCity';
import CommonApi from '../../../../services/CommonApi';
import PearsonApi from '../../../../services/PearsonApi';
import UtilApi from '../../../../services/UtilApi';
import { isValidCPF } from '../../../../utils/cpfValidation';
import { mask } from '../../../../utils/mask';
import { ProviderFormPageParams } from '../../pearson/form';
import AppModal from '../../../../components/AppModal';
import UserApi from '../../../../services/UserApi';
import { User } from '../../../../classes/User';
import { Bank } from '../../../../classes/Util';
import Loading from '../../../../components/Loading';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea } = Input;

const CreatorFormPage = (props: RouterProps) => {
  let params = useParams<ProviderFormPageParams>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  const [currentPearson, setCurrentPearson] = useState<User>();

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [states, setStates] = useState<ItemCityAndState[]>([]);
  const [city, setCities] = useState<ItemCityAndState[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);

  const [maskedValue, setMaskedValue] = useState('');

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadState();
    if (!!params.id) {
      setIsEdit(true);
      setTimeout(() => {
        UserApi.getItem(Number(params.id))
          .then((resp) => {
            setCurrentPearson(resp?.data);
          })
          .finally(() => {
            setLoader(false);
          });
      }, 500);
    }
  }, [params.id]);

  useEffect(() => {
    form.setFieldsValue(currentPearson);
    form.setFieldValue(
      'birth_date',
      moment(currentPearson?.birth_date).format('YYYY-MM-DD')
    );

    if (!!currentPearson?.state) {
      form.setFieldValue('state', currentPearson.state.initials);
      if (!!currentPearson.state.initials) {
        loadCity(currentPearson.state.initials);
      }
      form.setFieldValue('bank_name', {});
    }
  }, [currentPearson]);

  useEffect(() => {
    setLoader(true);
    loadBanks();
    loadState();
  }, []);

  const handlerChangeState = (option: any) => {
    const { value } = option;

    loadCity(value);
  };

  function handleChangeMask(event: any) {
    const { value } = event.target;
    console.log(mask(value));
    form.setFieldsValue({
      doc_number: mask(value),
    });
  }

  function loadBanks() {
    UtilApi.banks()
      .then((resp) => {
        setBanks(resp?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function loadState() {
    CommonApi.State()
      .then((resp) => {
        setStates(resp?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function loadCity(value?: any) {
    setLoadingCity(true);
    CommonApi.City(value)
      .then((resp) => {
        setCities(resp?.data);
      })
      .finally(() => {
        setLoadingCity(false);
        setTimeout(() => {
          if (!!currentPearson?.city) {
            form.setFieldValue('city', currentPearson.city.id);
          }
        }, 300);
      });
  }

  function getZipCode() {
    let zipcode = form.getFieldValue('zipcode');

    let clearZipCode = zipcode.replace(/[^a-zA-Z0-9]/g, '');

    UtilApi.ZipCode(clearZipCode)
      .then((resp) => {
        let response = resp?.data;

        form.setFieldsValue({
          address: response.logradouro ? response.logradouro : '',
          complement: response.complemento ? response.complemento : '',
          city: response.localidade ? response.localidade : '',
          state: response.uf ? response.uf : '',
          number: '',
        });
      })
      .catch((e) => {
        message.error('Cep não encontrado!');
      });
  }

  const handlerPass = () => {
    let r = Math.random().toString(36).slice(2, 10);
    form.setFieldsValue({
      password: r.toString(),
    });
  };

  const handlerSubmit = (values: any) => {
    console.log(values);
    setLoading(true);

    values = { ...values, ...{ profile: 'CREATOR' } };
    if (isEdit) {
      UserApi.update(values, Number(currentPearson?.id))
        .then((resp) => {
          message.success(resp.data.msg);
          setTimeout(() => {
            history.goBack();
          }, 300);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      UserApi.addnew(values)
        .then((resp) => {
          message.success(resp.data.msg);
          setTimeout(() => {
            history.goBack();
          }, 300);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function changeEmail(e: any) {
    console.log(e.target.value);
    if (form.getFieldValue('login')) {
      console.log('has');
    } else {
      console.log('not has');
    }
    console.log(!!form.getFieldValue('login'));
    if (form.getFieldValue('login') == '') {
      form.setFieldValue('login', e.target.value);
    }
  }

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
    // console.log(form.getFieldsError());
    // history.goBack()
  }

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />

      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? 'Atualizar' : 'Cadastrar'} Criador
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: '100%' }}
        wrap
      >
        {loader ? (
          <Loading />
        ) : (
          <Form
            style={{ width: '100%' }}
            layout={'vertical'}
            form={form}
            onFinish={handlerSubmit}
          >
            <Form.Item>
              <input type="hidden" name="profile" value="CREATOR" />
            </Form.Item>
            <Space
              size={[40, 40]}
              align="start"
              direction="vertical"
              style={{ width: '100%' }}
            >
              <div style={{ width: '100%' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Title level={2}>Cadastrar Criador</Title>
                  </Col>
                  <Col>
                    <Button
                      type="ghost"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                    >
                      {isEdit ? 'Atualizar' : 'Salvar'}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div style={{ width: '100%' }}>
                <Title level={5}>Dados do criador</Title>
                <Row gutter={[16, 16]} justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      label="Nome"
                      name={'name'}
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <Input placeholder="Nome" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={`CPF/CNPJ`}
                      name={'doc_number'}
                      rules={[
                        { required: true, message: 'Campo obrigatório' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let valid = isValidCPF(value);

                            if (!valid) {
                              return Promise.reject(
                                new Error(
                                  `O ${
                                    value.length > 14 ? `CNPJ` : `CPF`
                                  } é inválido`
                                )
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input onChange={handleChangeMask} value={maskedValue} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="birth_date" label="Data de nascimento:">
                      <Input type="date" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item
                      label={'Email'}
                      name="email"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <Input placeholder="E-mail" onChange={changeEmail} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Telefone" name="phone">
                      <Input placeholder="Telefone" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name={'zipcode'}
                      label={'CEP'}
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <Input type="text" placeholder={'_____-___'} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Estado"
                      name="state"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <Select
                        labelInValue
                        style={{ width: `100%` }}
                        onChange={handlerChangeState}
                        loading={loadingState}
                      >
                        {states.map((item: ItemCityAndState) => (
                          <Option key={item.id} value={item.initials}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Cidade"
                      name="city"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <Select
                        labelInValue
                        style={{ width: `100%` }}
                        loading={loadingState}
                      >
                        {city.map((item: ItemCityAndState) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="Endereço"
                      name="address"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <TextArea placeholder="Endereço" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div>
                <Title level={5}>Dados do bancários</Title>

                <Row gutter={[16, 16]} justify="space-between">
                  <Col span={6}>
                    <Form.Item
                      name={'bank_name'}
                      label={'Banco'}
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um banco',
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        showSearch
                        placeholder={'Banco'}
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={banks.map((item: Bank) => ({
                          label: item.name,
                          value: item.code,
                        }))}
                      ></Select>
                    </Form.Item>

                    {/* <Form.Item
                    label="Banco"
                    name="bank_name"
                    rules={[
                      { required: true, message: 'Campo é obrigatório!' },
                    ]}
                  >
                    <Input placeholder="Banco" />
                  </Form.Item> */}
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Agência"
                      name="bank_agency"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <Input placeholder="Agência" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Conta"
                      name="bank_account"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <Input placeholder="Conta" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Pix"
                      name="bank_pix"
                      rules={[
                        { required: true, message: 'Campo é obrigatório!' },
                      ]}
                    >
                      <Input placeholder="Pix" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {!isEdit && (
                <div>
                  <Title level={5}>Dados de acesso</Title>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Row gutter={[16, 16]}>
                        <Col>
                          <Form.Item name="password">
                            <Input.Password
                              placeholder="Senha"
                              type="password"
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col>
                          <Button type="dashed" onClick={handlerPass}>
                            Gerar senha
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </Space>
          </Form>
        )}
      </Space>
    </>
  );
};

export default withRouter(CreatorFormPage);
