import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Typography,
  Table,
  Radio,
  Image,
} from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { Modeling } from '../../../../../classes/Modeling';
import { Paginate } from '../../../../../classes/Paginate';
import APP_ROUTE from '../../../../../routes/routes';
import ModelingApi from '../../../../../services/ModelingApi';
import moment from 'moment';
import ProductionApi from '../../../../../services/ProductionApi';
import { Productions } from '../../../../../classes/Production';

const { Title, Text } = Typography;

const ProductSolicitations = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [list, setList] = useState<Paginate<Productions>>();

  const [filterType, setFilterType] = useState<'MODELING' | 'STOCK'>(
    'MODELING'
  );

  const [filter, setFilter] = useState({});


  useEffect(() => {
    onLoad();
  }, [filter]);


  function goDetail(item: Modeling) {
    history.push(APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(item.id));
  }

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);

    ProductionApi.list('creator', filter).then((resp) =>{
      setList(resp?.data);
    }).finally(() =>{
      setLoading(false);
    })
  }

  function goPayment(data: Productions) {
    if (data.payment) {
      window.open(data.payment.url, '_blank');
    }
  }

  function approve(data: Modeling, option: string) {
    ModelingApi.changeApprovation({ situation: option }, Number(data.id))
      .then((resp) => {
        message.success(resp.data.msg);
        setLoading(true);
        onLoad();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      });
  }

  const OptionsMenu = (record: Productions) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item
            onClick={() =>
              history.push(
                APP_ROUTE.CREATOR_PRODUCT_SOLICITATION_VIEW(record.id)
              )
            }
          >
            Ver
          </Menu.Item>

          <Menu.Item onClick={() => goPayment(record)}>Ver Cobrança</Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Peças produzidas`}
                prefix={'Qtd.'}
                value={955}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Peças vendidas`}
                prefix={'Qtd.'}
                value={955}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Peças a venda`}
                prefix={'Qtd.'}
                value={955}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Space direction="vertical">
            {/* <Button
              type="ghost"
              onClick={() => history.push(APP_ROUTE.CREATOR_PRODUCT_CREATE())}
            >
              Criar nova peça
            </Button> */}
            <Button
              type="ghost"
              onClick={() => history.push(APP_ROUTE.PRODUCT_CREATE('basic'))}
            >
              Estoque
            </Button>
          </Space>
        </Col>
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col>
          <Title level={2}>Solicitação de produtos</Title>
        </Col>
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="type">
              <Radio.Group defaultValue={filterType} buttonStyle="solid" onChange={(e) => { setFilter({...filter, ...{type:e.target.value}}) }}>
                <Radio.Button value="MODELING">Modelagem</Radio.Button>
                <Radio.Button value="PRODUCT">Estoque</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table 
          loading={loading}
          dataSource={list?.data}
          columns={[
            {
              title:'Produto Solicitado',
              dataIndex:'item.name',
              render:(text:any, record:Productions) =>(
                <Row>
                  <Col>
                    {!!record.item.media && (
                        <Image
                          width={50}
                          src={`${process.env.REACT_APP_API}/storage/${record.item.media.file}`}
                        />
                    )}
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <Text>{moment(record.created_at).format('DD/MM/YYYY')}</Text>
                      <Text strong>{record.item.name}</Text>
                    </Space>
                  </Col>
                </Row>
                
              )
            },
            {
              title: 'Tipo',
              dataIndex: 'type',
              render:(text:any, record:Productions) =>(
                <>
                  <Text>
                    {record.type == 'PILOT' && ('Piloto')}
                    {record.type == 'MARKETING' && ('Marketing')}
                    {record.type == 'REPOSITION' && ('Estoque')}
                    {record.type == 'PRODUCTION' && ('Produção')}
                  </Text>
                </>
              ),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              render:(text:any, record:Productions) =>(
                <>
                  <Text>
                    {record.current_production_status.status == 'CREATED' && ('Solicitado')}
                    {record.current_production_status.status == 'IN_PROGRESS' && ('Iniciado')}
                    {record.current_production_status.status == 'IN_PAYMENT' && ('Aguardando pagamento')}
                    {record.current_production_status.status == 'IN_PRODUCTION' && ('Em produção')}
                    {record.current_production_status.status == 'PRODUCTION_FINALIZED' && ('Produção finalizada')}
                    {record.current_production_status.status == 'ON_STOCK' && ('Em estoque')}

                    {record.current_production_status.status == "PAYED" && record.status == "IN_PROGRESS" && ('Em produção')}
                    {record.status == 'FINALIZED' && ('Produção finalizada')}
                    {record.status == 'IN_PROGRESS' && ('Em progresso')}
                  </Text>
                </>
              ),
            },
            {
              title:'Valor',
              dataIndex:'value',
              
            },
            {
              title:'',
              key: 'options',
              render: (text: any, record: Productions) => (
                <OptionsMenu {...record} />
              ),
            }
          ]}
        />
        {/* <Table
          columns={[
            {
              title: 'Produto solicitado',
              key: 'name',
              render: (text: any, record: Modeling) => (
                <Row>
                  <Col>
                    {!!record.media.reference && (
                      <Image
                        width={50}
                        src={`${process.env.REACT_APP_API}/storage/${record.media?.reference[0]?.file}`}
                      />
                    )}
                  </Col>
                  <Col>
                    <Space direction="vertical">
                      <Text>
                        {moment(record.created_at).format('DD/MM/YYYY')}
                      </Text>
                      <Text strong>{record.name}</Text>
                    </Space>
                  </Col>
                </Row>
              ),
            },
            {
              title: 'Tipo',
              key: 'type',
              render: (text: any, record: Modeling) => (
                <Text>
                  {filterType == 'MODELING' && 'Modelagem'}
                  {filterType == 'STOCK' && 'Estoque'}
                </Text>
              ),
            },
            {
              title: 'Status',
              key: 'value',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.status == 'CREATED' && 'Iniciado'}
                  {record.status == 'WAITING_BUDGET' && 'Aguardando pagamento'}
                  {record.status == 'IN_PROGRESS' && 'Em progresso'}
                  {record.status == 'IN_CHARGE' && 'Em produção'}
                  {record.status == 'IN_PAYMENT' && 'Em pagamento'}
                  {record.status == 'PAYED' && 'Pago'}
                </Text>
              ),
            },
            {
              title: 'Situação',
              key: 'situation',
              render: (text: any, record: Modeling) => (
                <Text>
                  {record.situation == 'CREATED' && 'Iniciado'}
                  {record.situation == 'ON_APPROVED' && 'Aguardando aprovação'}
                  {record.situation == 'REPPROVED' && 'Reprovada'}
                  {record.situation == 'APPROVED' && 'Aprovada'}
                </Text>
              ),
            },
            {
              title: '',
              key: 'options',
              render: (text: any, record: Modeling) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          dataSource={list?.data}
          size={'middle'}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ['bottomCenter'],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        /> */}
      </div>
    </>
  );
};

export default ProductSolicitations;
