import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select } from "antd";
import { FC, useEffect, useState } from "react";
import {SaveOutlined } from '@ant-design/icons';
import { ProductionItem, ProductionItemList } from "../../../classes/Production";
import { CurrencyFormatter, CurrencyParser } from "../../../utils/currencyFormater";
import { BaseDict } from "../../../classes/Util";
import { ProductMeasure } from "../../../classes/Measure";

interface Options{
    onSave:(values:any, index:any) => void;
    currentItem?:ProductionItem;
    currentIndex?:any;
    selectedColors:Array<number>;
    onOpen?:boolean;
    suggested_value?:number;
    colors:Array<BaseDict>;
    measure:Array<ProductMeasure>
}

const ProductionItemForm:FC<Options> = ({onSave,currentItem,currentIndex, selectedColors,suggested_value, colors, measure}:Options) =>{

    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() =>{
        form.setFieldsValue({production_itens:measure})
    },[measure]);

    useEffect(() =>{
        if(!!currentItem){
            console.log(currentItem);
            form.setFieldsValue({
                color:currentItem?.color,
                production_itens:currentItem?.production_itens
            });
            setIsEdit(true);
        }
        
        // if(!!currentItem){
            
            // 
        // }
    },[currentItem]);

    const addProductionItem = (values:any) =>{
        console.log(currentIndex);
        onSave(values, currentIndex);
    }

    const handleValue = (values:any,index:number) =>{
        let itens = form.getFieldValue('production_itens');
        itens[index].value = Number(suggested_value) * itens[index].size;
        
        form.setFieldsValue({ production_itens: itens})
    }

    return(
        <>
            <Form form={form} onFinish={addProductionItem} layout={'vertical'}>
                <Form.Item label={'Cor'} name={'color'} rules={
                    [
                        {
                            required:true,
                            message:'Campo obrigatório'
                        }
                    ]
                }>
                    <Select
                        labelInValue
                        style={{ width: 120 }}
                        options={colors?.filter((c:BaseDict, index:number) =>{
                            return !selectedColors.includes(c.id)
                        }).map((item: BaseDict) => ({
                            label: item.name,
                            value: item.id,
                        }))}
                    />
                </Form.Item>
                    <Form.List name="production_itens">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row key={key} gutter={[16,16]}>
                                        <Col>
                                            <Form.Item
                                                label="Tamanho"
                                                name={[name, 'label']}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Tamanho é obrigatório',
                                                },
                                                ]}
                                            >
                                                <Input readOnly={true} disabled={true}/>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item label={'Quantidade'} name={[name,'size']} rules={[
                                                {
                                                    required:true,
                                                    message:'Campo obrigatório',
                                                    pattern: new RegExp(/^[0-9]+$/)
                                                },
                                                () =>({
                                                    validator(_, value){
                                                        if(isNaN(value)){
                                                            return Promise.reject("Valor deve ser maior que 0");
                                                        }else if(Number(value) == 0){
                                                            return Promise.reject("Valor deve ser maior que 0");
                                                        }else{
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                   
                                                })
                                            ]}>
                                                <Input type="number" onChange={(e) => handleValue(e.target.value,name)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item label={'Valor'} name={[name,'value']}>
                                                <InputNumber
                                                style={{
                                                    width: '100%',
                                                    marginRight: '1rem',
                                                }}
                                                formatter={CurrencyFormatter}
                                                parser={CurrencyParser}
                                                readOnly={true}
                                                disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>

                <Button htmlType={'submit'} type={'primary'} className={'btn-orange'} icon={<SaveOutlined />}>{isEdit ? 'Salvar' : 'Incluir'}</Button>
            </Form>
        </>
    )
}

export default ProductionItemForm;