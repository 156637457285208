import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Table,
  Typography,
  Image,
  Tag,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  message,
  Drawer,
} from "antd";
import { useHistory } from "react-router-dom";

import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import APP_ROUTE from "../../../../../routes/routes";
import StoreApi from "../../../../../services/StoreApi";
import { Paginate } from "../../../../../classes/Paginate";
import { StoreDict } from "../../../../../classes/Store";
import moment from "moment";

const { Title, Text } = Typography;

const ShopPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingNewLink, setLoadingNewLink] = useState(false);

  const [store, setStore] = useState<Paginate<StoreDict>>();

  const [currentStore, setCurrentStore] = useState<StoreDict>();
  const [filter, setFilter] = useState({});

  const [newLink, setNewLink] = useState(false);

  useEffect(() => {
    onLoad();
  }, [filter]);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    StoreApi.list(filter)
      .then((resp) => {
        setStore(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function removeItem(item: StoreDict) {
    StoreApi.removeItem(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
  }

  function onPublish(item: StoreDict) {
    StoreApi.publish(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onLoad();
      });
  }

  const OptionsMenu = (record: StoreDict) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item
            onClick={() =>
              history.replace(APP_ROUTE.SHOP_PAGE_CREATE(record.id))
            }
          >
            Ver
          </Menu.Item>
          <Menu.Item>Gerar boleto</Menu.Item>

          <Menu.Item
            onClick={() => [setNewLink(true), setCurrentStore(record)]}
          >
            Gerar link temporário
          </Menu.Item>

          <Menu.Item onClick={() => onPublish(record)}>
            {record.publish ? "Despublicar" : "Publicar"}
          </Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const handlerSubmit = (values: any) => {
    console.log(values);
    setLoadingNewLink(true);
    StoreApi.generateTemporaryUrl(Number(currentStore?.id), values)
      .then((resp) => {
        message.success(resp?.data.msg);
        form.resetFields();
        setNewLink(false);
        onLoad();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoadingNewLink(false);
      });
  };

  return (
    <>
      <Drawer
        title="Gerar link para validação"
        open={newLink}
        destroyOnClose={true}
        closable={true}
        onClose={() => setNewLink(false)}
      >
        <Text>
          Será gerado um link para acesso temporário do criador, para fins de
          validação da página.
          <strong>
            Será enviado um e-mail para o criador, para que ele possa validar a
            loja!
          </strong>
        </Text>

        <Form form={form} onFinish={handlerSubmit} layout={"vertical"}>
          <Form.Item name="days" label={"Quantidade de dias"}>
            <Input type="number" />
          </Form.Item>

          <Form.Item>
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  type="default"
                  htmlType="submit"
                  loading={loadingNewLink}
                >
                  Gerar link
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  htmlType="reset"
                  danger
                  disabled={loadingNewLink}
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Drawer>

      <Row justify="end">
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.SHOP_CREATE())}
          >
            CRIAR LOJA
          </Button>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="page-title" level={2}>
            Lojas
          </Title>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          dataSource={store?.data}
          columns={[
            {
              title: "Criador",
              key: "creator",
              render: (text: any, record: StoreDict) => (
                <>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Image
                        width={50}
                        src={`${process.env.REACT_APP_API}/storage/${record.avatar}`}
                      />
                    </Col>
                    <Col>
                      <Title level={5}>{record.user_name}</Title>
                      <Text>
                        {moment(record.created_at).format("DD/MM/YYYY")}
                      </Text>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              title: "Slug",
              render: (text: any, record: StoreDict) => <>{record.slug}</>,
            },
            {
              title: "Link de aprovação",
              key: "link",
              render: (text: any, record: StoreDict) => (
                <>
                  <Space direction="vertical">
                    <a
                      href={`${process.env.REACT_APP_API}/store/temp/${record.code}`}
                      target="_blank"
                    >{`${process.env.REACT_APP_API}/store/temp/${record.code}`}</a>
                    <Text>
                      Expira em:
                      {moment(record.expires_link).format("DD/MM/YYYY")}
                    </Text>
                  </Space>
                </>
              ),
            },
            {
              title: "TIPO",
              render: (text: any, record: StoreDict) => (
                <>
                  {record.type == "TAG" && "Etiqueta"}
                  {record.type == "PAGE" && "Página"}
                </>
              ),
            },
            {
              title: "Status da loja",
              render: (text: any, record: StoreDict) => (
                <>
                  {record.situation == "ON_APPROVED" && (
                    <Tag color="#939393">EM APROVAÇÃO</Tag>
                  )}
                  {record.situation == "APPROVED" && (
                    <Tag color="#00A838">ATIVA</Tag>
                  )}
                  {record.situation == "DISAPPROVED" && (
                    <Tag color="#730000">Reprovada</Tag>
                  )}
                  {record.situation == "STARTED" && (
                    <Tag color="#730000">Iniciada</Tag>
                  )}
                </>
              ),
            },
            {
              title: "",
              key: "actions",
              render: (text: any, record: StoreDict) => (
                <OptionsMenu {...record} />
              ),
            },
          ]}
          size={"middle"}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: store?.per_page,
            current: store?.current_page,
            total: store?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default ShopPage;
