import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Row,
  Skeleton,
  Statistic,
  Typography,
  Form,
  Input,
  Table,
  Tag,
  Popconfirm,
  message,
  Dropdown,
  Menu,
  Space,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Paginate } from "../../../../classes/Paginate";
import { Pearson } from "../../../../classes/Pearson";
import { Stats } from "../../../../classes/Stats";
import { Type } from "../../../../classes/Type";
import PearsonApi from "../../../../services/PearsonApi";
import TypeApi from "../../../../services/TypeApi";
import {
  RouteProps,
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import APP_ROUTE from "../../../../routes/routes";
import React from "react";
import { User } from "../../../../classes/User";
import UserApi from "../../../../services/UserApi";

const { Title, Text } = Typography;

export interface PersonPageType {
  type?: string;
}

const ColaboratorPage = (props: RouteProps) => {
  const history = useHistory();
  const params = useParams<PersonPageType>();

  const [form] = Form.useForm();

  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [loadingPerson, setLoadingPearson] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const [stats, setStats] = useState<Stats>();
  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [dataPearson, setDataPearson] = useState<Paginate<User>>();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (!!params && !!params.type) {
      setTimeout(() => {
        loadAll(params.type?.toString().toUpperCase());
      }, 200);
    }
  }, [params.type]);

  function loadAll(option?: string) {
    //setType(option?.toString());
    onStats(option);

    if (!!option) {
      setFilter({ ...filter, ...{ type: option } });
    }
  }

  useEffect(() => {
    onLoad();
    onStats();
  }, [filter]);

  function onStats(type?: string) {
    setLoadingStats(true);
    UserApi.stats("COLABORATOR")
      .then((resp) => {
        setStats(resp?.data);
      })
      .finally(() => {
        setLoadingStats(false);
      });
  }

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function goDetail(item: User) {
    history.push(APP_ROUTE.COLABORATOR_VIEW(item.id));
  }

  function removeItem(item: User) {
    UserApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        // onStats();
        onLoad();
      });
    console.log(item);
  }

  const OptionsMenu = (record: User) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  function onLoad() {
    setLoadingPearson(true);
    UserApi.list({ ...filter, ...{ profile: "COLABORATOR" } })
      .then((resp) => {
        setDataPearson(resp?.data);
      })
      .catch((e) => {
        console.log(e.response);
      })
      .finally(() => {
        setLoadingPearson(false);
      });
  }

  const columns = [
    {
      title: "Colaborador",
      dataIndex: "name",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "doc_number",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
    },
    {
      title: "E-mail",
      key: "email",
      render: (text: any, record: User) => (
        <a href={`mailto:${record.email}`}>{record.email}</a>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (text: any, record: User) => <OptionsMenu {...record} />,
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Colaborador</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2}>Colaborador</Title>

      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Colaboradores ativos`}
                prefix={"Qtd."}
                value={stats?.active || 0}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Colaboradores inativos`}
                prefix={"Qtd."}
                value={stats?.inactive || 0}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() =>
              history.push(APP_ROUTE.COLABORATOR_CREATE(params.type))
            }
          >
            Cadastrar Colaborador
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify="space-between">
        <Col>
          {loadingTypes ? (
            <Skeleton />
          ) : (
            <>
              {!!dataTypes && (
                <Row gutter={[16, 16]}>
                  {dataTypes.map((item: Type, index: number) => (
                    <Col>
                      <Button
                        onClick={() =>
                          setFilter({ ...filter, ...{ type_id: item.id } })
                        }
                      >
                        {item.name}
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={columns}
          dataSource={dataPearson?.data}
          size={"middle"}
          loading={loadingPerson}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: dataPearson?.per_page,
            current: dataPearson?.current_page,
            total: dataPearson?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default withRouter(ColaboratorPage);
