import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Typography,
  Image,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import APP_ROUTE from '../../../../routes/routes';
import ProductApi from '../../../../services/ProductApi';
import { Product, StockStats } from '../../../../classes/Product';
import moment from 'moment';
import { ProductMeasure } from '../../../../classes/Measure';
import { Paginate } from '../../../../classes/Paginate';

const { Text, Title } = Typography;

const PageStock = () => {
  const history = useHistory();
  const params = useParams<{ view: string }>();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [list, setList] = useState<Paginate<Product>>();

  const [stats, setStats] = useState<StockStats>();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
    getStats();
  }, [filter]);

  useEffect(() => {
    setFilter({ ...filter, ...{ stock: true } });
  }, []);

  const handlerFilter = (values: Product) => {
    setFilter({ ...filter, ...values });
  };

  function getStats(){
    setLoadingStats(true);
    ProductApi.stockStats('stock').then((resp) =>{
      setStats(resp?.data)
    }).finally(() =>{
      setLoadingStats(false);
    });
  }

  function onLoad() {
    setLoading(true);

    ProductApi.list(filter, 'admin')
      .then((resp) => {
        console.log(resp?.data)
        setList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function changeStatus(productId: number) {
    ProductApi.enable(Number(productId)).then((resp) => {
      message.success(resp?.data.msg);
    });
  }

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Peças em estoque`}
                prefix={'Qtd.'}
                value={stats?.in_stock}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Peças esgotadas`}
                prefix={'Qtd.'}
                value={stats?.zero_stock}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Peças desativadas`}
                prefix={'Qtd.'}
                value={stats?.inactive}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.PRODUCT_CREATE())}
          >
            NOVO PRODUTO
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col>
          <Title level={2}>Estoque</Title>
        </Col>
        <Col>
          <Form layout={'inline'} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={'Mostrar deletados'}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: 'Produto',
              key: 'name',
              render: (text: any, record: Product) => (
                <Row gutter={[16, 16]} align={'middle'}>
                  <Col>
                    <Image
                      width={60}
                      src={`${process.env.REACT_APP_API}/storage/${record.media?.file}`}
                    />
                  </Col>
                  <Col>
                    <Text>
                      {record.name} <br /> {record.sku}
                    </Text>
                  </Col>
                </Row>
              ),
            },
            {
              title: 'Cor',
              key: 'created_at',
              render: (text: any, record: Product) => (
                <Text>{record.color?.name}</Text>
              ),
            },
            {
              title: 'Preço',
              dataIndex: 'value',
              render: (text: any, record: Product) => (
                <Text>R$ {record.price}</Text>
              ),
            },
            {
              title: 'Estoque',
              dataIndex: 'stock',
              render: (text: any, record: Product) => (
                <>
                  <Row gutter={[10, 10]}>
                    {record.measure?.map(
                      (item: ProductMeasure, index: number) => (
                        <Col key={index}>
                          <Text>
                            {item.label} <br /> {item.stock}
                          </Text>
                        </Col>
                      )
                    )}
                    <Button
                      type="text"
                      onClick={() => {
                        history.push(APP_ROUTE.PRODUCT_STOCK(record.id));
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Row>
                </>
              ),
            },
            {
              title: 'Status no site',
              dataIndex: 'status',
              render: (text: any, record: Product) => (
                <>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Form form={form}>
                        <Form.Item label={'Ativo'} name="active">
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            size={'small'}
                            onChange={(val: boolean) => {
                              changeStatus(Number(record?.id));
                            }}
                            defaultChecked={record.active}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
          dataSource={list?.data}
          size={'middle'}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ['bottomCenter'],
            pageSize: list?.per_page,
            current: list?.current_page,
            total: list?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default PageStock;
