import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Col,
  Form,
  Row,
  Typography,
  Button,
  Space,
  Image,
  Input,
  InputNumber,
  message,
  Skeleton,
  List,
  Card,
  Popconfirm,
  Table,
} from 'antd';
import {
  PlusOutlined,
  MinusSquareOutlined,
  CalculatorOutlined,
  DeleteOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import { FileListItem } from '../../../../classes/Util';
import AppModal from '../../../../components/AppModal';
import {
  CurrencyFormatter,
  CurrencyParser,
} from '../../../../utils/currencyFormater';
import CustomUploadFiles from '../../../../components/CustomUploadFiles';
import ModelingApi from '../../../../services/ModelingApi';
import modal from 'antd/lib/modal';
import { Modeling, MaterialItens, PearsonItem } from '../../../../classes/Modeling';
import APP_ROUTE from '../../../../routes/routes';

const { TextArea } = Input;
const { Title, Text } = Typography;

const CreatorProductForm = () => {
  const history = useHistory();

  let params = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState('');

  const [fileList, setFileList] = useState<FileListItem[]>([]);
  const [fileCuts, setFileCuts] = useState<FileListItem[]>([]);
  const [materialList, setMaterialList] = useState<MaterialItens[]>([]);
  const [pearsonList, setPearonsList] = useState<PearsonItem[]>([]);

  const [modeling, setModeling] = useState<Modeling>();

  const [isFileListZero, setIsFileListZero] = useState(false);

  const [currentCreator, setCurrentCreator] = useState<any>();

  const [totalValue, setTotalValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);
      setIsLoading(true);
      ModelingApi.getItem(Number(params.id), 'creator')
        .then((resp) => {
          form.setFieldsValue(resp?.data);
          setFileList(resp?.data.media.reference);
          setFileCuts(resp?.data.media.cut);
          setTotalValue(resp?.data.value);
          setMaterialList(resp?.data.material);
          setPearonsList(resp?.data.pearsons);
          if (resp?.data.status != 'CREATED') {
            setReadOnly(true);
          }

          setModeling(resp?.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params.id]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  const handlerSubmit = (values: any) => {
    setLoading(true);
    setIsFileListZero(false);
    values = {
      ...values,
      ...{
        reference: fileList,
      },
    };

    if (fileList.length == 0) {
      setIsFileListZero(true);
      message.error('É obrigatório anexar as referências!');
      setLoading(false);
    } else {
      if (isEdit) {
        ModelingApi.update(values, Number(params.id), 'creator')
          .then((resp) => {
            modal.confirm({
              title: resp.data.msg,
              icon: <CheckOutlined />,
              content:
                'Voce receberá um e-mail com o link para efetuar o pagamento. A produção será iniciada após o envio do comprovante. O custo de produção da peça pode variar de acordo com o valor e estoque dos insumos',
              okText: 'Ok',
              onOk: () => {
               history.push(APP_ROUTE.CREATOR_PRODUCT_CREATE());
              },
            });
          })
          .catch((e) => {
            message.error(e.response.data.msg);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        ModelingApi.addnew(values, 'creator')
          .then((resp) => {
            modal.confirm({
              title: resp.data.msg,
              icon: <CheckOutlined />,
              content:
                'Voce receberá um e-mail com o link para efetuar o pagamento. A produção será iniciada após o envio do comprovante. O custo de produção da peça pode variar de acordo com o valor e estoque dos insumos',
              okText: 'Ok',
              onOk: () => {
                history.push(APP_ROUTE.CREATOR_PRODUCT_CREATE());
              },
            });
          })
          .catch((e) => {
            message.error(e.response.data.msg);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handlerAddNewFile = (value: any) => {
    setFileList([...fileList, value]);
  };

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />
      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? 'Atualizar peça' : 'Cadastrar nova peça'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="space-between" align="middle">
        <Title level={2}>
          {isEdit ? 'Atualizar peça' : 'Cadastrar nova peça'}
        </Title>
      </Row>
      {!isLoading && (
        <Space
          size={[40, 40]}
          direction="vertical"
          align="start"
          style={{ width: '100%' }}
          wrap
        >
          <Form
            style={{ width: '100%' }}
            layout={'vertical'}
            form={form}
            onFinish={handlerSubmit}
          >
            <Space
              size={[40, 40]}
              align="start"
              direction="vertical"
              style={{ width: '100%' }}
            >
              <div style={{ width: '100%' }}>
                <Form.Item
                  name="name"
                  label={'Nome'}
                  rules={[{ required: true, message: 'Nome obrigatório!' }]}
                >
                  <Input readOnly={readOnly} disabled={readOnly} />
                </Form.Item>

                <Form.Item
                  name="description"
                  label={'Descritivo detalhado'}
                  rules={[
                    { required: true, message: 'Descrição obrigatório!' },
                  ]}
                >
                  <TextArea readOnly={readOnly} disabled={readOnly} />
                </Form.Item>
              </div>

            

              <div style={{ width: '100%' }}>
                <Title level={4}>Proposta</Title>
                {!readOnly && (
                  <>
                    <small>(PNG, JPG ou PDF)</small>
                    {isFileListZero && (
                      <Text type="danger">
                        Os arquivos de referências, são obrigatórios
                      </Text>
                    )}
                    <CustomUploadFiles
                      list={fileList}
                      reOrder={() => {}}
                      onFile={handlerAddNewFile}
                      path={'reference'}
                      removeFilePath={'modeling/remove-media'}
                    />
                  </>
                )}

                {isEdit && (
                  <>
                    <List
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 4,
                      }}
                      dataSource={fileList}
                      renderItem={(item) => (
                        <List.Item>
                          <Card
                            title={item.original_name}
                            style={{ width: 300 }}
                            actions={[
                              <>
                                {!readOnly ? (
                                  <Popconfirm
                                    title="Remover arquivo？"
                                    okText="Sim"
                                    cancelText="Não"
                                  >
                                    <Button
                                      icon={<DeleteOutlined />}
                                      type={'ghost'}
                                    ></Button>
                                  </Popconfirm>
                                ) : (
                                  <></>
                                )}
                              </>,
                            ]}
                          >
                            <Image
                              width={200}
                              src={`${process.env.REACT_APP_API}/storage/${item.file}`}
                            />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </div>

              {fileCuts.length > 0 && (
                <div style={{ width: '100%' }}>
                  <Title level={4}>Arquivos Cortes</Title>
                  {isEdit && (
                    <>
                      <List
                        size="large"
                        bordered
                        dataSource={fileCuts}
                        renderItem={(item) => (
                          <List.Item
                            title={item.original_name}
                            style={{ width: '100%' }}
                          >
                            <Text strong>
                              <a
                                href={`${process.env.REACT_APP_API}/storage/${item.file}`}
                                target="_blank"
                              >
                                {item.original_name
                                  ? item.original_name
                                  : item.file}
                              </a>
                            </Text>
                          </List.Item>
                        )}
                      />
                    </>
                  )}
                </div>
              )}
              {pearsonList.length > 0  && (
                <>
                  <Title level={5}>Profissinais envolvidos</Title>
                  <Table 
                    dataSource={pearsonList}
                    pagination={false}
                    columns={[
                      {
                        title:'Profissional',
                        dataIndex:'type'
                      },
                      {
                        title:'Valor',
                        dataIndex:'value'
                      },
                    ]}
                    summary={(pageData) => {
                      let total = 0;
                      pageData.forEach((item) =>{
                        total += item.value
                      })

                      return(
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>
                            {total}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )
                    }}
                  />
                  </>
              )} 

              {materialList.length > 0  && (
                <>
                  <Title level={5}>Materiais utilizados</Title>
                  <Table 
                    dataSource={materialList}
                    pagination={false}
                    columns={[
                      {
                        title:'Material',
                        key:'material',
                        render:(text:any, record:MaterialItens) =>(
                          <Text>{record.info.label}</Text>
                        )
                      },
                      {
                        title:'Valor unitário',
                        key:'unit_value',
                        render:(text:any, record:MaterialItens) =>(
                          <Text>R$ {record.info.value}</Text>
                        )
                      },
                      {
                        title:'Quantidade',
                        key:'size',
                        render:(text:any, record:MaterialItens) =>(
                          <Text>{record.size}</Text>
                        )
                      },
                      {
                        title:'Valor',
                        key:'total_value',
                        render:(text:any, record:MaterialItens) =>(
                          <Text>R$ {record.value}</Text>
                        )
                      }
                    ]}
                    summary={(pageData) => {
                      let totalSimple = 0;
                      let globalTotal = 0;
                      let totaItens = 0;
                      pageData.forEach((item) =>{
                        totalSimple += item.info.value;
                        totaItens += item.size;
                        globalTotal += item.value;
                      })

                      return(
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>{totalSimple}</Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>{totaItens}</Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>{globalTotal}</Table.Summary.Cell>
                        </Table.Summary.Row>
                      )
                    }}
                  />
                  </>
              )} 

                {modeling?.payment && (
                <div style={{ width: '100%' }}>
                  <Title level={4}>Cobrança</Title>


                  <p><strong>Total da modelagem:</strong> R${modeling.value}</p>
                  <p><strong>Entrega({modeling.shipping_type}) piloto:</strong> R${modeling.shipping_value}</p>
                  <p><strong>Total:</strong> R${modeling.value + modeling.shipping_value}</p>

                  <Text>
                    <strong>Situação:</strong>
                    {modeling.payment.status == 'pending' &&
                      'Aguardando pagamento'}
                    {modeling.payment.status == 'paid' && 'Pago'}
                  </Text>

                  <Button
                    type="link"
                    onClick={() => {
                      window.open(modeling.payment?.url, '_blank');
                    }}
                  >
                    {' '}
                    Ver cobrança
                  </Button>
                </div>
              )}

              {/* {modeling?.pearsons?.map((item))} */}

              <Form.Item>
                {!readOnly && (
                  <Button
                    type="ghost"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    {isEdit ? 'Atualizar' : 'Salvar'}
                  </Button>
                )}
              </Form.Item>
            </Space>
          </Form>
        </Space>
      )}
    </>
  );
};

export default CreatorProductForm;
