import BaseApi from './BaseApi';

export default {
  stats: (type?: any) => {
    return BaseApi.get(`/admin/user`, {
      params: {
        stats: true,
        profile: type,
      },
    });
  },
  search: (text?: any, profile?: string) => {
    let params = {
      term: text,
    };

    if (!!profile) {
      params = { ...params, ...{ profile: profile } };
    }
    return BaseApi.get('/admin/user/utils/search-item', {
      params: params,
    });
  },
  searchText: (filter?: any) => {
    return BaseApi.get('/admin/user/utils/search-item', {
      params: filter,
    });
  },
  list: (filter?: any) => {
    return BaseApi.get(`/admin/user`, { params: filter });
  },
  addnew: (body: any) => {
    return BaseApi.post('/admin/user', body);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`/admin/user/${id}`, body);
  },
  updateProfile: (body: any) => {
    return BaseApi.put('/auth/update', body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`/admin/user/${id}`);
  },
  getItem: (id: number) => {
    return BaseApi.get(`/admin/user/${id}`);
  },
};
