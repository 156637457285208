import { Space, Typography } from "antd";
import { FC } from "react";

import "./style.scss";

interface Options {
  title: string;
  linePosition?: "VERTICAL" | "HORIZONTAL";
  highlight?: { symbol: string; value: string };
  line: Array<{ symbol: string; value: string }>;
}

const FellaStatisct: FC<Options> = ({
  title,
  highlight,
  linePosition,
  line,
}: Options) => {
  return (
    <>
      <Space
        direction="vertical"
        size={[10, 10]}
        align="start"
        style={{ width: "100%" }}
        className="fella-statistic"
        wrap
      >
        <Typography.Title level={5} className="title">
          {title}
        </Typography.Title>
        {highlight && (
          <p className="highlight">
            <small>{highlight.symbol}</small>
            {highlight.value}
          </p>
        )}
        <div
          className={`lines ${
            linePosition == "HORIZONTAL" ? "horizontal" : "vertical"
          }`}
        >
          {line.map((item, index: number) => (
            <p>
              <small>{item.symbol}</small>
              {item.value}
            </p>
          ))}
        </div>
      </Space>
    </>
  );
};

//const Divider:FC<DividerOptions> = ({color, height}: DividerOptions) =>{
export default FellaStatisct;
