import {
  createContext,
  FunctionComponent,
  useContext,
  useState,
  PropsWithChildren,
  FC,
  useEffect,
} from 'react';
import {
  Alert,
  Avatar,
  Button,
  Col,
  Layout,
  Menu,
  message,
  Row,
  Typography,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { NavLink, useHistory } from 'react-router-dom';
import { User } from '../classes/User';

import { ContextProps } from './context-props';
import Sider from 'antd/lib/layout/Sider';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import AuthApi from '../services/AuthApi';
import APP_ROUTE from '../routes/routes';
import AppHeader from '../components/AppHeader';
import React from 'react';
import AppMenu from '../components/appmenu';

const { Title, Text } = Typography;

const layoutContext = createContext({} as ContextProps);

export const useAppContext = (): ContextProps => {
  return useContext(layoutContext);
};

function useProvideContext(): ContextProps {
  const history = useHistory();
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(false);
  const [closeMenu, setCloseMenu] = useState(false);
  const [user, setUser] = useState<User>();
  const [name, setUserName] = useState('');
  const [updateUser, setOnUpdateUser] = useState(false);

  useEffect(() => {
    window.onresize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth <= 1050) {
        setCloseMenu(true);
      }
    };
  }, [window.onresize]);

  useEffect(() =>{
    if(updateUser === true){
      setUserData();
    }
  },[updateUser])

  useEffect(() => {
    if(AuthApi.getToken() != "" && AuthApi.isLoggedIn()){
      setUserData();
    }
  }, [AuthApi.isLoggedIn()]);

  function setUserData(){
    AuthApi.Me()
    .then((resp) => {
      AuthApi.setUserData(resp?.data);
      const currentUser = AuthApi.getUserData() as User;
      
      setIsLoggedUser(AuthApi.isLoggedIn());

      setUser(currentUser);
      let name = currentUser?.name.split(' ');
      if (name) {
        setUserName(`${name[0]} <br /><strong>${name[1]}</strong>`);
      }
    })
    .catch((e) => {
      if (e.response.status == 401) {
       AuthApi.logout();
      }
    })
    .finally(() => {
      setOnUpdateUser(false);
    });
  }

  return {
    isLoggedUser,
    user,
    name,
    closeMenu,
    updateUser,
    setIsLoggedUser,
    setUser,
    setUserName,
    setCloseMenu,
    setOnUpdateUser
  };
}

const ProvideLayout: FC<PropsWithChildren> = ({ children }) => {
  const layout: ContextProps = useProvideContext();
  const [name, setUsername] = useState('');

  useEffect(() => {
    let n = layout.user?.name.split(' ');
    if (n) {
      console.log(n[0]);
      setUsername(`${n[0]} <br /><strong>${n[1]}</strong>`);
    }
    //
  }, []);

  return (
    <layoutContext.Provider value={layout}>
      <Layout
        style={{ minHeight: '100vh' }}
        className={layout.isLoggedUser ? 'logged' : 'no-logged'}
        hasSider
      >
        {layout.isLoggedUser && <AppHeader />}
        {layout.isLoggedUser ? (
          <>
            <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                width: 200,
              }}
              breakpoint="lg"
              collapsed={layout.closeMenu}
            >
              <div className="wrapper-item-sider">
                <h1>
                  <img src="/imgs/brand.svg" />
                </h1>
              </div>
              {layout.user?.profile == 'CREATOR' && (
                <div className="wrapper-item-sider user">
                  <Row align="middle" gutter={[10, 10]}>
                    <Col>
                      <Avatar size={60} icon={<UserOutlined />} />
                    </Col>
                    <Col>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: layout.name || '',
                        }}
                      ></p>
                    </Col>
                  </Row>
                </div>
              )}
              <AppMenu />
            </Sider>

            <Content style={{ margin: '24px 16px 0', marginLeft: 230 }}>
              <div
                className="site-layout-background"
                style={{
                  padding: 24,
                  minHeight: 360,
                  marginTop: 80,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {children}
              </div>
            </Content>
          </>
        ) : (
          <Content style={{ margin: '24px 16px 0', marginLeft: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              {children}
            </div>
          </Content>
        )}
      </Layout>
    </layoutContext.Provider>
  );
};

export default ProvideLayout;
