import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Typography,
} from "antd";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { RouteProps, useHistory, withRouter } from "react-router-dom";
import APP_ROUTE from "../../../../routes/routes";
import { Paginate } from "../../../../classes/Paginate";
import { User } from "../../../../classes/User";
import UserApi from "../../../../services/UserApi";
import { Stats } from "../../../../classes/Stats";
import moment from "moment";

const { Title, Text } = Typography;

const IndicatorPage = (props: RouteProps) => {
  const history = useHistory();

  const [loadingUser, setLoadingUser] = useState(false);

  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const [stats, setStats] = useState<Stats>();

  const [listUser, setListUser] = useState<Paginate<User>>();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
    loadStats();
  }, [filter]);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoadingUser(true);
    UserApi.list({ ...filter, ...{ profile: "INDICATOR" } })
      .then((resp) => {
        setListUser(resp?.data);
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }

  function removeItem(item: User) {
    UserApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        // onStats();
        onLoad();
      });
    console.log(item);
  }

  function loadStats() {
    setLoadingStats(true);
    UserApi.stats("INDICATOR")
      .then((resp) => {
        setStats(resp?.data);
      })
      .finally(() => {
        setLoadingStats(false);
      });
  }

  function goDetail(item: User) {
    history.push(APP_ROUTE.INDICATOR_VIEW(item.id));
  }

  const OptionsMenu = (record: User) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Indicadores ativos`}
                prefix={"Qtd."}
                value={stats?.active || 0}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Indicadores inativos`}
                prefix={"Qtd."}
                value={stats?.inactive || 0}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.INDICATOR_CREATE())}
          >
            CADASTRAR INDICADOR
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>Indicadores</Title>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Indicador",
              dataIndex: "name",
            },
            {
              title: "Indicado por",
              key: "indicated_by",
              render: (text: any, record: User) => (
                <Text>{record.indicated?.name}</Text>
              ),
            },
            {
              title: "Aniversário",
              key: "birth_date",
              render: (text: any, record: User) => (
                <Text>{moment(record.birth_date).format("DD/MM")}</Text>
              ),
            },
            {
              title: "Cidade",
              key: "city",
              render: (text: any, record: User) => (
                <Text>{record.city?.name}</Text>
              ),
            },
            {
              title: "Instagram",
              key: "instagram",
              render: (text: any, record: User) => (
                <Text>{record.instagram}</Text>
              ),
            },
            {
              title: "",
              key: "options",
              render: (text: any, record: User) => <OptionsMenu {...record} />,
            },
          ]}
          dataSource={listUser?.data}
          size={"middle"}
          loading={loadingUser}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: listUser?.per_page,
            current: listUser?.current_page,
            total: listUser?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default withRouter(IndicatorPage);
