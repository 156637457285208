import { Button, Divider, Input, InputRef, message, Select, Space } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { ColorOption } from "../../classes/Color";
import ColorApi from "../../services/ColorApi";

export interface ColorSelectOptions {
  defaultColor?: { label: string; value: string };
  onColor: (val?: any) => void;
}

const ColorsSelect: FC<ColorSelectOptions> = ({
  defaultColor,
  onColor,
}: ColorSelectOptions) => {
  const inputRef = useRef<InputRef>(null);
  const [colors, setColors] = useState<ColorOption[]>([]);
  const [loadingColor, setLoadingColor] = useState(false);

  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [currentColor, setCurrentColor] = useState<any>();

  useEffect(() => {
    fetchColors();
  }, []);

  useEffect(() => {
    onColor(currentColor);
  }, [currentColor]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  const handleChange = (value: any) => {
    //setCurrentColor(selectOption);
    setCurrentColor(value.key);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setLoadingColor(true);
    ColorApi.addnew({
      name: name,
      color: color,
    })
      .then((resp) => {
        fetchColors();
      })
      .catch((e) => {
        message.error(e?.response.data.msg);
      })
      .finally(() => {
        setLoadingColor(false);
      });

    setName("");
    setColor("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  function fetchColors() {
    ColorApi.list({ list: true }).then((resp) => {
      setColors(resp?.data);
    });
  }

  return (
    <>
      <Select
        labelInValue
        style={{
          width: 300,
          backgroundColor: !!currentColor ? currentColor : "",
        }}
        value={defaultColor}
        defaultValue={defaultColor}
        placeholder="Cor"
        onSelect={handleChange}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder="Nome"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
              />
              <Input
                type="color"
                placeholder="Cor"
                ref={inputRef}
                value={color}
                onChange={onColorChange}
              />
              <Button
                type="text"
                loading={loadingColor}
                icon={<PlusOutlined />}
                onClick={addItem}
              >
                Nova
              </Button>
            </Space>
          </>
        )}
        options={colors.map((item: ColorOption) => ({
          label: item.name,
          value: item.color,
          key: item.id,
        }))}
      />
    </>
  );
};

export default ColorsSelect;
