import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
  Select,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { Coupon } from "../../../../classes/Coupon";
import { SearchResult } from "../../../../classes/Util";
import AppModal from "../../../../components/AppModal";
import CouponApi from "../../../../services/CouponApi";
import DepartmentApi from "../../../../services/DepartmentApi";
import { ListOptions, fromOptions, LimitOptions } from "./const";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const CouponFormPage = (props: RouterProps) => {
  const history = useHistory();

  let params = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState<Coupon>();

  const [selectDepartment, setSelectDepartment] = useState("");
  const [type, setType] = useState("");
  const [selectItem, setSelectItem] = useState("");

  const [notifyClients, setNotifyClients] = useState(false);
  const [notifyCreators, setNotifyCreators] = useState(false);

  const [result, setResults] = useState<SearchResult[]>([]);

  const [applyon, setApplyOn] = useState("");

  useEffect(() => {
    if (!!params.id) {
      setIsEdit(true);
      CouponApi.getItem(Number(params.id)).then((resp) => {
        setApplyOn(resp?.data.apply_on);
        if (resp?.data.limited == "LIMITED_IN") {
          setShowLimit(true);
        }
        setType(resp?.data.type);
        setNotifyClients(resp?.data.notify_all_creators);
        setNotifyCreators(resp?.data.notify_all_creators);

        setCurrentCoupon(resp?.data);
        form.setFieldsValue(resp?.data);
        form.setFieldValue("validate_at", [
          moment(resp?.data.start_at, "YYYY-MM-DD"),
          moment(resp?.data.end_at, "YYYY-MM-DD"),
        ]);
      });
    }
  }, [params.id]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  const handleChangeApply = ({ target: { value } }: RadioChangeEvent) => {
    setResults([]);
    setSelectItem("");
    form.setFieldValue("value", "");
    setApplyOn(value);
  };

  const handlerChangeLimit = ({ target: { value } }: RadioChangeEvent) => {
    setShowLimit(value == "LIMITED_IN" ? true : false);
  };

  const handlerChangeType = ({ target: { value } }: RadioChangeEvent) => {
    setType(value);
  };

  function onSearchItem(text: any, type: "DEPARTMENT" | "PRODUCT") {
    setLoadingSearch(true);
    if (type == "DEPARTMENT") {
      DepartmentApi.onSearch(text).then((resp) => {
        setResults(resp?.data);
      });
    }
  }

  // onSearchItem
  function setChangeValue(val: string, type: "DEPARTMENT" | "PRODUCT") {}

  const handlerSubmit = (values: any) => {
    setLoading(true);
    console.log(values);
    values = {
      ...values,
      ...{
        start_at: moment(values.validate_at[0]).format("YYYY-MM-DD"),
        end_at: moment(values.validate_at[1]).format("YYYY-MM-DD"),
        notify_all_creators: notifyCreators,
        notify_all_clients: notifyClients,
      },
    };
    if (isEdit) {
      CouponApi.update(values, Number(currentCoupon?.id))
        .then((resp) => {
          message.success(resp.data.msg);
          setTimeout(() => {
            history.goBack();
          }, 300);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CouponApi.addnew(values)
        .then((resp) => {
          message.success(resp.data.msg);
          setTimeout(() => {
            history.goBack();
          }, 300);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChangeNotifyClients = (e: CheckboxChangeEvent) => {
    setNotifyClients(e.target.checked);
  };

  const onChangeNotifyCreators = (e: CheckboxChangeEvent) => {
    setNotifyCreators(e.target.checked);
  };

  return (
    <>
      <AppModal
        show={showModal}
        text="Ao sair você perderá todas as informações. Deseja sair mesmo assim?"
        type="CONFIRM"
        onOk={() => {
          history.goBack();
        }}
        onCancel={() => setShowModal(false)}
      />
      <Row justify="end">
        <Button type="text" onClick={back}>
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          {isEdit ? "Atualizar" : "Cadastrar"} Cupom
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={2}>Cadastrar Criador</Title>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    {isEdit ? "Atualizar" : "Salvar"}
                  </Button>
                </Col>
              </Row>
            </div>

            <div style={{ width: "100%" }}>
              <Row>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={handlerChangeType}
                    options={[
                      { label: "Frete grátis", value: "FREE_DELIVERY" },
                      {
                        label: "% desconto",
                        value: "PERCENT",
                      },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Row>
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item
                name="code"
                label="Código do cupom"
                wrapperCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="name"
                label="Nome do cupom"
                wrapperCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              {type == "PERCENT" && (
                <Form.Item
                  name="discount"
                  label="Desconto"
                  wrapperCol={{ span: 8 }}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <InputNumber
                    defaultValue={0}
                    style={{ width: "100%" }}
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                  />
                </Form.Item>
              )}

              <Row>
                <Form.Item
                  name="apply_on"
                  wrapperCol={{ span: 16 }}
                  label="Aplicar em"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={handleChangeApply}
                    defaultValue={"ALL_PRODUCT"}
                    options={ListOptions}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Row>

              {applyon == "ABOVE" || applyon == "SHOPPING_ABOVE" ? (
                <Form.Item
                  name="value"
                  style={{ width: "100%" }}
                  label={
                    applyon == "ABOVE" ? "Peças acima de" : "Compras acima de"
                  }
                  wrapperCol={{ span: 8 }}
                  rules={[
                    {
                      required:
                        applyon == "ABOVE" || applyon == "SHOPPING_ABOVE"
                          ? true
                          : false,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              ) : (
                <></>
              )}

              {applyon == "DEPARTMENT" || applyon == "SPECIFIC_PRODUCT" ? (
                <Form.Item
                  name="item_id"
                  style={{ width: "100%" }}
                  label={`${
                    applyon == "DEPARTMENT" ? "Departamento" : "Produto"
                  }`}
                  wrapperCol={{ span: 8 }}
                  rules={[
                    {
                      required:
                        applyon == "DEPARTMENT" || applyon == "SPECIFIC_PRODUCT"
                          ? true
                          : false,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    value={selectItem}
                    placeholder={`${
                      applyon == "DEPARTMENT" ? "Departamento" : "Produto"
                    }`}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(v: any) => onSearchItem(v, "DEPARTMENT")}
                    onChange={(v: any) => setChangeValue(v, "DEPARTMENT")}
                    loading={loadingSearch}
                    notFoundContent={null}
                  >
                    {result.map((item: SearchResult) => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}

              <Form.Item
                name="validate_at"
                label="Validade"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                ]}
              >
                <RangePicker
                  format={"DD/MM/YYYY"}
                  placeholder={["Inicio", "Fim"]}
                />
              </Form.Item>

              <Row>
                <Form.Item
                  name="from"
                  label="Fonte de abatimento"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Radio.Group
                    options={fromOptions}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Row>

              <Form.Item
                name="limited"
                label="Limite de uso"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                ]}
              >
                <Radio.Group
                  options={LimitOptions}
                  onChange={handlerChangeLimit}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              {showLimit && (
                <Form.Item
                  wrapperCol={{ span: 8 }}
                  name="limit_number"
                  label="Limitar uso em"
                  rules={[
                    {
                      required: showLimit,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              )}
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item name="notify_all_creators">
                <Checkbox
                  checked={notifyCreators}
                  onChange={onChangeNotifyCreators}
                >
                  Enviar promoção para criadores
                </Checkbox>
              </Form.Item>

              <Form.Item name="notify_all_clients">
                <Checkbox
                  checked={notifyClients}
                  onChange={onChangeNotifyClients}
                >
                  Enviar promoção para clientes cadastrados
                </Checkbox>
              </Form.Item>
            </div>
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default withRouter(CouponFormPage);
