import { Breadcrumb, Col, Divider, Row,Typography } from "antd";

const PageCredentials = () => {
  return <>
    <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Caixas de entrega</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col>
          <Typography.Title level={2}>Configurações gerais</Typography.Title>
        </Col>

        
      </Row>

      <Divider />

      <div className="wrapper-table">
        
      </div>
  </>;
};

export default PageCredentials;
