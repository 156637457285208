import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Typography,
} from 'antd';
import Title from 'antd/lib/skeleton/Title';
import { useEffect, useState } from 'react';
import SystemSettingsApi from '../../../../services/SystemSettingsApi';
import {
  CurrencyFormatter,
  CurrencyParser,
} from '../../../../utils/currencyFormater';

const PageGeneralSettings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    SystemSettingsApi.getItem().then((resp) => {
      form.setFieldsValue(resp?.data);
    });
  }, []);

  const handlerSubmit = (values: any) => {
    setLoading(true);
    SystemSettingsApi.saveItem(values)
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Caixas de entrega</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col>
          <Typography.Title level={2}>Configurações gerais</Typography.Title>
        </Col>
      </Row>
      <Typography.Text>Configurações gerais do sistema.</Typography.Text>
      <Divider />
      <div className="wrapper-table">
        <Form form={form} onFinish={handlerSubmit} layout={'vertical'}>
          <Form.Item
            label={'Máximo de parcelas'}
            name="max_parcel"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input type={'number'} />
          </Form.Item>
          <Form.Item
            label={'Criação de página da loja(R$)'}
            name="build_store"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              style={{
                width: '100%',
                marginRight: '1rem',
              }}
              formatter={CurrencyFormatter}
              parser={CurrencyParser}
            />
          </Form.Item>

          <Form.Item
            label={'Imposto Geral (%)'}
            name="general_tax"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input type={'number'} prefix={'%'} />
          </Form.Item>

          <fieldset>
            <Typography.Title level={4}>Divisão de lucros</Typography.Title>
            <Form.Item
              label={'Criador(%)'}
              name="creator_gain"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input type={'number'} prefix={'%'} />
            </Form.Item>
            <Form.Item
              label={'Indicador(%)'}
              name="indicator_gain"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input type={'number'} prefix={'%'} />
            </Form.Item>
            <Form.Item
              label={'Bonus equipe(%)'}
              name="team_gain"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input type={'number'} prefix={'%'} />
            </Form.Item>
            <Form.Item
              label={'Fella(%)'}
              name="fella_gain"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input type={'number'} prefix={'%'} />
            </Form.Item>
          </fieldset>

          <Button
            type={'primary'}
            size={'large'}
            htmlType="submit"
            className={'btn-orange'}
            loading={loading}
          >
            Salvar
          </Button>
        </Form>
      </div>
    </>
  );
};

export default PageGeneralSettings;
