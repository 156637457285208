import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Upload,
  UploadProps,
  RadioChangeEvent,
  Radio,
  message,
  Image,
  Drawer,
} from "antd";
import { FC, useEffect, useState } from "react";
import {
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { FileImageFilled, UploadOutlined } from "@ant-design/icons";
import { Department } from "../../classes/Department";
import DepartmentApi from "../../services/DepartmentApi";
import UploadFile from "../Upload";

const { Title, Text } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;

interface DepartmentFormOptions {
  onUpdate: () => void;
  newItem: (val?: any) => void;
  onClose: () => void;
}

const DepartmentForm: FC<DepartmentFormOptions> = ({
  onUpdate,
  newItem,
  onClose,
}: DepartmentFormOptions) => {
  const [banner, setBanner] = useState("");

  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [currentDepartment, setCurrentDeparment] = useState<Department>();

  useEffect(() => {
    form.setFieldsValue({
      name: currentDepartment?.name,
      description: currentDepartment?.description,
      highlight_type: currentDepartment?.highlight_type,
    });
  }, [currentDepartment]);

  function back() {
    const hasError = form
      .getFieldsError()
      .filter((item) => item.errors.length > 0);

    setShowModal(true);
  }

  const onChangeHighlight = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    // setValue4(value);
  };

  const handlerSubmit = (values: any) => {
    values = { ...values, ...{ banner: banner } };
    setLoading(true);
    DepartmentApi.addnew(values)
      .then((resp) => {
        message.success(resp?.data.msg);
        newItem(resp?.data.id);
        onUpdate();
        onClose();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: "100%" }}
        wrap
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={handlerSubmit}
        >
          <Space
            size={[40, 40]}
            align="start"
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <Title level={5}>Dados principais</Title>

              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: "Campo é obrigatório!" }]}
              >
                <Input placeholder="Nome do departamento" />
              </Form.Item>

              <Form.Item
                label="Descrição"
                name="description"
                rules={[{ required: true, message: "Campo é obrigatório!" }]}
              >
                <TextArea placeholder="Nome do departamento" />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Title level={5}>Banner</Title>
              {isEdit && (
                <Image
                  width={300}
                  src={`${process.env.REACT_APP_API}/storage/${currentDepartment?.banner}`}
                />
              )}

              <Form.Item label={"Banner"} name={"banner"}>
                <UploadFile
                  path={"department"}
                  hint={"PNG, JPG"}
                  onFile={setBanner}
                />
              </Form.Item>
              <Form.Item name="highlight_type">
                <Radio.Group
                  options={[
                    { label: "Destaque principal", value: "MAIN" },
                    {
                      label: "Destaque secundário",
                      value: "SECONDARY",
                    },
                  ]}
                  onChange={onChangeHighlight}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Col>
                <Button
                  type="ghost"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  {isEdit ? "Atualizar" : "Salvar"}
                </Button>
              </Col>
            </div>
          </Space>
        </Form>
      </Space>
    </>
  );
};

export default DepartmentForm;
