import { useEffect, useState } from "react";
import { Row, Typography, Col, Table, Dropdown, Button, Space, Menu, Drawer, Image, message } from "antd";
import { DownOutlined, CheckOutlined } from '@ant-design/icons';
import ProductionApi from "../../../../services/ProductionApi";
import { Paginate } from "../../../../classes/Paginate";
import { Productions } from "../../../../classes/Production";
import DetailSolicitation from "../detail";
import modal from "antd/lib/modal";

const {Title, Text} = Typography;

const PageSolicitations = () =>{
    const [loading, setLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [filter, setFilter] = useState({});
    const [currentProduction, setCurrentProduction] = useState<Productions>();
    const [list, setList] = useState<Paginate<Productions>>();
    

    useEffect(() =>{
        onload();
    },[filter])

    function onload(){
        setLoading(true);
        ProductionApi.list('admin',filter).then((resp) =>{
            setList(resp?.data);
        }).finally(() =>{
            setLoading(false)
        });
    }
    

     const OptionsMenu = (record: Productions) => {
        const [loadingItem, setLoadingItem] = useState(false);
        const finalizate = () =>{
            modal.confirm({
                title: 'Finalizar produção',
                icon: <CheckOutlined />,
                content:'Ao finalizar a produção, o produto será colocado no estoque da loja.',
                okText: 'Ok',
                onOk: () => {
                 setLoadingItem(true);
                 ProductionApi.finalize(Number(record.id)).then((resp) =>{
                    message.success(resp?.data.msg);
                    onload();
                 }).finally(() =>{
                    setLoadingItem(false);
                 });
                },
            });
        }

        const Itens = () =>{
            return (
                <Menu>
                    <Menu.Item onClick={() => [
                        setCurrentProduction(record),
                        setShowDetail(true)
                    ]}>Ver itens ({record.production_itens_count})</Menu.Item>
                    {record.status != 'FINALIZED' && (
                        <Menu.Item onClick={finalizate}>Finalizar produção</Menu.Item>
                    )}
                    <Menu.Item onClick={() => {
                        window.open(record.payment.pdf, '_blank')
                    }}>Ver cobrança</Menu.Item>
                </Menu>

            )
        }

        return (
            <Dropdown trigger={['click']} overlay={Itens} className={'user-dropdown'}>
              <Button type="ghost" onClick={(e) => e.preventDefault()}>
                <Space>
                  Opções <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          );
     }

    return (
        <>
            <Drawer open={showDetail} size="large" title={'Itens solicitados'} destroyOnClose={true} closable={true} onClose={() => setShowDetail(false)}>
                <DetailSolicitation currentProduction={currentProduction}/>
                
            </Drawer>

            <Row justify="space-between">
                <Col>
                    <Title level={2}>Solicitações de produção</Title>
                </Col>  
            </Row>

            <div className="wrapper-table">
                <Table 
                    loading={loading}
                    dataSource={list?.data}
                    size="large"
                    columns={[
                        {
                            title:'Produto',
                            key:'product',
                            render:(text:any, record:Productions) => (
                                <>
                                    <Space direction="horizontal">
                                        <Image width={50} src={`${process.env.REACT_APP_API}/storage/${record.item.media.file}`}/>
                                        <Text>{record.item.name}</Text>
                                    </Space>
                                    
                                </>
                            )
                        },
                        {
                            title:'Criador',
                            key:'creator',
                            render:(text:any, record:Productions) => (
                                <>
                                    <Text>{record.creator_name}</Text>
                                </>
                            )
                        },
                        {
                            title:'Preço',
                            key:'value',
                            render:(text:any, record:Productions) =>(
                                <Text>{record.value}</Text>
                            )
                        },
                        {
                            title:'Situação',
                            dataIndex:'situation',
                            render:(text:any, record:Productions) =>(
                                <>
                                    {record.current_production_status.status == "PAYED" && record.status == "IN_PROGRESS" && ('Em produção')}
                                    {record.status == 'FINALIZED' && ('Produção finalizada')}
                                    {record.status == 'IN_PROGRESS' && ('Em progresso')}
                                </>
                            )
                        },
                        {
                            title:'',
                            key:'actions',
                            render:(text:any, record:Productions) => <OptionsMenu {...record}/>
                        }
                    ]}
                />
            </div>
        
        </>
    )
}

export default PageSolicitations;