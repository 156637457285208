import { FC } from "react";

export interface DividerOptions {
    color?:string;
    height?:number;
}

const Divider:FC<DividerOptions> = ({color, height}: DividerOptions) =>{
    return(
        <div className="bar" style={{
            borderColor:color ? color : 'rgba(0, 0, 0, 0.85)',
            borderWidth:Number(height) > 0 ? height : 1
        }}></div>
    )
}

export default Divider;

