import {
  RouteProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import {
  PlusSquareOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UploadOutlined,
  PlusOutlined,
  CloseOutlined,
  MinusOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {
  Row,
  Button,
  Form,
  Typography,
  Breadcrumb,
  Col,
  Space,
  Input,
  Select,
  Drawer,
  Steps,
  Upload,
  InputNumber,
  Radio,
  Table,
  Skeleton,
  Divider,
  Checkbox,
  FormListFieldData,
  message,
  Switch,
  Card,
} from 'antd';
import { Product, ProductItem } from '../../../../classes/Product';
import { useEffect, useState } from 'react';
import ProductApi from '../../../../services/ProductApi';
import APP_ROUTE from '../../../../routes/routes';

import './style.scss';
import { ProductMeasure } from '../../../../classes/Measure';

const { Title, Text } = Typography;

const ProductStock = (props: RouteProps) => {
  const history = useHistory();
  const [form] = Form.useForm();
  let params = useParams<{ id?: string }>();

  const [sendingInformation, setSendingInformation] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<ProductItem>();
  const [fieldChecked, setFieldChecked] = useState(false);
  const [listMeasure, setListMeasure] = useState<ProductMeasure[]>([]);

  useEffect(() => {
    if (!!params.id) {
      onLoad();
    }
  }, [params.id]);

  function onLoad() {
    ProductApi.getStock('admin',Number(params.id)).then((resp) => {
      setCurrentProduct(resp?.data);
      setFieldChecked(resp?.data.product[0].active);

      setListMeasure(resp?.data.measures);
    });
  }

  const changeStock = (option: 'ADD' | 'REM', index: number) => {
    const items = [...listMeasure];
    let newStock = 0;
    if (option == 'ADD') newStock = items[index].stock + 1;

    if (option == 'REM') {
      let newValue = items[index].stock - 1;
      newStock = newValue <= 0 ? 0 : newValue;
    }

    items[index].stock = newStock;

    // console.log(items);
    setListMeasure(items);
  };

  function handlerValues() {
    // ProductApi.update()
    setSendingInformation(true);
    ProductApi.updateStock(Number(params.id), { measure: listMeasure })
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setSendingInformation(false);
      });
  }

  function changeStatus() {
    setSendingInformation(true);
    ProductApi.enable(Number(params.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        setSendingInformation(false);
        onLoad();
      });
  }

  return (
    <>
      <Row justify="end">
        <Button
          type="text"
          onClick={() => {
            history.goBack();
          }}
        >
          <Text strong>VOLTAR</Text>
        </Button>
      </Row>

      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
        >
          Alterar estoque
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>Solicitar estoque de peças</Title>
        </Col>
      </Row>
      <Space
        size={[40, 40]}
        direction="vertical"
        align="start"
        style={{ width: '100%' }}
        wrap
      >
        <div className="wrapper-table">
          <Table
            pagination={false}
            dataSource={currentProduct?.product}
            size={'small'}
            columns={[
              {
                title: 'Produto',
                dataIndex: 'name',
              },
              {
                title: 'Cor',
                dataIndex: 'color',
              },
              {
                title: 'Estoque',
                dataIndex: 'creator_name',
                render: (text: any, record: Product) => (
                  <Text>
                    {record?.stock_status == 'OUT' && 'Estoque zerado'}
                    {record?.stock_status == 'FULL' && 'Em estoque'}
                    {record?.stock_status == 'PARTIALLY' && 'Estoque parcial'}
                  </Text>
                ),
              },
              {
                title: 'Status no site',
                dataIndex: 'status',
                render: (text: any, record: Product) => (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Form form={form}>
                          <Form.Item label={'Ativo'} name="active">
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              size={'small'}
                              onChange={(val: boolean) => {
                                changeStatus();
                              }}
                              defaultChecked={currentProduct?.product[0].active}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                      <Col>
                        <Button
                          type="link"
                          onClick={() => {
                            history.push(APP_ROUTE.PRODUCT_VIEW(record.id));
                          }}
                        >
                          Ver mais
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              },
            ]}
          ></Table>
        </div>
        <div>
          <Row justify={'space-between'} align="middle">
            <Col>
              {' '}
              <Title level={3}>Peça por tamanho</Title>
            </Col>
            <Col>
              <div className="card-text">
                <p>
                  <span>Peças em estoque</span>{' '}
                  <strong>{currentProduct?.total}</strong>
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <div className="block-card">
            {listMeasure.map((item: ProductMeasure, index: number) => (
              <Card style={{ width: '300px' }}>
                <Title level={4} className={'item-title'}>
                  {item.label}
                </Title>
                <Row>
                  <Col>
                    <Button
                      icon={<MinusOutlined />}
                      onClick={() => {
                        changeStock('REM', index);
                      }}
                      disabled={item.stock <= 0}
                    ></Button>
                  </Col>
                  <Col className="field-number">
                    <Input type={'text'} value={item.stock} />
                  </Col>
                  <Col>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        changeStock('ADD', index);
                      }}
                    ></Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </div>
        <Row justify={'end'}>
          <Button
            type="default"
            size="large"
            loading={sendingInformation}
            onClick={handlerValues}
          >
            Solicitar estoque
          </Button>
        </Row>
      </Space>
    </>
  );
};

export default withRouter(ProductStock);
