import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Row,
  Skeleton,
  Statistic,
  Typography,
  Form,
  Input,
  Table,
  Tag,
  Popconfirm,
  message,
  Dropdown,
  Menu,
  Space,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Paginate } from "../../../../classes/Paginate";
import { Pearson } from "../../../../classes/Pearson";
import { Stats } from "../../../../classes/Stats";
import { Type } from "../../../../classes/Type";
import PearsonApi from "../../../../services/PearsonApi";
import TypeApi from "../../../../services/TypeApi";
import {
  RouteProps,
  RouterProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import APP_ROUTE from "../../../../routes/routes";
import React from "react";

const { Title, Text } = Typography;

export interface PersonPageType {
  type?: string;
}

const PearsonPage = (props: RouteProps) => {
  const history = useHistory();
  const params = useParams<PersonPageType>();

  const [form] = Form.useForm();

  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [loadingPerson, setLoadingPearson] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const [dataStats, setDataStats] = useState<Stats>();
  const [dataTypes, setDataTypes] = useState<Type[]>([]);
  const [dataPearson, setDataPearson] = useState<Paginate<Pearson>>();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (!!params && !!params.type) {
      setTimeout(() => {
        loadAll(params.type?.toString().toUpperCase());
      }, 200);
    }
  }, [params.type]);

  function loadAll(option?: string) {
    //setType(option?.toString());
    onStats(option);
    loadTypes(option);

    if (!!option) {
      setFilter({ ...filter, ...{ type: option } });
    }
  }

  useEffect(() => {
    onLoad();
  }, [filter]);

  function onStats(type?: string) {
    setLoadingStats(true);
    PearsonApi.stats(type)
      .then((resp) => {
        setDataStats(resp?.data);
      })
      .finally(() => {
        setLoadingStats(false);
      });
  }

  function loadTypes(type?: string) {
    setLoadingTypes(true);
    TypeApi.loadTypesFilter(type)
      .then((resp) => {
        setDataTypes(resp?.data);
      })
      .finally(() => {
        setLoadingTypes(false);
      });
  }

  function removeItem(item: Pearson) {
    PearsonApi.remove(Number(item.id))
      .then((resp) => {
        message.success(resp?.data.msg);
      })
      .finally(() => {
        onStats();
        onLoad();
      });
    console.log(item);
  }

  function goDetail(item: Pearson) {
    history.push(APP_ROUTE.PEARSON_VIEW(item.type.toLowerCase(), item.id));
  }

  function enableItem(item: Pearson) {
    PearsonApi.enable(Number(item?.id))
      .then((resp) => {
        message.success(resp?.data.msg);
        onStats(params.type?.toString().toUpperCase());
        onLoad();
      })
      .catch((e) => {
        message.error(e.response?.data.msg);
      })
      .finally(() => {});
  }

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  const OptionsMenu = (record: Pearson) => {
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => goDetail(record)}>Ver</Menu.Item>

          <Menu.Item onClick={() => enableItem(record)}>
            {record.active ? "Inativar" : "Ativar"}
          </Menu.Item>

          <Menu.Item danger>
            <Popconfirm
              title={`Remover ${record.name}`}
              onConfirm={() => removeItem(record)}
              okButtonProps={{ loading: loadingRemove }}
            >
              <Button type="text" style={{ padding: 0 }}>
                Remover
              </Button>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  function onLoad() {
    setLoadingPearson(true);
    PearsonApi.list(filter)
      .then((resp) => {
        setDataPearson(resp?.data);
      })
      .catch((e) => {
        console.log(e.response);
      })
      .finally(() => {
        setLoadingPearson(false);
      });
  }

  const columns = [
    {
      title: params.type == "team" ? "Equipe" : "Fornecedor",
      dataIndex: "name",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "doc_number",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
    },
    {
      title: "E-mail",
      key: "email",
      render: (text: any, record: Pearson) => (
        <a href={`mailto:${record.email}`}>{record.email}</a>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text: any, record: Pearson) => (
        <Tag color={record.active ? "#00A838" : "#939393"} key={record.id}>
          {record.active ? "Ativo" : "Inativo"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (text: any, record: Pearson) => <OptionsMenu {...record} />,
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          {params.type == "team" ? "Equipe" : "Fornecedor"}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2}>{params.type == "team" ? "Equipe" : "Fornecedor"}</Title>

      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`${
                  params.type == "team" ? "Equipe" : "Fornecedor"
                } ativos`}
                prefix={"Qtd."}
                value={dataStats?.active}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`${
                  params.type == "team" ? "Equipe" : "Fornecedor"
                } Inativos`}
                prefix={"Qtd."}
                value={dataStats?.inactive}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.PEARSON_CREATE(params.type))}
          >
            Cadastrar {params.type == "team" ? "Equipe" : "Fornecedor"}
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row justify="space-between">
        <Col>
          {loadingTypes ? (
            <Skeleton />
          ) : (
            <>
              {!!dataTypes && (
                <Row gutter={[16, 16]}>
                  {dataTypes.map((item: Type, index: number) => (
                    <Col>
                      <Button
                        onClick={() =>
                          setFilter({ ...filter, ...{ type_id: item.id } })
                        }
                      >
                        {item.name}
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={columns}
          dataSource={dataPearson?.data}
          size={"middle"}
          loading={loadingPerson}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: dataPearson?.per_page,
            current: dataPearson?.current_page,
            total: dataPearson?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default withRouter(PearsonPage);
