import BaseApi from "./BaseApi";

export default {
  list: (id: number) => {
    return BaseApi.get(`/admin/modeling-media/?id=${id}`);
  },
  delete: (id: number) => {
    return BaseApi.delete(`/admin/modeling-media/${id}`);
  },
};
