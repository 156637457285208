import BaseApi from "./BaseApi";

export default {
  list: (filter?: any) => {
    return BaseApi.get(`/admin/measure`, { params: filter });
  },
  addnew: (body: any) => {
    return BaseApi.post(`/admin/measure`, body);
  },
};
