import axios from 'axios';
import BaseApi from './BaseApi';

export default {
  ZipCode: (zipcode: any) => {
    let clearzip = zipcode.replace('.', '');
    clearzip.replace('-', '');
    return axios.get(`https://viacep.com.br/ws/${clearzip}/json/`);
  },
  Shipping:(body?:any) =>{
    return BaseApi.post(`/admin/dispatch/simulate`,body);
  },
  banks: () => {
    return axios.get(`https://brasilapi.com.br/api/banks/v1`);
  },
  uploadFile: (body: any) => {
    return BaseApi.post('/upload-file', body);
  },
  updloadFormData: (body: any) => {},
};
