import BaseApi from "./BaseApi";

export default {
  list: (filter?: any) => {
    return BaseApi.get("/admin/store", { params: filter });
  },
  getItem: (id: number) => {
    return BaseApi.get(`/admin/store/${id}`);
  },
  addnew: (body: any) => {
    return BaseApi.post("/admin/store", body);
  },
  removeItem: (id: number) => {
    return BaseApi.delete(`/admin/store/${id}`);
  },
  publish: (id: number) => {
    return BaseApi.delete(`/admin/pulish/${id}`);
  },
  generateTemporaryUrl: (id: number, body: any) => {
    return BaseApi.put(`/admin/store/${id}/generate-url`, body);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`/admin/store/${id}`, body);
  },
};
