import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import Divider from '../../components/Divider';

import FormButton from '../../components/Button';
import AuthApi from '../../services/AuthApi';
import { useHistory } from 'react-router-dom';
import APP_ROUTE from '../../routes/routes';
import { useAppContext } from '../../context/app-context';
import { Button, Form, Input, message } from 'antd';
import React from 'react';

import './style.css';

const Login = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const { setIsLoggedUser } = useAppContext();

  const handlerSubmit = (values: any) => {
    setLoading(true);
    AuthApi.Login(values)
      .then((resp) => {
        AuthApi.setToken(resp?.data);

        setTimeout(() => {
          AuthApi.Me().then((resp) => {
            AuthApi.setUserData(resp?.data);
            setIsLoggedUser(true);
            if (resp.data.profile == 'ADMINISTRATOR') {
              history.replace(APP_ROUTE.PRODUCT());
            } else {
              history.replace(APP_ROUTE.CREATOR_PRODUCT());
            }

            window.location.reload();
          });
        }, 500);
      })
      .catch((e: any) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log('here');
  };

  return (
    <>
      <div className="wrapper-login">
        <div className="form">
          <div className="brand">
            <img src="/imgs/brand.svg" alt="" />
            <h2>Fella</h2>
          </div>
          <Divider />
          <Form
            onFinish={handlerSubmit}
            layout={'vertical'}
            autoComplete={'off'}
          >
            <Form.Item
              label="Usuário"
              name="email"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className="btn-orange"
                block
              >
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
