import BaseApi from './BaseApi';

export default {
  getItem: () => {
    return BaseApi.get(`/admin/system-config`);
  },
  saveItem: (body: any) => {
    return BaseApi.post(`/admin/system-config`, body);
  },
};
