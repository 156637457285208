import { Button, Form, Input, InputNumber, message } from "antd";
import { FC, useEffect, useState } from "react";
import PackingApi from "../../../../services/PackingApi";
import {
  CurrencyFormatter,
  CurrencyParser,
} from "../../../../utils/currencyFormater";

interface FormPackingOptions {
  isEdit?: boolean;
  packing?: any;
  onClose: () => void;
  onUpdate: () => void;
}

const FormPacking: FC<FormPackingOptions> = ({
  isEdit,
  packing,
  onClose,
  onUpdate,
}: FormPackingOptions) => {
  const [form] = Form.useForm();
  const [sending, setSending] = useState(false);

  useEffect(() => {
    form.setFieldsValue(packing);
  }, [packing]);

  const handlerSubmit = (values: any) => {
    setSending(true);

    if(isEdit){
      PackingApi.update(values, Number(packing.id)).then((resp) => {
        message.success(resp?.data.msg);
        onUpdate();
        onClose();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setSending(false);
      });
    }else{
      PackingApi.addnew(values)
        .then((resp) => {
          message.success(resp?.data.msg);
          onUpdate();
          onClose();
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setSending(false);
        });
    }
  };

  return (
    <>
      <Form form={form} onFinish={handlerSubmit} layout="vertical">
        <Form.Item
          label={"Nome"}
          name="name"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Itens"}
          name="size_itens"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Altura"}
          name="height"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Largura"}
          name="width"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Peso"}
          name="weight"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Comprimento"}
          name="length"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={"Valor do seguro"} name="secure_value">
          <InputNumber
            style={{
              width: "100%",
            }}
            formatter={CurrencyFormatter}
            parser={CurrencyParser}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            className="btn-orange"
            htmlType="submit"
            loading={sending}
          >
            {isEdit ? "Atualizar" : "Cadadastrar"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormPacking;
