import { FC, useEffect, useState } from "react";
import ProductionApi from "../../../../services/ProductionApi";
import { Space, StepProps, Steps, StepsProps, Table } from "antd";
import { ProductionDetail, Productions } from "../../../../classes/Production";
import moment from "moment";
import ProductionStatusOptions from "../../../../utils/productionstatus";

interface Options {
    currentProduction?:Productions
}

const DetailSolicitation:FC<Options> = ({currentProduction}: Options) =>{
    const [loading, setLoading] = useState(false);
    const [stepItens, setStepItens] = useState<StepProps[]>([]);

    const [itens, setItens] = useState<ProductionDetail[]>([]);
    


    useEffect(() =>{
        if(!!currentProduction){
            setLoading(true);
            ProductionApi.getItem('admin',Number(currentProduction.id)).then((resp) =>{
                setItens(resp?.data);
            }).finally(() =>{
                setLoading(false);
            })
        }
    },[currentProduction]);

    return(
        <>
            <Table
                loading={loading}
                dataSource={itens}
                columns={[
                    {
                        title:'Cor',
                        key:'color',
                        render:(value:any, record:ProductionDetail) =>(
                            <>
                                <Space direction="vertical">
                                    <span style={{background:record.color_value}}>
                                    </span>
                                    {record.color_name}
                                </Space>
                            </>
                        )
                    },
                    {
                        title:'Tamanho',
                        dataIndex:'measure_name'
                    },
                    {
                        title:'Quantidade',
                        dataIndex:'size'
                    },
                    {
                        title:'Valor',
                        dataIndex:'price'
                    }
                ]}
            />
        </>
    )
}

export default DetailSolicitation;