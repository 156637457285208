import { Button, Col, Form, Input, Row, Switch, Typography } from "antd";
import { useHistory } from "react-router-dom";

import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import APP_ROUTE from "../../../../../routes/routes";

const { Title, Text } = Typography;

const Page = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
  }, [filter]);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {}

  return (
    <>
      <Row justify="end">
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.SHOP_CREATE())}
          >
            CRIAR LOJA
          </Button>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="page-title" level={2}>
            Lojas
          </Title>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Page;
