import ProvideLayout from './context/app-context';
import FellaRoute from './routes';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <ProvideLayout>
        <FellaRoute />
      </ProvideLayout>
    </BrowserRouter>
  );
};
export default App;
