import {
  Row,
  Col,
  Form,
  Input,
  Switch,
  Typography,
  Statistic,
  Button,
  Divider,
  Table,
  Image,
} from "antd";
import {
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APP_ROUTE from "../../../../../routes/routes";
import SaleApi from "../../../../../services/SaleApi";
import { Paginate } from "../../../../../classes/Paginate";
import { Sale } from "../../../../../classes/Sale";
import moment from "moment";

const { Title, Text } = Typography;

const PageWholeSale = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [saleList, setSaleList] = useState<Paginate<Sale>>();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    console.log("here here");

    setFilter({ ...filter, ...{ type: "WHOLESALE" } });
    // onLoad();
    stats();
  }, []);

  useEffect(() => {
    onLoad();
  }, [filter]);

  const handlerFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function onLoad() {
    setLoading(true);
    SaleApi.list(filter)
      .then((resp) => {
        setSaleList(resp?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function stats() {
    setLoadingStats(true);

    setTimeout(() => {
      setLoadingStats(false);
    });
  }

  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Statistic
                title={`Total de peças vendidas`}
                prefix={"R$."}
                value={0.0}
                loading={loadingStats}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Lucro total de vendas`}
                prefix={"R$."}
                value={0.0}
                loading={loadingStats}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="ghost"
            onClick={() => history.push(APP_ROUTE.PURCHASE_WHOLESALE_CREATE())}
          >
            Criar venda
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row justify={"space-between"}>
        <Col>
          <Title level={2}>Vendas do atacado</Title>
        </Col>
        <Col>
          <Form layout={"inline"} onFinish={handlerFilter}>
            <Form.Item name="term">
              <Input
                type="text"
                placeholder="Buscar"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label={"Mostrar deletados"}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val: boolean) =>
                  setFilter({ ...filter, ...{ deleted: val } })
                }
                defaultChecked={false}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="wrapper-table">
        <Table
          columns={[
            {
              title: "Produto",
              key: "name",
              render: (text: any, record: Sale) => (
                <Row gutter={[16, 16]} align={"middle"}>
                  <Col>
                    <Image
                      width={60}
                      src={`${process.env.REACT_APP_API}/storage/${record.product?.media}`}
                    />
                  </Col>
                  <Col>
                    <Text>
                      {record.product?.name} <br /> {record.product?.sku}
                    </Text>
                  </Col>
                </Row>
              ),
            },
            {
              title: "Data",
              key: "created_at",
              render: (text: any, record: Sale) => (
                <Text>{moment(record.created_at).format("DD/MM/YYYY")}</Text>
              ),
            },
            {
              title: "Preço",
              dataIndex: "value",
              render: (text: any, record: Sale) => (
                <Text>R$ {record.value}</Text>
              ),
            },
            {
              title: "Qtd",
              key: "size",
              render: (text: any, record: Sale) => <Text>{record?.itens}</Text>,
            },
            {
              title: "Total",
              key: "total",
              render: (text: any, record: Sale) => (
                <Text>R$ {record?.total}</Text>
              ),
            },
            {
              title: "Lucro",
              key: "profit",
              render: (text: any, record: Sale) => (
                <Text>R$ {record?.profit}</Text>
              ),
            },
            {
              title: "Criador",
              key: "profit",
              render: (text: any, record: Sale) => (
                <Text>{record?.creator.name}</Text>
              ),
            },
            {
              title: "Status",
              dataIndex: "creator_name",
              render: (text: any, record: Sale) => (
                <Text>
                  {record?.payment?.status == "paid" && "Pago"}
                  {record?.payment?.status == "pending" &&
                    "Aguardando pagamento"}
                </Text>
              ),
            },
          ]}
          dataSource={saleList?.data}
          size={"middle"}
          loading={loading}
          rowKey={(record) => record.id || 0}
          pagination={{
            position: ["bottomCenter"],
            pageSize: saleList?.per_page,
            current: saleList?.current_page,
            total: saleList?.total,
            onChange: (page, pageSize) => {
              setFilter({ ...filter, ...{ page: page } });
            },
          }}
        />
      </div>
    </>
  );
};

export default PageWholeSale;
