import BaseApi from "./BaseApi";

export default {
  listAll: (filter?: any) => {
    return BaseApi.get(`/admin/department`, { params: filter });
  },
  addnew: (body: any) => {
    return BaseApi.post(`/admin/department`, body);
  },
  getItem: (id: number) => {
    return BaseApi.get(`/admin/department/${id}`);
  },
  update: (body: any, id: number) => {
    return BaseApi.put(`/admin/department/${id}`, body);
  },
  remove: (id: number) => {
    return BaseApi.delete(`/admin/department/${id}`);
  },
  enable: (id: number) => {
    return BaseApi.put(`/admin/department/${id}/enable`);
  },
  onSearch: (term?: string) => {
    return BaseApi.get(`/admin/department/utils/search-item`, {
      params: { term: term },
    });
  },
};
